import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import { ROUTES } from '../../libs';
import { AppDispatch, ApplicationState } from '../../store/application-state';
import { ProtectedRouteProps } from './protected-routes.props';

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const isAuthenticated = () => {
    return props.loggedUser !== null && props.loggedUser.token !== "" && props.loggedUser.token !== undefined && props.loggedUser.token !== null ? true : false;
  };

  return (
    <>
      {isAuthenticated() ? props.children : <Navigate to={ROUTES.LOGIN} replace />}
    </>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  loggedUser: state.login.loggedUser,
  userRole: state.login.userRole ?? null

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProtectedRoute);

