import { ForgetPasswordRequestOtp } from "libs/models/forget-password";
import { ValidationModel } from "libs/models/validate-model";
import translate from "utils/translations";

export const validateRequestForgetPasswordState = (
  request: ForgetPasswordRequestOtp
): ValidationModel<ForgetPasswordRequestOtp> => {
  let validationModel = {} as ValidationModel<ForgetPasswordRequestOtp>;

  var emailRegex = new RegExp("^([\\w\\.\\-]+)@([\\w\\-]+)((\\.(\\w){2,3})+)$");
  if (!emailRegex.test(request.email)) {
    validationModel.email = translate(
      "Login_Validation_Email_Error_Text",
      "The entered email not a valid email"
    );
  }

  return validationModel;
};
