import { LandingPageContents } from "libs/landing-page-forms";
import { useState } from "react";
import translate from "utils/translations";

const LandingPage = () => {
  const [landingPage, setLandingPage] = useState(0);
  const setLandingPageIndex = (value: number) => {
    setLandingPage(value);
  };
  return (
    <div className="flex flex-col h-full">
      <div className="w-full h-10 bg-primary-100 flex justify-center items-center">
        <div
          onClick={() => setLandingPageIndex(0)}
          className={`mx-1 text-primary-700 select-none cursor-pointer ${
            landingPage === 0 ? "font-bold" : "font-normal"
          }`}
        >
          {translate("Offerz_Text", "Offerz")}
        </div>
        <div className="border-[1px] border-solid border-[#518c7d]  z-10 h-5 mx-3" />
        <div
          onClick={() => setLandingPageIndex(1)}
          className={`mx-1 text-primary-600 select-none cursor-pointer ${
            landingPage === 1 ? "font-bold" : "font-semibold"
          }`}
        >
          {translate("GiftCard_Text", "GiftCard")}
        </div>
      </div>
      <div className="h-full w-full">{LandingPageContents[landingPage]}</div>
    </div>
  );
};

export { LandingPage };
