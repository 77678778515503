import { Button } from "@ofrz/ui-package";
import { connect } from "react-redux";
import { AppDispatch, ApplicationState } from "../../store/application-state";
import { requestVerification } from "../../store/slices/account-verification";
import { login } from "../../store/slices/login";
import translate from "../../utils/translations";
import { AccountVerificationProps } from "./account.verification.props";
import { COLORS } from "../../libs/colors";

const AccountVerification = (props: AccountVerificationProps) => {

    const onResendVerification = async () => {
        await props.requestEmailVerification(props.email);
    }
    const emailVerificationChecking = async () => {
        await props.emailVerificationChecking(props.email);
    }
    return <div className=" flex-col">
        <p className={`font-medium text-lg ${COLORS.TEXT_POPUP}  my-2 text-center`}>
            {translate(
                "Verify_Email",
                "Verify your email address"
            )}
        </p>
        <p className={`font-medium text-lg ${COLORS.TEXT_POPUP}  my-2 text-center`}>
            {translate(
                "Verify_Email",
                "A verification email has been sent to your email"
            )}
        </p>
        <p className={`font-medium text-lg ${COLORS.TEXT_POPUP}  my-2 text-center`}>
            {translate(
                "Verify_Email",
                "Please check you email and click the link provided in the email to complete your account registration."
            )}
        </p>
        <p className={`font-medium text-sm ${COLORS.TEXT_POPUP}  my-2 text-center mt-10`}>
            {translate(
                "Verify_Email",
                "If you do not receive the email within the next 5 minutes, user the button below to resend verification email."
            )}
        </p>
        <div className="text-center flex flex-col">
            <button
                onClick={onResendVerification}
                className={`text-base ${COLORS.TEXT_PRIMARY} font-semibold my-3`}>
                {translate("Resend_Email_Verification", "Resend Email Verification")}
            </button>
            <Button
                onClick={emailVerificationChecking}
                className={`rounded-lg   ${COLORS.TEXT_WHITE} text-center`}
                size="md"
                primary>
                {translate("I_Have_Verified_Email", "I have Verified Email")}
            </Button>
        </div>
    </div>


}
const mapStateToProps = (state: ApplicationState) => ({

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    emailVerificationChecking: () => {
        dispatch(login())
    },
    requestEmailVerification: (email: string) => {
        dispatch(requestVerification(email))
    }
});

const AccountVerificationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountVerification);
export { AccountVerificationContainer };

