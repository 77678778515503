import { Button, TextInput } from "@ofrz/ui-package";
import { connect } from "react-redux";
import {
  AppDispatch,
  ApplicationState,
} from "../../../../store/application-state";
import { setCloseDialog } from "../../../../store/slices/modal-popup";
import {
  setClearProductItem
} from "../../../../store/slices/product";
import {
  clearVendorProductItem,
  editVendorProductPrice,
  setVendorProductItemProp
} from "../../../../store/slices/vendor-product";
import translate from "../../../../utils/translations";
import { UpdateVendorProductPopupProps } from "./update-vendor-product-popup.props";
import { validateUpdateVendorProductItemState } from "./update-vendor-product-popup.policy";

const UpdateVendorProductPopUp = (props: UpdateVendorProductPopupProps) => {
  const onPropChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    props.onVendorProductPropChange(name, value);
  };
  const editCategory = () => {
    props.editVendorProduct();
  };
  const onCloseDialog = () => {
    props.setCloseDialog();
  };

  return (
    <div className="flex flex-col">
      <div className="mt-2">
        <TextInput
          title={translate("Product_Name", "Product Name")}
          disabled
          className="w-full"
          placeholder=""
          type="text"
          name="name"
          value={props.vendorProductDetails.productName ?? ""}
        />
      </div>
      <div className="mt-2">
        <TextInput
          title={translate("Category_Text", "Category")}
          disabled
          className="w-full"
          placeholder=""
          type="text"
          name="name"
          value={props.vendorProductDetails.productCategory ?? ""}
        />
      </div>
      <div className="mt-2">
        <TextInput
          title={translate("Sub_Category_Text", "Sub Category")}
          disabled
          className="w-full"
          placeholder=""
          type="text"
          name="name"
          value={props.vendorProductDetails.productSubCategory ?? ""}
        />
      </div>
      <div className="mt-2">
        <TextInput
          title={translate("Brand_Text", "Brand")}
          disabled
          className="w-full"
          placeholder=""
          type="text"
          name="name"
          value={props.vendorProductDetails.productBrand ?? ""}
        />
      </div>
      <div className="mt-2">
        <TextInput
          title={translate("Product_Price_Text", "Product Price")}
          className="w-full"
          placeholder="Product Price"
          type="text"
          name="price"
          value={props.vendorProductItem.price ?? ""}
          onChange={onPropChange}
           error={props.validationState.price}
        />
      </div>
      <div className="flex justify-end self-end gap-3 items-end mt-10">
        <Button
          className="text-[#F04438] rounded-lg !bg-white !w-[115px] !h-[45px]  !text-[14px] !font-medium border-[1px] border-[#F04438]"
          onClick={onCloseDialog}
        >
          {translate("Cancel", "Cancel")}
        </Button>
        <Button
          className="text-white rounded-lg !bg-[#518c7d] w-[115px] h-[45px] !text-[14px] font-medium"
          onClick={editCategory}
        >
          {translate("Update", "Update")}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  vendorProductItem: state.vendorproduct.vendorProductItem,
  validationState: validateUpdateVendorProductItemState(state.vendorproduct),
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onVendorProductPropChange: (name: string, value: string) => {
    dispatch(setVendorProductItemProp({ name: name, value: value }));
  },
  editVendorProduct: () => {
    dispatch(editVendorProductPrice());
  },
  setCloseDialog: () => {
    dispatch(clearVendorProductItem());
    dispatch(setClearProductItem());
    dispatch(setCloseDialog());
  },
});

const UpdateVendorProductPopUpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateVendorProductPopUp);

export { UpdateVendorProductPopUpContainer };

