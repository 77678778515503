import { Outlet, useMatch } from "react-router";
import { SideNavigationBarContainer } from "../../containers/side-navigation-bar";
import { TopNavigationBarContainer } from "../../containers/top-navigation-bar";
import { SYSTEM_ADMIN } from "../../libs/routes";

const SystemAdminApplayout = () => {
  const isVendorRequestPage = useMatch(SYSTEM_ADMIN.VENDOR_REQUEST);
  const isVendorDetailsPage = useMatch(SYSTEM_ADMIN.VENDOR_DETAILS);
  const renderSideBar = !isVendorDetailsPage && !isVendorRequestPage;
  return (
    <div className="flex-column w-full h-screen">
      <div className="w-full border-b h-[10%] min-h-[70px] px-[80px] bg-white">
        <TopNavigationBarContainer />
      </div>
      <div
        className={`flex flex-row h-[90%] w-full ${
          renderSideBar ? "px-[80px]" : "px-[160px]"
        }`}>
        {renderSideBar && (
          <div className="w-3/12">
            <SideNavigationBarContainer />
          </div>
        )}
        <div className={`flex ${renderSideBar ? "w-9/12" : "w-full"}`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
export { SystemAdminApplayout };
