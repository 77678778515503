import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserCulture } from "../../libs/enums";
import { CategoryModel } from "../../libs/models/category";
import { Filter } from "../../libs/models/filter";
import { PaginatedList } from "../../libs/models/paginated-list";
import { getCategoriesListService } from "../../services/offerz-service";
import { getFilterQuery } from "../../utils/filter";
import { AsyncAppThunk } from "../application-state";
import { showLoaderWhile } from "./ui";
import { getFileUrl } from "utils/mediaUrlFormater";

export interface OfferzLandingPageStore {
  categoriesLookupList: CategoryModel[];
  sliderImageList: string[];
  filter: Filter;
  userCulture: UserCulture;
}

const initialState: OfferzLandingPageStore = {
  filter: {
    search: { key: "name", value: "" },
    dateProp: { from: null, to: null },
    orderBy: { key: "Name", sort: "asc" },
  },
  categoriesLookupList: [],
  userCulture: UserCulture.English,
  sliderImageList: [],
};

const slice = createSlice({
  name: "offerz-landing-page",
  initialState,
  reducers: {
    setCategoriesLookupList: (
      state: OfferzLandingPageStore,
      action: PayloadAction<PaginatedList<CategoryModel>>
    ) => {
      const categoryList =
        action.payload.list === undefined ? [] : action.payload.list;
      state.categoriesLookupList = categoryList;
      const imgList = categoryList.flatMap((att) =>
        att.pictureFileId === null || att.pictureFileId == ""
          ? ""
          : getFileUrl(att.pictureFileId)
      );
      state.sliderImageList = imgList;
    },

    resetCategoriesList: (state: OfferzLandingPageStore) => {
      state.categoriesLookupList = initialState.categoriesLookupList;
    },
  },
});

export const { setCategoriesLookupList, resetCategoriesList } = slice.actions;
export const reducer = slice.reducer;

export const getOfferzCategoriesLookupList =
  (): AsyncAppThunk => async (dispatch, store) => {
    dispatch(
      showLoaderWhile(async () => {
        const categoriesListResponse = await getCategoriesListService(
          getFilterQuery(store().offerzlandingpage.filter, 0, 10)
        );
        if (
          categoriesListResponse &&
          categoriesListResponse.result &&
          categoriesListResponse?.result !== null
        ) {
          dispatch(setCategoriesLookupList(categoriesListResponse!.result));
        }
      })
    );
  };
