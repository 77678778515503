import { ProductModel } from "../../../../libs/models/product";
import { ValidationModel } from "../../../../libs/models/validate-model";
import { ProductStore } from "../../../../store/slices/product";
import translate from "../../../../utils/translations";

export const validateUpdateProductItemState = (
    state: ProductStore
  ): ValidationModel<ProductModel> => {
    let validationModel = {} as ValidationModel<ProductModel>;
  
    if (!state.isValidating) {
      return validationModel;
    }
    if (state.productItem.name === null || state.productItem.name === "") {
      validationModel.name = translate("Required_Text", "This field is required");
    }
    if (
      state.productItem.subCategoryId === null ||
      state.productItem.subCategoryId === ""
    ) {
      validationModel.name = translate("Required_Text", "This field is required");
    }
    if (
      state.productItem.brandId === null ||
      state.productItem.brandId === ""
    ) {
      validationModel.name = translate("Required_Text", "This field is required");
    }
    if (
      state.productItem.pictureFileId === null ||
      state.productItem.pictureFileId === ""
    ) {
      validationModel.name = translate("Required_Text", "This field is required");
    }
    return validationModel;
  };
  