import { ChangeForgetPasswordModel } from "libs/models/change-password";
import { ValidationModel } from "libs/models/validate-model";
import translate from "utils/translations";

const validateChangeForgetPassword = (
  changePassword: ChangeForgetPasswordModel
): ValidationModel<ChangeForgetPasswordModel> => {
  let validationModel = {} as ValidationModel<ChangeForgetPasswordModel>;

  if (changePassword.newPassword == "") {
    validationModel.newPassword = translate(
      "Required_Text",
      "This field is required"
    );
  } else if (changePassword.newPassword.length < 8) {
    validationModel.newPassword = translate(
      "Login_Validation_Password_Error_Text",
      "The password can not be less than 8 characters"
    );
  }
  if (changePassword.confirmPassword != changePassword.newPassword) {
    validationModel.confirmPassword = translate(
      "Login_Confirm_Password_Text",
      "Please confirm your password"
    );
  }
  return validationModel;
};
export { validateChangeForgetPassword };
