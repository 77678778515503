import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ModalPopupStore {
  title?: React.ReactNode;
  actionButtons: any;
  content: React.ReactNode | null;
  isOpen: boolean;
  onClose: () => void;
}

const initialState: ModalPopupStore = {
    isOpen: false,
    title: "",
    content: null,
    actionButtons: null,
    onClose: () => { },
};

const slice = createSlice({
    name: "modal-popup",
    initialState,
    reducers: {
        setDialogStateChange: (state: ModalPopupStore, action: PayloadAction<ModalPopupStore>) => {
            state.title = action.payload.title;
            state.actionButtons = action.payload.actionButtons;
            state.content = action.payload.content;
            state.isOpen = action.payload.isOpen;
            state.onClose = action.payload.onClose;
        },
        setCloseDialog: (state: ModalPopupStore) => {
            state.title = "";
            state.actionButtons = null;
            state.content = null;
            state.isOpen = false;
            state.onClose = () => { };
        }
        
    },
});

export const {
    setDialogStateChange,
    setCloseDialog
} = slice.actions;
export const reducer = slice.reducer;

