import { Button, MenuItem, Slider } from "@ofrz/ui-package";
import { COLORS } from "libs/colors";
import { ROUTES, SYSTEM_ADMIN, VENDOR_ROUTES } from "libs/routes";
import { useEffect } from "react";
import { connect } from "react-redux";
import { OfferzLogo } from "static/images/svgs/offerz-logo";
import { AppDispatch, ApplicationState } from "store/application-state";
import { getOfferzCategoriesLookupList } from "store/slices/offerz-landing-page";
import { MenuItemLabel } from "utils/shared";
import translate from "utils/translations";
import { OfferzLandingPageProps } from "./offerz-landing-page.props";
import { push } from "@lagunovsky/redux-react-router";
import { CrosspayLogo } from "static/images/svgs/crosspay-logo";
import { RolesTypes } from "libs/models/login";

const OfferzLandingPage = (props: OfferzLandingPageProps) => {
  useEffect(() => {
    props.getOfferzCategoriesLookup();
  }, []);
  const onLoginClicked = () => {
    props.goTo(ROUTES.LOGIN);
  };
  const goToCategories = () => {
    var route =
      props.userRole === RolesTypes.Admin
        ? SYSTEM_ADMIN.CATEGORIES
        : VENDOR_ROUTES.CATEGORIES;
    props.goTo(route);
  };
  return (
    <div className="mt-10">
      <div className="flex justify-between mx-5">
        <div>
          <CrosspayLogo />
        </div>
        <div>
          <Button
            onClick={onLoginClicked}
            className={`rounded-lg w-full ${COLORS.TEXT_WHITE}`}
            size="md"
            primary
          >
            {translate("Login_Text", "Login")}
          </Button>
        </div>
      </div>
      <div className="flex flex-wrap justify-start space-x-1 align-middle items-center text-center">
        {props.categoriesLookupList.map(({ id, name }) => {
          return (
            <div>
              <MenuItem
                isActive={false}
                menuItem={MenuItemLabel({
                  defaultValue: name,
                  key: id,
                })}
                isExpandable={false}
                onClick={goToCategories}
              />
            </div>
          );
        })}
      </div>
      {props.categoriesLookupList && (
        <div className="my-5">
          <Slider
            imgList={props.sliderImageList}
            sliderIconColor="black"
            duration={5000}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  userCulture: state.offerzlandingpage.userCulture,
  categoriesLookupList: state.offerzlandingpage.categoriesLookupList,
  sliderImageList: state.offerzlandingpage.sliderImageList,
  userRole: state.login.userRole,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOfferzCategoriesLookup: () => {
    dispatch(getOfferzCategoriesLookupList());
  },
  goTo: (route: string) => {
    dispatch(push(route));
  },
});

const OfferzLandingPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferzLandingPage);

export { OfferzLandingPageContainer };
