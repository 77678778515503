import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ErrorsCodes, UserCulture } from "../../libs/enums";
import { CategoryModel } from "../../libs/models/category";
import { Filter } from "../../libs/models/filter";
import { PaginatedList } from "../../libs/models/paginated-list";
import {
  addCategoryService,
  deleteCategoryService,
  editCategoryService,
  getCategoriesListService,
  uploadFile,
} from "../../services/offerz-service";
import { getFilterQuery } from "../../utils/filter";
import { AsyncAppThunk } from "../application-state";
import { setErrorCode } from "./login";
import { setCloseDialog } from "./modal-popup";
import { showLoaderWhile } from "./ui";
import { validateAddCategoryItemState } from "../../pages/Categories/containers/AddCategoryContainer/add-category-popup.policy";
import { validateAddSubCategoryItemState } from "../../pages/Categories/containers/AddSubCategoryContainer/add-sub-category-popup.policy";
import { validateEditCategoryItemState } from "../../pages/Categories/containers/UpdateCategoryContainer/edit-category-popup.policy";

export interface CategoriesStore {
  isValidating: boolean;
  categoriesList: PaginatedList<CategoryModel>;
  parentCategoryLoopkUp: CategoryModel[];
  subCategoryLoopkUp: CategoryModel[];
  filter: Filter;
  userCulture: UserCulture;
  categoryItem: CategoryModel;
  subCategoryItem: CategoryModel;
  categoryFile?: File;
}

const initialState: CategoriesStore = {
  isValidating: false,
  filter: {
    search: { key: "name", value: "" },
    dateProp: { from: null, to: null },
    orderBy: { key: "Name", sort: "asc" },
  },
  subCategoryLoopkUp: [],
  parentCategoryLoopkUp: [],
  categoriesList: {
    list: [],
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
  },
  userCulture: UserCulture.English,
  subCategoryItem: {
    id: "",
    name: "",
    pictureFileId: "",
    parentCategoryId: "",
    children: [],
  },
  categoryItem: {
    id: "",
    name: "",
    pictureFileId: "",
    parentCategoryId: null,
    children: [],
  },
};

const slice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setIsValidating: (
      state: CategoriesStore,
      action: PayloadAction<boolean>
    ) => {
      state.isValidating = action.payload;
    },
    setCategoriesList: (
      state: CategoriesStore,
      action: PayloadAction<PaginatedList<CategoryModel>>
    ) => {
      state.categoriesList = action.payload;
    },
    setPage: (state: CategoriesStore, action: PayloadAction<number>) => {
      state.categoriesList.pageIndex = action.payload;
    },
    setCategoryFile: (
      state: CategoriesStore,
      action: PayloadAction<File | undefined>
    ) => {
      state.categoryFile = action.payload;
    },
    setCategoryItemProp: (
      state: CategoriesStore,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      const { name, value } = action.payload;
      state.categoryItem = { ...state.categoryItem, [name]: value };
    },
    setCategoryItem: (
      state: CategoriesStore,
      action: PayloadAction<CategoryModel>
    ) => {
      state.categoryItem = action.payload;
    },
    setSubCategoryItem: (
      state: CategoriesStore,
      action: PayloadAction<CategoryModel>
    ) => {
      state.categoryItem = action.payload;
    },
    setCategoryFileId: (
      state: CategoriesStore,
      action: PayloadAction<string>
    ) => {
      state.categoryItem.pictureFileId = action.payload;
    },
    setCategorySearch: (
      state: CategoriesStore,
      action: PayloadAction<string>
    ) => {
      state.filter.search!.value = action.payload;
    },
    resetCategoriesList: (state: CategoriesStore) => {
      state.categoriesList = initialState.categoriesList;
    },
    setCategoryLookUp: (
      state: CategoriesStore,
      action: PayloadAction<CategoryModel[]>
    ) => {
      state.parentCategoryLoopkUp = action.payload;
    },
    setSubCategoryLookUp: (
      state: CategoriesStore,
      action: PayloadAction<string>
    ) => {
      const selectedCategoryId = action.payload;
      const selectedCategory = state.parentCategoryLoopkUp.find(
        (obj) => obj.id === selectedCategoryId
      );
      state.subCategoryLoopkUp =
        selectedCategory === undefined ? [] : selectedCategory.children;
    },
    setClearCategoryItem: (state: CategoriesStore) => {
      state.categoryFile = undefined;
      state.isValidating = false;
      state.categoryItem = initialState.categoryItem;
    },
  },
});

export const {
  setIsValidating,
  setPage,
  setCategorySearch,
  setCategoryItemProp,
  setCategoriesList,
  setCategoryFile,
  setClearCategoryItem,
  setCategoryItem,
  resetCategoriesList,
  setCategoryFileId,
  setCategoryLookUp,
  setSubCategoryLookUp,
} = slice.actions;
export const reducer = slice.reducer;

export const getCategoriesList =
  (): AsyncAppThunk => async (dispatch, store) => {
    dispatch(
      showLoaderWhile(async () => {
        const categoriesListResponse = await getCategoriesListService(
          getFilterQuery(
            store().categories.filter,
            store().categories.categoriesList.pageIndex *
              store().categories.categoriesList.pageSize,
            store().categories.categoriesList.pageSize
          )
        );
        if (categoriesListResponse && categoriesListResponse?.result !== null) {
          dispatch(setCategoriesList(categoriesListResponse!.result));
        }
      })
    );
  };
export const getCategoriesLookUp = (): AsyncAppThunk => async (dispatch) => {
  dispatch(
    showLoaderWhile(async () => {
      const categoriesListResponse = await getCategoriesListService(null);
      if (categoriesListResponse && categoriesListResponse?.result !== null) {
        dispatch(setCategoryLookUp(categoriesListResponse!.result.list));
      }
    })
  );
};
export const deleteCategory =
  (id: string): AsyncAppThunk =>
  async (dispatch, store) => {
    dispatch(
      showLoaderWhile(async () => {
        const response = await deleteCategoryService(id);
        if (response && response?.result !== null) {
          dispatch(setCloseDialog());
          dispatch(getCategoriesList());
        }
      })
    );
  };

export const editCategory = (): AsyncAppThunk => async (dispatch, store) => {
  const categoryModelItem = store().categories.categoryItem!;
  dispatch(setIsValidating(true));
  if (
    Object.keys(validateEditCategoryItemState(store().categories)).length == 0
  ) {
    dispatch(
      showLoaderWhile(async () => {
        const response = await editCategoryService(categoryModelItem);
        if (response && response?.result !== null) {
          dispatch(setCloseDialog());
          dispatch(setClearCategoryItem());
          dispatch(getCategoriesList());
        }
      })
    );
  }
};
export const addCategory = (): AsyncAppThunk => async (dispatch, store) => {
  const categoryModelItem = store().categories.categoryItem!;
  dispatch(setIsValidating(true));
  if (
    Object.keys(validateAddCategoryItemState(store().categories)).length == 0
  ) {
    dispatch(
      showLoaderWhile(async () => {
        const response = await addCategoryService(categoryModelItem);
        if (response && response?.result !== null) {
          dispatch(setCloseDialog());
          dispatch(setClearCategoryItem());
          dispatch(getCategoriesList());
        }
      })
    );
  }
};
export const addSubCategory = (): AsyncAppThunk => async (dispatch, store) => {
  const categoryModelItem = store().categories.categoryItem!;
  dispatch(setIsValidating(true));
  if (
    Object.keys(validateAddSubCategoryItemState(store().categories)).length == 0
  ) {
    dispatch(
      showLoaderWhile(async () => {
        const response = await addCategoryService(categoryModelItem);
        if (response && response?.result !== null) {
          dispatch(setCloseDialog());
          dispatch(setClearCategoryItem());
          dispatch(getCategoriesList());
        }
      })
    );
  }
};
export const UploadCategoryFile =
  (file: File): AsyncAppThunk =>
  async (dispatch, store) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(setCategoryFile(file));
    dispatch(
      showLoaderWhile(async () => {
        const response = await uploadFile(formData);

        if (response && response.result != null) {
          dispatch(setCategoryFileId(response.result));
        } else {
          dispatch(setErrorCode(response?.errorCode as ErrorsCodes));
        }
      })
    );
  };
