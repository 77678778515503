import { push } from '@lagunovsky/redux-react-router'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router'
import { TermsConditionsDialogContainer } from '../../containers/terms-conditions-dialog'
import { TopNavigationBarContainer } from '../../containers/top-navigation-bar'
import { LoggedUserStatus } from '../../libs/models/login'
import { AppDispatch, ApplicationState } from '../../store'
import { OnConfirmTermsConditions, UserRegistered } from '../../store/slices/login'
import { AppLayoutProps } from './vendor-admin-app-layout.props'

const VendorAdminApplayout = (props: AppLayoutProps) => {
    useEffect(() => {
        props.UserRegistered();
    }, []);
    const isVendorApproved = () => {
        return (
            props.loggedUserRegistrationStatus !== null &&
            props.loggedUserRegistrationStatus === LoggedUserStatus.OfferzMember
        );
    };


    return isVendorApproved() ?
        (
            <div className="flex-column w-full h-screen">
                <div className="w-full` border-b h-[10%] min-h-[70px] px-[80px] bg-white">
                    <TopNavigationBarContainer />
                </div>
                <div className="flex w-full h-[90%] bg-[#FCFCFD] px-[80px] justify-center items-center">
                    <Outlet />
                </div>
                <TermsConditionsDialogContainer
                    onConfirm={() => props.onConfirmTermsConditions()}
                    open={props.isTermsConditionsDialogOpen}
                />
            </div>
        ) : <></>
};

const mapStateToProps = (state: ApplicationState) => ({
    loggedUserRegistrationStatus: state.login.loggedUserRegistrationStatus,

    isTermsConditionsDialogOpen: state.login.isTermsConditionsDialogOpen,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    UserRegistered: () => {
        dispatch(UserRegistered());
    },
    goTo: (route: string) => {
        dispatch(push(route));
    },
    onConfirmTermsConditions: () => {
        dispatch(OnConfirmTermsConditions());
    }
});

const VendorAdminApplayoutContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VendorAdminApplayout);
export { VendorAdminApplayoutContainer }


