import { Button, FileUploader, TextInput } from '@ofrz/ui-package';
import { FilesTypes } from '@ofrz/ui-package/dist/components/FileUploader/types';
import React from 'react';
import { connect } from 'react-redux';
import { validateEditCategoryItemState } from './edit-category-popup.policy';
import { EditCategoryPopupProps } from './edit-category-popup.props';
import translate from '../../../../utils/translations';
import { ApplicationState, AppDispatch } from '../../../../store';
import { setCategoryItemProp, UploadCategoryFile, editCategory, setClearCategoryItem } from '../../../../store/slices/categories';
import { setCloseDialog } from '../../../../store/slices/modal-popup';

const EditCategoryPopUp = (props: EditCategoryPopupProps) => {
  const onPropChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    props.onPropChange(name, value);
  };
  const onChangeCategoryFile = (file?: File) => {
    props.onChangeCategoryFile(file);
  };
  const editCategory = () => {
    props.editCategory();
  };
  const onCloseDialog = () => {
    props.setCloseDialog();
  };
  const RenderSubCategoriesComponent = () => {
    return (
      <div className="flex justify-start flex-wrap gap-1">
        {props.categoryItem.children.map(({ name, id }) => {
          return (
            <div
              key={id}
              className="bg-[#518c7d1a]  rounded-[16px] inline-block px-3 "
            >
              <div className="flex align-middle items-center">
                <div className=" text-[#518c7d]">{name}</div>
              </div>
            </div>
          );
        })}
      </div>

    );
  };

  return (
    <div className="flex flex-col">
      <div className="mt-2">
        <TextInput
          title={translate("Category_Name", "Category Name")}
          className="w-full"
          placeholder={translate("Enter_Category_Name_Here", "Please enter category name here")}
          type="text"
          name="name"
          value={props.categoryItem.name}
          onChange={onPropChange}
          error={props.validationState.name}
        />
      </div>
      <div className="mt-2">
        <label>
          {translate("Sub_Category", "Sub Categories")}
        </label>
      </div>
      <div className="mt-2">
        <RenderSubCategoriesComponent />
      </div>
      <div className="mt-2">
        <FileUploader
          placeholder={translate(
            "Upload_Category_Image",
            "Upload Category Image"
          )}
          error={props.validationState.parentCategoryId}
          selectedFile={props.categoryFile}
          supportedFiles={[FilesTypes.pdf, FilesTypes.png, FilesTypes.jpg]}
          onChange={onChangeCategoryFile}
        />
      </div>
      <div className="flex justify-end self-end gap-3 items-end mt-10">
        <Button
          className="text-[#F04438] rounded-lg !bg-white !w-[115px] !h-[45px]  !text-[14px] !font-medium border-[1px] border-[#F04438]"
          onClick={onCloseDialog}
        >
          {translate("Cancel", "Cancel")}
        </Button>
        <Button
          className="text-white rounded-lg !bg-[#518c7d] w-[115px] h-[45px] !text-[14px] font-medium"
          onClick={editCategory}
        >
          {translate("Edit", "Edit")}
        </Button>
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  categoryItem: state.categories.categoryItem,
  categoryFile: state.categories.categoryFile,
  validationState: validateEditCategoryItemState(state.categories),
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onPropChange: (name: string, value: string) => {
    dispatch(setCategoryItemProp({ name: name, value: value }));
  },
  onChangeCategoryFile: (file?: File) => {
    dispatch(UploadCategoryFile(file!));
  },
  editCategory: () => {
    dispatch(editCategory());
  },
  setCloseDialog: () => {
    dispatch(setClearCategoryItem());
    dispatch(setCloseDialog());
  },
});

const EditCategoryPopUpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCategoryPopUp);

export { EditCategoryPopUpContainer };

