import {
  Button,
  Dialog,
  FileUploader,
  PhoneNumber,
  TextInput,
  WebsiteInput,
} from "@ofrz/ui-package";
import React from "react";
import { connect } from "react-redux";
import { COLORS } from "../../libs/colors";
import { CountryCodes, MobileServicesCode } from "../../libs/country-codes";
import { AppDispatch, ApplicationState } from "../../store";
import {
  UploadFile,
  registerVendor,
  setAddBranchDialogIsOpen,
  setVendorAcceptedTerms,
  setVendorMobileNumber,
  setVendorName,
  setVendorPhoneNumber,
  setWebsite,
} from "../../store/slices/vendor-details";
import translate from "../../utils/translations";
import { validateVendorRegisterRequestState } from "./vendor-details.policy";
import { VendoreDetailsProps } from "./vendor-details.props";
import { AddBranchsContainer } from "../AddBranch";
import ErrorText from "../../components/error-text";
import { AddBranchComponentContainer } from "./components/add-branch-component";
import { FilesTypes } from "@ofrz/ui-package/dist/components/FileUploader/types";
import { TermsConditionsDialogContainer } from "../../containers/terms-conditions-dialog/terms-condition-dialog-container";
import { setIsTermsConditionsDialogOpen } from "../../store/slices/login";

const VendorDetails = (props: VendoreDetailsProps) => {
  const onChangeVendorName = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeVendorName(event.target.value.trim());
  };

  const onChangeWebsite = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeWebsite(event.target.value.trim());
  };
  const onChangeMobileNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeMobileNumber(event.target.value.trim());
  };

  const onChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangePhoneNumber(event.target.value.trim());
  };

  const onChangeVendorTaxRegistrationFile = (file?: File) => {
    props.onChangeVendorTaxRegistrationFile(file);
  };
  // const onTermsConditionChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   props.onTermsConditionChange(event.target.checked);
  // };
  const onSubmitClicked = () => {
    props.onSubmit();
  };

  return (
    <div className="flex flex-col py-2">
      <Dialog
        title={translate("Add_New_Branch_Text", "Add New Branch")}
        onClose={() => {}}
        isOpen={props.addBranchDialogIsOpen}
        actionButtons={[]}
        content={<AddBranchsContainer />}
      />

      <div className="mt-2 ">
        <label className={`${COLORS.TEXT_FORM} font-semibold`}>
          {translate("Vendor_Name_Text", "Vendor Name")}
        </label>
        <TextInput
          className="w-full border p-2"
          placeholder="type your name"
          type="text"
          value={props.vendorName}
          onChange={onChangeVendorName}
          error={props.validationState.vendorName}
        />
      </div>
      <div className="mt-2">
        <label className={`${COLORS.TEXT_FORM} font-semibold`}>
          {translate("Website_Text", "Website")}
        </label>
        <WebsiteInput
          className="w-full border p-2"
          placeholder="www.offerz.com"
          value={props.vendorWebsite}
          onChange={onChangeWebsite}
          error={props.validationState.vendorWebsite}
        />
      </div>
      <div className="mt-2">
        <label className={`${COLORS.TEXT_FORM} font-semibold`}>
          {translate(
            "Vendor_Registration_Tax_File_Label",
            "Vendor tax registration file"
          )}
        </label>
        <FileUploader
          placeholder={translate(
            "Vendor_Registration_Tax_File_Placeholder",
            "Upload your tax file"
          )}
          error={props.validationState.vendorTaxRegistrationFile}
          selectedFile={props.vendorTaxRegistrationFile}
          supportedFiles={[FilesTypes.pdf, FilesTypes.jpg, FilesTypes.png]}
          onChange={onChangeVendorTaxRegistrationFile}
        />
      </div>
      <div className="mt-2">
        <label className={`${COLORS.TEXT_FORM} font-semibold`}>
          {translate("Phone_Number_Text", "Phone Number")}
        </label>
        <PhoneNumber
          defaultCountryCode="+02"
          value={props.vendorPhoneNumber}
          onChange={onChangePhoneNumber}
          countryCodes={CountryCodes}
          error={props.validationState.vendorMobileNumber}
        />
      </div>
      <div className="mt-2">
        <label className={`${COLORS.TEXT_FORM} font-semibold`}>
          {translate("Mobile_Number_Text", "Mobile Number")}
        </label>
        <PhoneNumber
          defaultCountryCode="011"
          value={props.vendorMobileNumber}
          onChange={onChangeMobileNumber}
          countryCodes={MobileServicesCode}
          error={props.validationState.vendorMobileNumber}
        />
      </div>
      <div className="my-2">
        <AddBranchComponentContainer />
      </div>
      <div className="mt-2">
        <input
          className="mr-[0.5rem]"
          type="checkbox"
          disabled
          checked={props.vendorTermsConditionId != null}
        />
        <label
          className={`text-black font-semibold`}
          onClick={() => props.onOpenTermsConditionsDialogOpen(true)}>
          {translate("Agree_to_Text", "I Agree to ")}
          <span className={`${COLORS.TEXT_PRIMARY}`}>
            {translate("Terms_Conditions_Text", "the terms and conditions")}
          </span>
        </label>
        <ErrorText text={props.validationState.vendorTermsConditionId} />
      </div>
      <div className="mt-6 flex justify-end">
        <Button
          onClick={onSubmitClicked}
          className="rounded-lg  text-white w-1/5 text-center !px-0"
          size="md"
          primary>
          {translate("Submit_Text", "Submit")}
        </Button>
      </div>
      <TermsConditionsDialogContainer
        onConfirm={(id:string|null) => {
          props.onTermsConditionChange(id);
          props.onOpenTermsConditionsDialogOpen(false);
        }}
        open={props.isTermsConditionsDialogOpen}
      />
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  userCulture: state.vendordetails.userCulture,
  vendorName: state.vendordetails.vendorName,
  vendorMobileNumber: state.vendordetails.vendorMobileNumber,
  vendorPhoneNumber: state.vendordetails.vendorPhoneNumber,
  vendorWebsite: state.vendordetails.vendorWebsite,
  vendorTaxRegistrationFileId: state.vendordetails.vendorTaxRegistrationFileId,
  vendorTaxRegistrationFile: state.vendordetails.vendorTaxRegistrationFile,
  vendorBranches: state.vendordetails.vendorBranches,
  vendorTermsConditionId: state.vendordetails.vendorTermsConditionId,
  addBranchDialogIsOpen: state.vendordetails.addBranchDialogIsOpen,
  validationState: validateVendorRegisterRequestState(state.vendordetails),
  isTermsConditionsDialogOpen: state.login.isTermsConditionsDialogOpen,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onChangeVendorName: (firstName: string) => {
    dispatch(setVendorName(firstName));
  },
  onChangeWebsite: (lastName: string) => {
    dispatch(setWebsite(lastName));
  },
  onChangeMobileNumber: (mobileNumber: string) => {
    dispatch(setVendorMobileNumber(mobileNumber));
  },
  onChangePhoneNumber: (phoneNumber: string) => {
    dispatch(setVendorPhoneNumber(phoneNumber));
  },
  onChangeVendorTaxRegistrationFile: (file?: File) => {
    dispatch(UploadFile(file!));
  },
  onTermsConditionChange: (id: string|null) => {
    dispatch(setVendorAcceptedTerms(id));
  },
  onAddNewBranch: () => {
    dispatch(setAddBranchDialogIsOpen(true));
  },
  onSubmit: () => {
    dispatch(registerVendor());
  },
  onOpenTermsConditionsDialogOpen: (open: boolean) => {
    dispatch(setIsTermsConditionsDialogOpen(open));
  },
});

const VendorDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorDetails);
export { VendorDetailsContainer };
