import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserCulture } from "../../libs/enums";
import { BrandModel } from "../../libs/models/brand";
import { Filter } from "../../libs/models/filter";
import { PaginatedList } from "../../libs/models/paginated-list";
import { AsyncAppThunk } from "../application-state";
import { getBrandsListService } from "../../services/offerz-service";

export interface BrandStore {
    isValidating: boolean;
    brandList: PaginatedList<BrandModel>;
    brandLoopkUp: BrandModel[];
    filter: Filter
    userCulture: UserCulture;


}

const initialState: BrandStore = {
    isValidating: false,
    filter: {
        search: { key: "Name", value: "" },
        dateProp: { from: null, to: null },
        orderBy: { key: "Name", sort: "asc" },
    },
    brandLoopkUp: <BrandModel[]>[],
    brandList: {
        list: [],
        pageIndex: 0,
        pageSize: 10,
        totalCount: 0,
    },
    userCulture: UserCulture.English,
};

const slice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        setIsValidating: (
            state: BrandStore,
            action: PayloadAction<boolean>
        ) => {
            state.isValidating = action.payload;
        },
        setBrandLookUpList: (
            state: BrandStore,
            action: PayloadAction<BrandModel[]>
        ) => {
            state.brandLoopkUp = action.payload;
        },
    }
});

export const {
    setIsValidating,
    setBrandLookUpList
} = slice.actions;
export const reducer = slice.reducer;


export const getBrandsLookUp = (): AsyncAppThunk => async (dispatch) => {
    const brandsListResponse = await getBrandsListService();
            if (brandsListResponse && brandsListResponse?.result !== null) {
                dispatch(setBrandLookUpList(brandsListResponse!.result.list));
            }
};