import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorsCodes, UserCulture } from "../../libs/enums";
import { PaginatedList } from "../../libs/models/paginated-list";
import { ProductModel } from "../../libs/models/product";
import { AsyncAppThunk } from "../application-state";
import {
  addProductService,
  deleteProductService,
  editProductService,
  getProductListService,
  productAutoCompleteService,
  uploadFile,
} from "../../services/offerz-service";
import { setClearCategoryItem, getCategoriesList } from "./categories";
import { setCloseDialog } from "./modal-popup";
import { showLoaderWhile } from "./ui";
import { getFilterQuery } from "../../utils/filter";
import { Filter } from "../../libs/models/filter";
import { setErrorCode } from "./signup";
import { validateAddProductItemState } from "../../pages/AdminProduct/containers/AddAdminProductContainer/add-admin-product-popup.policy";

export interface ProductStore {
  productList: PaginatedList<ProductModel>;
  productItem: ProductModel;
  productFile?: File;
  filter: Filter;
  productListLookUp: ProductModel[];
  userCulture: UserCulture;
  isValidating: boolean;
}
const initialState: ProductStore = {
  isValidating: false,
  productItem: <ProductModel>{},
  filter: {
    search: { key: "Name", value: "" },
    dateProp: { from: null, to: null },
    orderBy: { key: "Name", sort: "asc" },
  },
  productList: {
    list: [],
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
  },
  productListLookUp: [],
  userCulture: UserCulture.English,
};
const slice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setIsValidating: (state: ProductStore, action: PayloadAction<boolean>) => {
      state.isValidating = action.payload;
    },
    setProductList: (
      state: ProductStore,
      action: PayloadAction<PaginatedList<ProductModel>>
    ) => {
      state.productList = action.payload;
    },
    setProductListPage: (state: ProductStore, action: PayloadAction<number>) => {
      state.productList.pageIndex = action.payload;
    },
    setProductItemProp: (
      state: ProductStore,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      const { name, value } = action.payload;
      state.productItem = { ...state.productItem, [name]: value };
    },
    setProductItem: (
      state: ProductStore,
      action: PayloadAction<ProductModel>
    ) => {
      state.productItem = action.payload;
    },
    setProductListLookUp: (
      state: ProductStore,
      action: PayloadAction<ProductModel[]>
    ) => {
      state.productListLookUp = action.payload;
    },
    setProductFile: (
      state: ProductStore,
      action: PayloadAction<File | undefined>
    ) => {
      state.productFile = action.payload;
    },
    setProductFileId: (state: ProductStore, action: PayloadAction<string>) => {
      state.productItem.pictureFileId = action.payload;
    },
    setClearProductItem: (state: ProductStore) => {
      state.productItem = initialState.productItem;
      state.productFile = initialState.productFile;

    },
  },
});
export const {
  setIsValidating,
  setClearProductItem,
  setProductListLookUp,
  setProductList,
  setProductItemProp,
  setProductFile,
  setProductFileId,
  setProductItem,
  setProductListPage
} = slice.actions;

export const reducer = slice.reducer;

export const getProductList = (): AsyncAppThunk => async (dispatch, store) => {
  dispatch(
    showLoaderWhile(async () => {
      const productListResponse = await getProductListService(
        getFilterQuery(
          store().product.filter,
          store().product.productList.pageIndex *
          store().product.productList.pageSize,
          store().product.productList.pageSize
        )
      );
      if (productListResponse && productListResponse?.result !== null) {
        dispatch(setProductList(productListResponse!.result));
      }
    })
  );
};
export const autoProductTypingComplete =
  (searchText: string): AsyncAppThunk =>
    async (dispatch) => {
      if (searchText === "") { dispatch(setProductListLookUp([])) }
      else {
        const response = await productAutoCompleteService(searchText);
        if (response && response.result !== null) {
          dispatch(setProductListLookUp(response.result));
        } else {
          dispatch(setProductListLookUp([]));
        }
      }
    };
export const addProduct = (): AsyncAppThunk => async (dispatch, store) => {
  dispatch(setIsValidating(true));
  const productModelItem = store().product.productItem;
  if (Object.keys(validateAddProductItemState(store().product)).length == 0) {
    dispatch(
      showLoaderWhile(async () => {
        const response = await addProductService(productModelItem);
        if (response && response?.result !== null) {
          dispatch(setCloseDialog());
          dispatch(setClearProductItem());
          dispatch(getProductList());
        }
      })
    );
  }
};
export const editProduct = (): AsyncAppThunk => async (dispatch, store) => {
  const productModelItem = store().product.productItem;
  dispatch(setIsValidating(true));
  if (Object.keys(validateAddProductItemState(store().product)).length == 0) {
    dispatch(
      showLoaderWhile(async () => {
        const response = await editProductService(productModelItem);
        if (response && response?.result !== null) {
          dispatch(setCloseDialog());
          dispatch(setClearProductItem());
          dispatch(getProductList());
        }
      })
    );
  }
};
export const deleteProduct =
  (id: string): AsyncAppThunk =>
    async (dispatch, store) => {
      dispatch(
        showLoaderWhile(async () => {
          const response = await deleteProductService(id);
          if (response && response?.result !== null) {
            dispatch(setCloseDialog());
            dispatch(getProductList());
          }
        })
      );
    };
export const UploadProductFile =
  (file: File): AsyncAppThunk =>
    async (dispatch, store) => {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(setProductFile(file));
      dispatch(
        showLoaderWhile(async () => {
          const response = await uploadFile(formData);

          if (response && response.result != null) {
            dispatch(setProductFileId(response.result));
          } else {
            dispatch(setErrorCode(response?.errorCode as ErrorsCodes));
          }
        })
      );
    };
