import { Button, FileUploader, TextInput } from '@ofrz/ui-package';
import { FilesTypes } from '@ofrz/ui-package/dist/components/FileUploader/types';
import React from 'react';
import { connect } from 'react-redux';
import { AddCategoryPopupProps } from './add-category-popup.props';
import { validateAddCategoryItemState } from './add-category-popup.policy';
import { ApplicationState, AppDispatch } from '../../../../store';
import { setCloseDialog } from '../../../../store/slices/modal-popup';
import translate from '../../../../utils/translations';
import { setCategoryItemProp, UploadCategoryFile, addCategory, setClearCategoryItem } from '../../../../store/slices/categories';


const AddCategoryPopUp = (props: AddCategoryPopupProps) => {
    const onPropChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        props.onPropChange(name, value);
    };
    const onChangeCategoryFile = (file?: File) => {
        props.onChangeCategoryFile(file);
    };
    const addCategory = () => {
        props.addCategory();
    };
    const onCloseDialog = () => {
        props.setCloseDialog();
    };
    return (
        <div className="flex flex-col">
            <div className="mt-2">
                <TextInput
                    title={translate("Category_Name", "Category Name")}
                    className="w-full"
                    placeholder={translate("Enter_Category_Name_Here", "Please enter category name here")}
                    type="text"
                    name="name"
                    value={props.categoryItem.name}
                    onChange={onPropChange}
                    error={props.validationState.name}
                />
            </div>
            <div className="mt-2">
                <FileUploader
                    placeholder={translate(
                        "Upload_Category_Image",
                        "Upload Category Image"
                    )}
                    error={props.validationState.parentCategoryId}
                    selectedFile={props.categoryFile}
                    supportedFiles={[FilesTypes.pdf, FilesTypes.png, FilesTypes.jpg]}
                    onChange={onChangeCategoryFile}
                />
            </div>
            <div className="flex justify-end self-end gap-3 items-end mt-10">
                <Button
                    className="text-[#F04438] rounded-lg !bg-white !w-[115px] !h-[45px]  !text-[14px] !font-medium border-[1px] border-[#F04438]"
                    onClick={onCloseDialog}
                >
                    {translate("Cancel", "Cancel")}
                </Button>
                <Button
                    className="text-white rounded-lg !bg-[#518c7d] w-[115px] h-[45px] !text-[14px] font-medium"
                    onClick={addCategory}
                >
                    {translate("Add", "Add")}
                </Button>
            </div>
        </div>
    );
};
const mapStateToProps = (state: ApplicationState) => ({
    categoryItem: state.categories.categoryItem,
    categoryFile: state.categories.categoryFile,
    validationState: validateAddCategoryItemState(state.categories),
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onPropChange: (name: string, value: string) => {
        dispatch(setCategoryItemProp({ name: name, value: value }));
    },
    onChangeCategoryFile: (file?: File) => {
        dispatch(UploadCategoryFile(file!));
    },
    addCategory: () => {
        dispatch(addCategory());
    },
    setCloseDialog: () => {
        dispatch(setClearCategoryItem());
        dispatch(setCloseDialog());
    },
});

const AddCategoryPopUpContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCategoryPopUp);

export { AddCategoryPopUpContainer };

