import { TagProps } from './tag.props'

const Tag = (props:TagProps) => {
    return (
        <div
            key={props.title}
            className="bg-[#518c7d1a]  rounded-[16px] inline-block px-3 ">
            <div className="flex align-middle items-center">
                <div className=" text-[#518c7d] ">{props.title}</div>
            </div>
        </div>
    )
}

export default Tag