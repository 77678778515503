import { Button, TextInput } from "@ofrz/ui-package";
import moment from "moment";
import { connect } from "react-redux";
import { COLORS } from "../../../../libs/colors";
import { AppDispatch, ApplicationState } from "../../../../store";
import {
  editVendorMagazine,
  setClearMagazineItem,
  setMagazineItemProp,
} from "../../../../store/slices/magazine";
import { setCloseDialog } from "../../../../store/slices/modal-popup";
import translate from "../../../../utils/translations";
import { UpdateMagazinePopupProps } from "./update-magazine.props";
import { validateMagazineItemState } from "../../magazine.policy";

const UpdateMagazinePopUp = (props: UpdateMagazinePopupProps) => {
  const onPropChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    props.onPropChange(name, value);
  };

  const editVendorMagazine = () => {
    props.editVendorMagazine();
  };
  const onCloseDialog = () => {
    props.setCloseDialog();
  };
  return (
    <div className="flex flex-col">
      <div className="mt-2">
        <TextInput
          title={translate("Magazine_Name", "Magazine Name")}
          className="w-full"
          placeholder={translate("Enter_Magazine_Name_Here", "Please enter magazine name here")}
          type="text"
          name="title"
          value={props.magazineItem.title}
          onChange={onPropChange}
          error={props.validationState.title}
        />
      </div>
      <div className="flex mt-2 justify-between w-full gap-5 align-middle items-center mb-4">
        <div className="w-full flex-col justify-between self-end">
          <label className={`${COLORS.TEXT_FORM} font-semibold`}>
            {translate("From", "From")}
          </label>
          <div className="w-full h-12">
            <input
              type="date"
              id="from"
              name="from"
              className="w-full align-middle p-2 border-2 rounded-lg border-[#D0D5DD]"
              onChange={onPropChange}
              value={moment(props.magazineItem.from).format("YYYY-MM-DD")}
            />
             {props.validationState.from && <p className="text-red-500 text-sm mt-1">{props.validationState.from}</p>}
          </div>
        </div>
        <div className="w-full flex-col justify-between self-end">
          <label className={`${COLORS.TEXT_FORM} font-semibold`}>
            {translate("To", "To")}
          </label>
          <div className="w-full h-12">
            <input
              type="date"
              id="to"
              name="to"
              className="w-full align-middle p-2 border-2 rounded-lg border-[#D0D5DD]"
              onChange={onPropChange}
              value={moment(props.magazineItem.to).format("YYYY-MM-DD")}
            />                        
            {props.validationState.from && <p className="text-red-500 text-sm mt-1">{props.validationState.from}</p>}
          </div>
        </div>
      </div>
      <div className="mt-2">
        <TextInput
          title={translate("Magazine_Description", "TODO Magazine Description")}
          className="w-full"
          placeholder="Magazine Description"
          type="text"
          name="description"
          value={props.magazineItem.description}
          onChange={onPropChange}
          error={props.validationState.description}
        />
      </div>

      <div className="flex justify-end self-end gap-3 items-end mt-10">
        <Button
          className="text-[#F04438]  rounded-lg !bg-white !w-[115px] !h-[45px]  !text-[14px] !font-medium border-[1px] border-[#F04438]"
          onClick={onCloseDialog}
        >
          {translate("Cancel", "Cancel")}
        </Button>
        <Button
          className="text-white rounded-lg !bg-[#518c7d] w-[115px] h-[45px] !text-[14px] font-medium"
          onClick={editVendorMagazine}
        >
          {translate("Edit", "Edit")}
        </Button>
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  magazineItem: state.magazine.magazineItem,
  magazineFile: state.magazine.magazineFile,
  validationState: validateMagazineItemState(state.magazine),
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onPropChange: (name: string, value: string) => {
    dispatch(setMagazineItemProp({ name: name, value: value }));
  },

  editVendorMagazine: () => {
    dispatch(editVendorMagazine());
  },
  setCloseDialog: () => {
    dispatch(setClearMagazineItem());
    dispatch(setCloseDialog());
  },
});

const UpdateMagazinePopUpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateMagazinePopUp);

export default UpdateMagazinePopUpContainer;
