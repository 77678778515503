import { connect } from "react-redux";

import { useEffect, useState } from "react";

import { COLORS } from "libs/colors";
import { DealsModel } from "libs/models/deals";
import { OfferzLogo } from "static/images/svgs/offerz-logo";
import { AppDispatch, ApplicationState } from "store";
import { deleteCategory, setClearCategoryItem } from "store/slices/categories";
import { setDealItem } from "store/slices/deals";
import {
  ModalPopupStore,
  setCloseDialog,
  setDialogStateChange,
} from "store/slices/modal-popup";
import { getFileUrl } from "utils/mediaUrlFormater";
import translate from "utils/translations";
import { DeletDealPopUp } from "../components/delete-deal-popup";
import { EditDealPopUpContainer } from "../containers/UpdateCategoryContainer";
import { DealCardItemProps } from "./deal-item.props";
import { CrosspayLogo } from "static/images/svgs/crosspay-logo";

const DealCardItem = (props: DealCardItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClicks);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClicks);
    };
  }, [isOpen]);
  const handleOutsideClicks = (event: any) => {
    if (isOpen && event.target.name !== "menu-option") {
      setIsOpen(false);
    }
  };

  const onDeleteItem = () => {
    props.onDeleteCategoryClick({
      isOpen: true,
      title: <></>,
      content: <DeletDealPopUp />,
      actionButtons: [
        {
          children: (
            <div className={`${COLORS.TEXT_WHITE} bg-red`}>
              {translate("Close", "Close")}
            </div>
          ),
          className: `rounded-lg`,
          primary: true,
          onClick: props.setCloseDialog,
        },
        {
          children: (
            <div className={`${COLORS.TEXT_WHITE} bg-red`}>
              {translate("Delete", "Delete")}
            </div>
          ),
          className: `rounded-lg`,
          primary: true,

          onClick: () => props.deleteCategory(props.dealItem.id),
        },
      ],
      onClose: () => props.setCloseDialog(),
    });
    setIsOpen(false);
  };
  const onEditItem = () => {
    props.onEditCategoryClick(
      {
        isOpen: true,
        title: <p>{translate("Edit_Category", "Edit Category")}</p>,
        content: <EditDealPopUpContainer />,
        actionButtons: [],
        onClose: () => props.setCloseDialog(),
      },
      props.dealItem
    );
    setIsOpen(false);
  };
  const onAddSubCategory = () => {
    props.onAddSubCategoryClick({
      isOpen: true,
      title: <p>{translate("Add_Sub_Category", "Add Sub Category")}</p>,
      content: <EditDealPopUpContainer />,
      actionButtons: [],
      onClose: () => props.setCloseDialog(),
    });
  };

  return (
    <div
      className="rounded-[8px] shadow-lg w-[300px] p-3 flex-col"
      key={props.dealItem.id}
    >
      <div className="flex justify-between">
        <div className="text-ellipsis line-clamp-2 font-semibold text-[14px]">
          {props.dealItem.name}
        </div>
        <div className="cursor-pointer relative inline-block">
          {/* <div className='' onClick={toggleDropdown}><VerticalDotsIcon /></div>
                        {isOpen && (
                            <div id="myDropdown" className="absolute shadow-md  min-w-[100px] ">
                                <button className='hover:bg-[#2980B9] px-2 py-2 w-full text-start' onClick={onEditItem} name="menu-option">Edit</button>
                                <hr />
                                <button className='hover:bg-[#2980B9] px-2 py-2 w-full text-start' onClick={onDeleteItem} name="menu-option">Delete</button>
                            </div>
                        )} */}
        </div>
      </div>
      <div className="flex w-auto h-[110px] bg-white rounded-[10px] mr-3 justify-center items-center my-3">
        {" "}
        {props.dealItem.pictureFileId === null ? (
          <CrosspayLogo width="auto" height="90" />
        ) : (
          <img
            src={getFileUrl(props.dealItem.pictureFileId!)}
            alt="Downloaded Image"
            className="object-cover w-full h-full rounded-lg"
          />
        )}
      </div>

      <div className="text-ellipsis line-clamp-2  text-[12px]">
        {props.dealItem.description}
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  deleteCategory: (id: string) => {
    dispatch(deleteCategory(id));
  },
  onDeleteCategoryClick: (modalDialogProps: ModalPopupStore) => {
    dispatch(setDialogStateChange(modalDialogProps));
  },
  onEditCategoryClick: (
    modalDialogProps: ModalPopupStore,
    dealItem: DealsModel
  ) => {
    dispatch(setDealItem(dealItem));
    dispatch(setDialogStateChange(modalDialogProps));
  },
  onAddSubCategoryClick: (modalDialogProps: ModalPopupStore) => {
    dispatch(setDialogStateChange(modalDialogProps));
  },
  setCloseDialog: () => {
    dispatch(setClearCategoryItem());
    dispatch(setCloseDialog());
  },
});

const DealCardItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DealCardItem);
export { DealCardItemContainer };
