import { Button, FileUploader, TextInput } from "@ofrz/ui-package";
import { FilesTypes } from "@ofrz/ui-package/dist/components/FileUploader/types";
import { connect } from "react-redux";
import { SelectOptionComponent } from "../../../../components/select-option";
import { CategoryModel } from "../../../../libs/models/category";
import { AppDispatch, ApplicationState } from "../../../../store";
import {
  setSubCategoryLookUp
} from "../../../../store/slices/categories";
import { setCloseDialog } from "../../../../store/slices/modal-popup";
import {
  editProduct,
  setClearProductItem,
  setProductItemProp,
  UploadProductFile
} from "../../../../store/slices/product";
import translate from "../../../../utils/translations";
import { validateUpdateProductItemState } from "./update-admin-product-popup.policy";
import { UpdateProductPopupProps } from "./update-admin-product-popup.props";

const UpdateProductPopUp = (props: UpdateProductPopupProps) => {
  const onPropChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    props.onPropChange(name, value);
  };
  const onCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    props.setSubCategory(value);
  };
  const onSubCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    props.onPropChange(name, value);
  };
  const editProduct = () => {
    props.editProduct();
  };
  const onCloseDialog = () => {
    props.setCloseDialog();
  };
  const onChangeFile = (file?: File) => {
    props.onChangeFile(file);
  };
  const settingLookupList = (lookup: any[]) => {
    const options = lookup.map((obj: CategoryModel) => {
      return { value: obj.id, label: obj.name };
    });
    return options;
  };
  return (
    <div className="flex flex-col">
      <div className="mt-2">
        <TextInput
          title={translate("Product_Name_Text", "Product Name")}
          className="w-full"
          placeholder={translate("Enter_Product_Name_Here", "Please enter product name here")}
          type="text"
          name="name"
          value={props.productItem.name}
          onChange={onPropChange}
          error={props.validationState.name}
        />
      </div>
      <div className="mt-2">
        <SelectOptionComponent
          disabled={false}
          id="categoryId"
          title={translate("Category_Text", "Category")}
          className="w-full"
          placeholder="Select Category"
          name="categoryId"
          defaultValue={props.categoryId ?? ""}
          onChange={onCategoryChange}
          error={props.validationState.subCategoryId}
          optionsList={settingLookupList(props.parentCategoryLoopkUp)}
        />
      </div>
      <div className="mt-2">
        <SelectOptionComponent
          disabled={props.subCategoryLookUp.length === 0}
          id="subCategoryId"
          title={translate("Sub_Category_Text", "Sub Category")}
          className="w-full"
          placeholder="Select Sub Category"
          name="subCategoryId"
          value={props.productItem.subCategoryId ?? ""}
          onChange={onSubCategoryChange}
          error={props.validationState.subCategoryId}
          optionsList={settingLookupList(props.subCategoryLookUp)}
        />
      </div>
      <div className="mt-2">
        <SelectOptionComponent
          id="brandId"
          title={translate("Brand_Text", "Brand")}
          className="w-full"
          placeholder="Select Brand"
          name="brandId"
          value={props.productItem.brandId ?? ""}
          onChange={onSubCategoryChange}
          error={props.validationState.brandId}
          optionsList={settingLookupList(props.brandLookUp)}
        />
      </div>
      <div className="mt-5">
        <FileUploader
          placeholder={translate(
            "Upload_Product_Image",
            "Upload Product Image"
          )}
          error={props.validationState.pictureFileId}
          selectedFile={props.productFile}
          supportedFiles={[FilesTypes.png, FilesTypes.jpg]}
          onChange={onChangeFile}
        />
      </div>
      <div className="flex justify-end self-end gap-3 items-end mt-10">
        <Button
          className="text-[#F04438] rounded-lg !bg-white !w-[115px] !h-[45px]  !text-[14px] !font-medium border-[1px] border-[#F04438]"
          onClick={onCloseDialog}
        >
          {translate("Cancel", "Cancel")}
        </Button>
        <Button
          className="text-white rounded-lg !bg-[#518c7d] w-[115px] h-[45px] !text-[14px] font-medium"
          onClick={editProduct}
        >
          {translate("Update", "Update")}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  productItem: state.product.productItem,
  productFile: state.product.productFile,
  parentCategoryLoopkUp: state.categories.parentCategoryLoopkUp,
  subCategoryLookUp: state.categories.subCategoryLoopkUp,
  brandLookUp:state.brand.brandLoopkUp,
  validationState: validateUpdateProductItemState(state.product),
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onPropChange: (name: string, value: string) => {
    dispatch(setProductItemProp({ name: name, value: value }));
  },
  editProduct: () => {
    dispatch(editProduct());
  },
  onChangeFile: (file?: File) => {
    dispatch(UploadProductFile(file!));
  },
  setSubCategory: (categoryId: string) => {
    dispatch(setSubCategoryLookUp(categoryId));
  },

  setCloseDialog: () => {
    dispatch(setClearProductItem());
    dispatch(setCloseDialog());
  },
});

const UpdateProductPopUpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateProductPopUp);

export { UpdateProductPopUpContainer };

