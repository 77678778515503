import { Button, FileUploader, TextInput } from '@ofrz/ui-package';
import { FilesTypes } from '@ofrz/ui-package/dist/components/FileUploader/types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AddCategoryPopupProps } from './add-sub-category-popup.props';
import { validateAddSubCategoryItemState } from './add-sub-category-popup.policy';
import { ApplicationState, AppDispatch } from '../../../../store';
import { setCategoryItemProp, UploadCategoryFile, addSubCategory, setClearCategoryItem } from '../../../../store/slices/categories';
import { setCloseDialog } from '../../../../store/slices/modal-popup';
import translate from '../../../../utils/translations';


const AddSubCategoriesPopup = (props: AddCategoryPopupProps) => {
    const onPropChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        props.onPropChange(name, value);
    };
    useEffect(() => {
        props.onPropChange("parentCategoryId", props.parentCategoryId);
    }, [])
    const onChangeCategoryFile = (file?: File) => {
        props.onChangeCategoryFile(file);
    };
    const addSubCategory = () => {
        props.addSubCategory();
    };
    const onCloseDialog = () => {
        props.setCloseDialog();
    };

    return (
        <div className="flex flex-col">
            <div className="mt-2">
                <TextInput
                    title={translate("Category_Name", "Category Name")}
                    disabled={true}
                    className="w-full"
                    placeholder={translate("Enter_Category_Name_Here", "Please enter category name here")}
                    type="text"
                    name="name"
                    value={props.parentCategoryName}
                    onChange={onPropChange}
                    error={props.validationState.parentCategoryId}
                />
            </div>
            <div className="mt-2">
                <TextInput
                    title={translate("Sub_Category", "Sub Category")}
                    className="w-full"
                    placeholder={translate("Sub_Category", "Sub Category")}
                    type="text"
                    name="name"
                    value={props.subCategoryItem.name}
                    onChange={onPropChange}
                    error={props.validationState.name}
                />
            </div>

            <div className="mt-2">
                <FileUploader
                    placeholder={translate(
                        "Upload_Category_Image",
                        "Upload Category Image"
                    )}
                    error={props.validationState.pictureFileId}
                    selectedFile={props.categoryFile}
                    supportedFiles={[FilesTypes.pdf, FilesTypes.png, FilesTypes.jpg]}
                    onChange={onChangeCategoryFile}
                />
            </div>
            <div className="flex justify-end self-end gap-3 items-end mt-10">
                <Button
                    className="text-[#F04438] rounded-lg !bg-white !w-[115px] !h-[45px]  !text-[14px] !font-medium border-[1px] border-[#F04438]"
                    onClick={onCloseDialog}
                >
                    {translate("Cancel", "Cancel")}
                </Button>
                <Button
                    className="text-white rounded-lg !bg-[#518c7d] w-[115px] h-[45px] !text-[14px] font-medium"
                    onClick={addSubCategory}
                >
                    {translate("Add", "Add")}
                </Button>
            </div>
        </div>
    );
};
const mapStateToProps = (state: ApplicationState) => ({
    subCategoryItem: state.categories.categoryItem,
    categoryFile: state.categories.categoryFile,
    validationState: validateAddSubCategoryItemState(state.categories),
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onPropChange: (name: string, value: string) => {
        dispatch(setCategoryItemProp({ name: name, value: value }));
    },
    onChangeCategoryFile: (file?: File) => {
        dispatch(UploadCategoryFile(file!));
    },
    addSubCategory: () => {
        dispatch(addSubCategory());
    },
    setCloseDialog: () => {
        dispatch(setClearCategoryItem());
        dispatch(setCloseDialog());
    },
});

const AddSubCategoriesPopupContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSubCategoriesPopup);

export { AddSubCategoriesPopupContainer };

