const ErrorText = (props: { text: string }) => {
  return (
    <p
      className={`text-red-500 text-xs invisible ${
        props.text && props.text.length > 0 ? "visible" : "invisible"
      } `}>
      {props.text && props.text.length > 0 ? props.text : "Error"}
    </p>
  );
};

export default ErrorText;
