import React, { useState } from "react";
import { ChangePasswordProps } from "./change-password-dialog.props";
import { Dialog, TextInput } from "@ofrz/ui-package";
import { COLORS } from "../../libs/colors";
import translate from "../../utils/translations";
import ErrorText from "../error-text";
import { validateChangePassword } from "./change-password.policy";

const ChangePasswordDialog = (props:ChangePasswordProps) => {
    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    return(
        <Dialog title={translate("Login_Change_Password_Text", "Change Password")} onClose={()=>{}}  isOpen={true} actionButtons={[{
            children: <div className={`${COLORS.TEXT_WHITE}`}>{translate("Submit_Text","Submit")}</div>,
            primary: true,
            className: `rounded-lg w-full`,
            onClick: () => {
                if(Object.keys(validateChangePassword({oldPassword,newPassword,confirmPassword})).length ==0)
                    props.onConfirmPasswordChange({oldPassword,newPassword,confirmPassword})}
          }]} content={
            <>
            <div className="mt-2">
            <label className={`${COLORS.TEXT_FORM} font-semibold`}>
              {translate("Login_Old_Password_Text", "Old password")}
            </label>
            <TextInput
              className="w-full"
              placeholder="Type your password"
              type="password"
              autoComplete="password"
              disableSpace
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
           {validateChangePassword({oldPassword,newPassword,confirmPassword}).oldPassword && <ErrorText text={validateChangePassword({oldPassword,newPassword,confirmPassword}).oldPassword} />}
          </div>
          <div className="mt-2 ">
            <label className={`${COLORS.TEXT_FORM} font-semibold`}>
              {translate("Login_New_Password_Text", "New Password")}
            </label>
            <TextInput
              className="w-full"
              placeholder="Type your password"
              type="password"
              autoComplete="password"
              disableSpace
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            {validateChangePassword({oldPassword,newPassword,confirmPassword}).newPassword && <ErrorText text={validateChangePassword({oldPassword,newPassword,confirmPassword}).newPassword} />}
          </div>
          <div className="mt-2 ">
            <label className={`${COLORS.TEXT_FORM} font-semibold`}>
              {translate("Login_Confirm_Password_Text", "Confirm Password")}
            </label>
            <TextInput
              className="w-full"
              placeholder="Type your password"
              type="password"
              autoComplete="password"
              disableSpace
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {validateChangePassword({oldPassword,newPassword,confirmPassword}).confirmPassword && <ErrorText text={validateChangePassword({oldPassword,newPassword,confirmPassword}).confirmPassword} />}
          </div>
          </>
        } />
      
    )
}

export {ChangePasswordDialog}