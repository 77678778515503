import { Filter } from "../libs/models/filter";
import buildQuery from "odata-query";

const getFilterQuery = (filter: Filter, skip: number, top: number): string => {
  let filterObj: any[] = [];
  let orderBy: string = "";
  Object.keys(filter).map((key: string) => {
    if (key == "search") {
      filterObj = [
        ...filterObj,
        `startswith(${filter.search!.key},'${filter.search!.value}')`,
      ];
    } else if (key == "dateProp") {
     
    } else if (key == "orderBy") {
      orderBy = `${filter.orderBy!.key + " " + filter.orderBy!.sort}`;
    } else {
      filterObj = [...filterObj, { [key]: filter[key] }];
    }
  });
  var obj ={ filter:filterObj, skip:skip, top:top}
  let query = buildQuery({
    filter: filterObj,
    skip: skip,
    top: top,
    orderBy: orderBy,
  });
  let str = `${query}${filter['dateProp'] !=null && filter['dateProp'].from !=null ? "&From="+filter['dateProp'].from:''}${filter['dateProp'] !=null && filter['dateProp'].to !==null ? "&To="+filter['dateProp'].to:''}`
  return str;

};

export { getFilterQuery };
