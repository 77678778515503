
import React from "react";

const  UploadIcon = () => <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 11.6353V12.6353C16.5 14.0354 16.5 14.7355 16.2275 15.2702C15.9878 15.7406 15.6054 16.1231 15.135 16.3628C14.6002 16.6353 13.9001 16.6353 12.5 16.6353H5.5C4.09987 16.6353 3.3998 16.6353 2.86502 16.3628C2.39462 16.1231 2.01217 15.7406 1.77248 15.2702C1.5 14.7355 1.5 14.0354 1.5 12.6353V11.6353M13.1667 5.80192L9 1.63525M9 1.63525L4.83333 5.80192M9 1.63525V11.6353"
      stroke="#518c7d"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

export {UploadIcon}