import { ErrorsCodes } from "../../libs/enums";
import { SignupModel } from "../../libs/models/signup";
import { ValidationModel } from "../../libs/models/validate-model";
import { SignUpStore } from "../../store/slices/signup";
import { getErrorDescription } from "../../utils/errors";
import translate from "../../utils/translations";

export const validateSignupState = (state: SignUpStore): ValidationModel<SignupModel> => {
    let validationModel = {} as ValidationModel<SignupModel>;
  
    if (!state.isValidating) {
      return validationModel;
    }
  
    if (state.ErrorCode && state.ErrorCode == ErrorsCodes.InvalidEmail) {
      validationModel.userName = getErrorDescription(state.ErrorCode);
    }
  
    if (state.ErrorCode && state.ErrorCode == ErrorsCodes.InvalidPassword) {
      validationModel.password = getErrorDescription(state.ErrorCode);
    }
  
    if (state.firstName === null || state.firstName === "") {
      validationModel.firstName = translate(
        "Required_Text",
        "This field is required"
      );
    }
    if (state.lastName === null || state.lastName === "") {
      validationModel.lastname = translate(
        "Required_Text",
        "This field is required"
      );
    }
    var emailRegex = new RegExp("^([\\w\\.\\-]+)@([\\w\\-]+)((\\.(\\w){2,3})+)$");
    if (!emailRegex.test(state.email)) {
      validationModel.userName = translate(
        "Login_Validation_Email_Error_Text",
        "The entered email not a valid email"
      );
    }
  
    if (state.password.length < 8) {
      validationModel.password = translate(
        "Login_Validation_Password_Error_Text",
        "The password can not be less than 8 characters"
      );
    }
  
    return validationModel;
  };
  