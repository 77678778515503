import { VendorProductModel } from "libs/models/vendor-product";

export const renderAutoCompleteVendorProductOptions = (lookUp:VendorProductModel[]) => {  
    const options = lookUp.map((obj) => {
      return {
        value: obj.id!,
        label: obj.productName!,
      };
    });
    return options;
  };