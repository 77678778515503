const enum ErrorsCodes {
  InternalServerError,
  InvalidEmail,
  InvalidPassword,
  InvalidJwtToken,
  InvalidRefreshToken,
  NotVerifiedMobileNumber,
  OtpNotVerified,
  EmailAlreadyTaken,
  MobileNumberAlreadyExist,
  UseNameNotFound,
  InvalidUserName,
  InvalidMobileNumber,
}

const enum UserCulture {
  English = "en-GB",
  Arabic = "ar-EG",
}

const enum CookiesKeys {
  userCulture = "userCulture",
  refreshToken = "refreshToken"
}


export { ErrorsCodes, UserCulture, CookiesKeys };
