import { Dialog } from '@ofrz/ui-package'
import { connect } from 'react-redux'
import { AccountVerificationContainer } from '../../pages/AccountVerification'
import { ApplicationState } from "../../store"
import { PopUpProps } from './pop-up-container.props'


const PopUpComponent = (props: PopUpProps) => {

    return (
        <div className="flex-col">
            <Dialog title={""} onClose={() => { }} isOpen={props.dialogOpen} actionButtons={[]} content={<AccountVerificationContainer email={props.email} />} />
        </div>
    )
}
const mapStateToProps = (state: ApplicationState) => ({
    dialogOpen: state.accountverification.dialogOpen,
});

const PopUpContainer = connect(
    mapStateToProps,

)(PopUpComponent);
export { PopUpContainer };
