import { push } from "@lagunovsky/redux-react-router";
import { ROUTES } from "../../libs";
import { AsyncAppThunk } from "../application-state";
import { clearLoginData } from "./login";
import { clearStepperData } from "./stepper";

export const logout = (): AsyncAppThunk => async (dispatch) => {
    dispatch(clearLoginData());
    localStorage.clear(); 
    dispatch(clearStepperData());
    dispatch(push(ROUTES.LOGIN));

}
