const GiftCardLandingPage = () => {
  return (
    <div className="flex justify-center h-[100vh] w-full items-center">
      <div className="text-[50px] font-semibold text-primary-500 my-auto">
        Coming Soon...
      </div>
    </div>
  );
};

export { GiftCardLandingPage };
