import { DealsModel } from "libs/models/deals";
import { CategoryModel } from "../../../../libs/models/category";
import { ValidationModel } from "../../../../libs/models/validate-model";
import { CategoriesStore } from "../../../../store/slices/categories";
import translate from "../../../../utils/translations";
import { DealsStore } from "store/slices/deals";


export const validateEditDealItemState = (state: DealsStore): ValidationModel<DealsModel> => {
    let validationModel = {} as ValidationModel<DealsModel>;

    if (!state.isValidating) {
        return validationModel;
    }
    if (state.dealItem.name  === null || state.dealItem.name === "") {
        validationModel.name = translate(
          "Required_Text",
          "This field is required"
        );
      }
      if (state.dealItem.pictureFileId  === null || state.dealItem.pictureFileId === "") {
        validationModel.pictureFileId = translate(
          "Required_Text",
          "This field is required"
        );
      }
      if (state.dealItem.appliedOn  === null || state.dealItem.appliedOn.length==0) {
        validationModel.appliedOn = translate(
          "Required_Text",
          "This field is required"
        );
      }
    return validationModel;
};
