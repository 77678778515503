import { push } from "@lagunovsky/redux-react-router";
import { connect } from "react-redux";
import { AppDispatch, ApplicationState } from "../../../store";
import translate from "../../../utils/translations";
import { Button } from "@ofrz/ui-package";
import { useEffect } from "react";
import { SYSTEM_ADMIN } from "../../../libs/routes";

import { generatePath, useParams } from "react-router";
import { VendorRequestProps } from "./vendor-request-page.props";
import { HomeIcon } from "../../../static/images/svgs/home";
import { COLORS } from "../../../libs/colors";
import { ArrowRight } from "../../../static/images/svgs/arrow-right";
import { getVendorRequestDetails } from "./vendors-request-page.utils";
import {
  getVendorsRequests,
  vendorsRequestAction,
} from "../../../store/slices/vendors";
import VendorDetails from "../../../components/vendor-details/vendor-details";

const VendorRequestPage = (props: VendorRequestProps) => {
  const params = useParams<{ id: string }>();

  useEffect(() => {
    props.onInit();
  }, []);

  var requestDetails = getVendorRequestDetails(
    props.vendorsRequests,
    params.id
  );

  return (
    <div className="flex flex-col space-y-1 w-full h-full py-2">
      {!!requestDetails && (
        <>
          <div className="flex flex-row p-3 space-x-2 items-center">
            <div
              onClick={() => props.onHomeIconClick()}
              className="cursor-pointer">
              <HomeIcon />
            </div>
            <ArrowRight />
            <div className="text-[#667085]">
              {translate("Vendors_Request_Text", "Vendor Request")}
            </div>
            <ArrowRight />
            <div className={`${COLORS.TEXT_PRIMARY}`}>
              {requestDetails.vendorName}
            </div>
          </div>
          <div className="flex flex-row p-2">
            <div className="text-4xl font-semibold">
              {requestDetails.vendorName}
            </div>
            <div className="flex flex-1 justify-end space-x-2">
              <Button
                className={`${COLORS.TEXT_FORM} rounded-lg border-2`}
                disabled={requestDetails.status != null}
                onClick={() =>
                  requestDetails &&
                  props.onVendorsRequestAction(requestDetails.id, false)
                }>
                {translate("Reject_Text", "Reject")}
              </Button>
              <Button
                className={`${COLORS.TEXT_WHITE} rounded-lg`}
                disabled={requestDetails.status != null}
                primary
                onClick={() =>
                  requestDetails &&
                  props.onVendorsRequestAction(requestDetails.id, true)
                }>
                {translate("Approve_Text", "Approve")}
              </Button>
            </div>
          </div>
          <div className="flex flex-col w-full space-y-3 justify-center items-center">
            <VendorDetails
              vendorName={requestDetails.vendorName}
              vendorWebsite={requestDetails.vendorWebsite}
              vendorPhoneNumber={requestDetails.vendorPhoneNumber}
              vendorEmail={requestDetails.vendorEmail}
              taxRegistrationFileld={requestDetails.taxRegistrationFileld}
              vendorMobileNumber={requestDetails.vendorMobileNumber}
              vendorBranches={requestDetails.vendorBranches}
              vendorManager={requestDetails.requestedUser}
            />
          </div>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  vendorsRequests: state.vendors.vendorsRequests,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onInit: () => {
    dispatch(getVendorsRequests());
  },
  onVendorsRequestAction: (requestId: string, approve: boolean) => {
    dispatch(vendorsRequestAction(requestId, approve));
  },
  onHomeIconClick: () => {
    dispatch(push(generatePath(SYSTEM_ADMIN.VENDORS)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorRequestPage);
