import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { configureOfferzStore } from "./store";


const history = createBrowserHistory();
export const {store, persistor} = configureOfferzStore(history);
const initApp = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ReduxRouter history={history}>
          <App />
        </ReduxRouter>
      </PersistGate>
    </Provider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    {initApp()}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
