import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ErrorsCodes, UserCulture } from "../../libs/enums";
import { SignupModel } from "../../libs/models/signup";
import { validateSignupState } from "../../pages/Signup/signup.policy";
import { signupService } from "../../services/offerz-service";
import { AsyncAppThunk } from "../application-state";
import { setAccountVerificationDialog } from "./account-verification";
import { showLoaderWhile } from "./ui";

export interface SignUpStore {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  mobileNumber: string;
  ErrorCode?: ErrorsCodes;
  isValidating: boolean;
  userCulture: UserCulture;
}

const initialState: SignUpStore = {
  isValidating: false,
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  mobileNumber: "",
  userCulture: UserCulture.English,
};

const slice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setFirstName: (state: SignUpStore, action: PayloadAction<string>) => {
      state.firstName = action.payload;
      state.ErrorCode && (state.ErrorCode = undefined);
    },
    setLastName: (state: SignUpStore, action: PayloadAction<string>) => {
      state.lastName = action.payload;
      state.ErrorCode && (state.ErrorCode = undefined);
    },
    setEmail: (state: SignUpStore, action: PayloadAction<string>) => {
      state.email = action.payload;
      state.ErrorCode && (state.ErrorCode = undefined);
    },
    setPassword: (state: SignUpStore, action: PayloadAction<string>) => {
      state.password = action.payload;
      state.ErrorCode && (state.ErrorCode = undefined);
    },
    setMobileNumber: (state: SignUpStore, action: PayloadAction<string>) => {
      state.mobileNumber = action.payload;
      state.ErrorCode && (state.ErrorCode = undefined);
    },
    setErrorCode: (
      state: SignUpStore,
      action: PayloadAction<ErrorsCodes | undefined>
    ) => {
      state.ErrorCode = action.payload;
    },
    setIsValidating: (state: SignUpStore, action: PayloadAction<boolean>) => {
      state.isValidating = action.payload;
    },
  },
});

export const {
  setFirstName,
  setLastName,
  setEmail,
  setMobileNumber,
  setPassword,
  setErrorCode,
  setIsValidating,
} = slice.actions;
export const reducer = slice.reducer;

export const signup = (): AsyncAppThunk => async (dispatch, store) => {
  dispatch(setIsValidating(true));
  var signupState = store().signup;
  var validationState = validateSignupState(signupState);
  if (Object.keys(validationState).length < 1) {
    const signupModel: SignupModel = {
      firstName: signupState.firstName,
      lastname: signupState.lastName,
      mobileNumber: signupState.mobileNumber,
      userName: signupState.email,
      password: signupState.password,
    };
    dispatch(
      showLoaderWhile(async () => {
        const signupResponse = await signupService(signupModel);

        if (signupResponse && signupResponse.result != null) {
          dispatch(setIsValidating(false));
          dispatch(setAccountVerificationDialog(true));
          dispatch(setEmail(signupModel.userName));
          dispatch(setPassword(signupModel.password));
        } else {
          signupResponse &&
            signupResponse.errorCode &&
            dispatch(setErrorCode(signupResponse.errorCode as ErrorsCodes));
        }
      })
    );
  }
};
