import React, { useState } from "react";

const ImageWithFallback = (props: {
  src: string;
  alt: string;
  className: string;
  fallbackSrc: string;
}) => {
  const [imgSrc, setImgSrc] = useState(props.src);

  const handleError = () => {
    setImgSrc(props.fallbackSrc);
  };

  return (
    <img
      src={imgSrc}
      alt={props.alt}
      className={props.className}
      onError={handleError}
    />
  );
};

export default ImageWithFallback;
