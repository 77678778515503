import { connect } from "react-redux";
import { AppDispatch, ApplicationState } from "../../../../../store";
import { UnconfirmedTermsListProps } from "./unconfirmed-terms-list.props";
import { useEffect } from "react";
import { fetchTermsConditionsUnconfirmedList } from "../../../../../store/slices/terms-conditions";
import translate from "../../../../../utils/translations";
import { TermsConditionsType } from "../../../../../libs/models/system-admin";

const UnconfirmedTermsList = (props: UnconfirmedTermsListProps) => {
  useEffect(() => {
    props.onFetchUnconfirmedList();
  }, [props.type]);

  return  <div className="flex flex-col w-full shadow-sm bg-white rounded-lg relative border-2  p-2">
    <div className="mb-2 font-semibold">
    {props.type == TermsConditionsType.Consumer ? 'Consumers' : 'Vendors'} {translate('Didnt_Agree', 'didn\'t agree')}
    </div>
  {props.list.map((item) => (
    <div className="flex-grow flex-1">
    <div className="flex  justify-between">
      <div className="flex  text-primary-600 font-semibold">
      {item.name}
      </div>
      <div className="flex text-secondary">
      {`${translate('Version_Text','Version')} ${item.version}`}
      </div>
      </div>
      <div>
      {item.email}
      </div>  
     
      </div>
  ))}
  </div>
};

const mapStateToProps = (state: ApplicationState) => ({
  type: state.termsconditions.type,
  list: state.termsconditions.UnconfirmedList,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onFetchUnconfirmedList: () => {
    dispatch(fetchTermsConditionsUnconfirmedList());
  }
});

const UnconfirmedTermsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconfirmedTermsList);

export { UnconfirmedTermsListContainer };
