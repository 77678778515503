import { AppDispatch, ApplicationState } from "../../store";
import { connect } from "react-redux";
import { Button, TextInput } from "@ofrz/ui-package";
import { login, setEmail, setPassword } from "../../store/slices/login";
import ErrorText from "../../components/error-text";
import translate from "../../utils/translations";
import { validateState } from "./login-form-container.policy";
import { LoginFormContainerProps } from "./signin.props";
import { COLORS } from "../../libs/colors";
import { PopUpContainer } from "../../components/pop-up";
import { push } from "@lagunovsky/redux-react-router";
import { ROUTES } from "../../libs";

const LoginForm = (props: LoginFormContainerProps) => {
  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeEmail(event.target.value.trim());
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangePassword(event.target.value.trim());
  };

  const onLoginClicked = () => {
    props.onLogin();
  };

  return (
    <div className="flex-col">
      <PopUpContainer email={props.email} />
      <p className="font-medium text-lg text-[#667085] my-2">
        {translate(
          "Login_Description_Text",
          "Welcome back! Please enter your details."
        )}
      </p>
      <div className="flex flex-col py-2">
        <div className="mt-2">
          <label className={`${COLORS.TEXT_FORM} font-semibold`}>
            {translate("Login_Email_Text", "Email")}
          </label>
          <TextInput
            className="w-full"
            placeholder="store@offerz.com"
            type="text"
            autoComplete="user-name"
            disableSpace
            value={props.email}
            onInput={onEmailChange}
            onChange={onEmailChange}
          />
          <ErrorText text={props.validationState.userName} />
        </div>
        <div className="mt-2 ">
          <label className={`${COLORS.TEXT_FORM} font-semibold`}>
            {translate("Login_Password_Text", "Password")}
          </label>
          <TextInput
            className="w-full"
            placeholder="Type your password"
            type="password"
            autoComplete="password"
            disableSpace
            value={props.password}
            onInput={onPasswordChange}
            onChange={onPasswordChange}
          />
          <ErrorText text={props.validationState.password} />
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => props.goTo(ROUTES.FORGET_PASSWORD)}
            className={`text-base ${COLORS.TEXT_PRIMARY} font-semibold my-3`}>
            {translate("Password_Text", "Forget Password?")}
          </button>
        </div>
        <div>
          <Button
            onClick={onLoginClicked}
            className={`rounded-lg w-full ${COLORS.TEXT_WHITE}`}
            color="white"
            size="md"
            primary>
            {translate("Login_Text", "Login")}
          </Button>
        </div>
        <div className="flex justify-center mt-4">
          <p
            onClick={() => props.goTo(ROUTES.SIGN_UP_VENDOR)}
            className="cursor-pointer hover:underline hover:text-primary-500 text-primary-500 text-sm mt-1 hover">
            {translate("Not_User_Signup", "Not a user signup")}
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  userCulture: state.login.userCulture,
  email: state.login.email,
  password: state.login.password,
  validationState: validateState(state.login),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onChangeEmail: (email: string) => {
    dispatch(setEmail(email));
  },
  onChangePassword: (password: string) => {
    dispatch(setPassword(password));
  },
  onLogin: () => {
    dispatch(login());
  },
  goTo: (route: string) => {
    dispatch(push(route));
  },
});

const LoginFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);

export default LoginFormContainer;
