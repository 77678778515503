import { ChangePasswordModel } from "../../libs/models/change-password";
import { ValidationModel } from "../../libs/models/validate-model";
import translate from "../../utils/translations";

const validateChangePassword = (
  changePassword: ChangePasswordModel
): ValidationModel<ChangePasswordModel> => {
  let validationModel = {} as ValidationModel<ChangePasswordModel>;
  if (changePassword.oldPassword == "") {
    validationModel.oldPassword = translate(
      "Required_Text",
      "This field is required"
    );
  }
  if (changePassword.newPassword == "") {
    validationModel.newPassword = translate(
      "Required_Text",
      "This field is required"
    );
  } else if (changePassword.newPassword.length < 8) {
    validationModel.newPassword = translate(
      "Login_Validation_Password_Error_Text",
      "The password can not be less than 8 characters"
    );
  }
  if (changePassword.confirmPassword != changePassword.newPassword) {
    validationModel.newPassword = translate(
      "Login_Confirm_Password_Text",
      "Please confirm your password"
    );
  }
  return validationModel;
};
export { validateChangePassword };
