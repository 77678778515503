import { ErrorsCodes } from "../../libs/enums";
import { LoginModel } from "../../libs/models/login";
import { ValidationModel } from "../../libs/models/validate-model";
import { LoginStore } from "../../store/slices/login";
import { getErrorDescription } from "../../utils/errors";
import translate from "../../utils/translations";

export const validateState = (state: LoginStore): ValidationModel<LoginModel> => {
  let validationModel = {} as ValidationModel<LoginModel>;

  if (!state.isValidating) {
    return validationModel;
  }

  if (state.ErrorCode && state.ErrorCode == ErrorsCodes.InvalidEmail) {
    validationModel.userName = getErrorDescription(state.ErrorCode);
  }

  if (state.ErrorCode && state.ErrorCode == ErrorsCodes.InvalidPassword) {
    validationModel.password = getErrorDescription(state.ErrorCode);
  }

  var emailRegex = new RegExp("^([\\w\\.\\-]+)@([\\w\\-]+)((\\.(\\w){2,3})+)$");
  if (!emailRegex.test(state.email)) {
    validationModel.userName = translate(
      "Login_Validation_Email_Error_Text",
      "The entered email not a valid email"
    );
  }

  if (state.password.length < 8) {
    validationModel.password = translate(
      "Login_Validation_Password_Error_Text",
      "The password can not be less than 8 characters"
    );
  }

  return validationModel;
};
