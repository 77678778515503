import { SignUpContainer } from "../pages/Signup";
import { VendorDetailsContainer } from "../pages/VendorDetails";
import translate from "../utils/translations";
import { ISteps } from "./models/signup";

const STEPPER_FORMS: { SIGNUP: ISteps[] } = {
  SIGNUP: [
    {
      content: <SignUpContainer />,
      headline: translate(
        "Vendor_Registration_Step_1_Headline",
        "Personal Info"
      ),
      description: translate(
        "Vendor_Registration_Step_1_description",
        "A few details about you"
      ),
    },
    {
      content: <VendorDetailsContainer />,
      headline: translate(
        "Vendor_Registration_Step_2_Headline",
        "Vendor details"
      ),
      description: translate(
        "Vendor_Registration_Step_2_description",
        "A few details about your vendor"
      ),
    },
  ],
  //SIGNUP:[VendorDetailsContainer,SignUpContainer]
};
export { STEPPER_FORMS };
