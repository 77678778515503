import { ValidationModel } from "../../libs/models/validate-model";
import { VendorRegisterRequestModel } from "../../libs/models/vendor-register-request";
import { REGEX_PATTERN } from "../../libs/regex-patterns";
import { VendorDetailsStore } from "../../store/slices/vendor-details";
import translate from "../../utils/translations";

export const validateVendorRegisterRequestState = (state: VendorDetailsStore): ValidationModel<VendorRegisterRequestModel> => {
  let validationModel = {} as ValidationModel<VendorRegisterRequestModel>;

  if (!state.isValidating) {
    return validationModel;
  }

  // if (state.ErrorCode && state.ErrorCode == ErrorsCodes.InvalidEmail) {
  //   validationModel.userName = getErrorDescription(state.ErrorCode);
  // }

  // if (state.ErrorCode && state.ErrorCode == ErrorsCodes.InvalidPassword) {
  //   validationModel.password = getErrorDescription(state.ErrorCode);
  // }

  if (!REGEX_PATTERN.ALPHAPET_ONLY.test(state.vendorName)) {
    validationModel.vendorName = translate(
      "Alphapet_Only_Error_Text",
      "required alphapet only"
    );
  }
  if (!REGEX_PATTERN.NUMBERS_ONLY.test(state.vendorMobileNumber)) {
    validationModel.vendorMobileNumber = translate(
      "Numbers_Only_Error_Text",
      "required numbers only"
    );
  }
  if (!REGEX_PATTERN.WEBSITE.test(state.vendorWebsite)) {
    validationModel.vendorWebsite = translate(
      "Website_Format_Error_Text",
      "website format is not correct"
    );
  }
  if (state.vendorTermsConditionId==null) {
    validationModel.vendorTermsConditionId = translate(
      "Must_Accept_Terms_Condition_Text",
      "You must accept our terms"
    );
  }

  if (!state.vendorTaxRegistrationFile) {
    validationModel.vendorTaxRegistrationFile = translate(
      "Vendor_Registration_Tax_File_Error_Text",
      "You must Upload tax file"
    );
  }




  return validationModel;
};
