import React from "react";
import { VendorDetailsProps } from "./vendor-details.props";
import DetailsCard from "../details-card/details-card";
import translate from "../../utils/translations";
import { getFileUrl } from "utils/mediaUrlFormater";

const VendorDetails = (props: VendorDetailsProps) => {
  const downloadTaxFile = () => {
    const link = document.createElement("a");
    link.href = getFileUrl( props.taxRegistrationFileld);
    link.target = "_blank";
    link.download = "downloaded-file";
    link.click();
  };
  return (
    <>
      <DetailsCard
        title={translate("Vendor_Info_Text", "Vendor Info")}
        details={[
          { key: "Name", value: props.vendorName },
          { key: "Number", value: props.vendorPhoneNumber },
          {
            key: translate(
              "Vendor_Tax_Registration_File_Text",
              "Tax registration file"
            ),
            value: (
              <div
                onClick={() => downloadTaxFile()}
                className="text-[#2D5289] underline font-semibold">
                Tax File
              </div>
            ),
          },
          { key: "Website", value: props.vendorWebsite },
          {
            key: "Email",
            value: props.vendorManager.userName,
          },
        ]}
      />
      <DetailsCard
        title={translate(
          "Vendor_Account_Manager_Text",
          "Vendor`s account manager"
        )}
        details={[
          {
            key: "Name",
            value:
              props.vendorManager.firstName +
              " " +
              props.vendorManager.lastName,
          },
          { key: "Number", value: props.vendorMobileNumber },
          { key: "Role", value: "Account Manager" },
          {
            key: "Email",
            value: props.vendorManager.userName,
          },
        ]}
      />
      {props.vendorBranches.length > 0 && (
        <DetailsCard
          title={translate("Vendor_Branches_Manager_Text", "Vendor`s Branches")}
          body={
            <>
              {props.vendorBranches.map((branch, index) => (
                <div
                  key={index}
                  className="bg-[#518c7d1a]  rounded-[16px] inline-block px-3 ">
                  <div className="flex align-middle items-center">
                    <div className=" text-[#518c7d] ">{branch.area}</div>
                  </div>
                </div>
              ))}
            </>
          }
        />
      )}
    </>
  );
};

export default VendorDetails;
