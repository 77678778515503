import { push } from '@lagunovsky/redux-react-router';
import { connect } from "react-redux";
import { AppDispatch, ApplicationState } from "../../store";
import { Menu } from "@ofrz/ui-package";
import { useLocation } from "react-router";
import { SYSTEM_ADMIN } from "../../libs/routes";
import { MenuItem } from "@ofrz/ui-package/dist/components/MenuItem/types";
import translate from "../../utils/translations";
import { SideNavigationBarProps } from "./side-navigation-bar.props";
import { DashBoardIcon } from "../../static/images/svgs";
import { VendorIcon } from "../../static/images/svgs/nav-bar/vendor-icon";
import { TermsConditionsIcon } from "../../static/images/svgs/nav-bar/terms-conditions-icon";

const SideNavigationBar = (props: SideNavigationBarProps) => {
  const location = useLocation();

  const isActiveButton = (buttonRoute: string) => {
    return location.pathname == buttonRoute;
  };

  const onButtonClicked = (buttonRouteClicked: string) => {
    props.goTo(buttonRouteClicked);
  };

  var SystemAdminItems: MenuItem[] = [
    // {
    //   id: SYSTEM_ADMIN.DASHBOARD,
    //   title: (
    //     <>
    //       <DashBoardIcon active={isActiveButton(SYSTEM_ADMIN.DASHBOARD)} />
    //       &nbsp;
    //       {`${translate("dashboard", "Dashboard")}`}
    //     </>
    //   ),
    //   isActive: isActiveButton(SYSTEM_ADMIN.DASHBOARD),
    // },
    {
      id: SYSTEM_ADMIN.VENDORS,
      title: (
        <>
          <VendorIcon active={isActiveButton(SYSTEM_ADMIN.VENDORS)} /> &nbsp;
          {`${translate("Vendors_Text", "Vendors")}`}
        </>
      ),
      isActive: isActiveButton(SYSTEM_ADMIN.VENDORS),
    },
    {
      id: SYSTEM_ADMIN.CATEGORIES,
      title: (
        <>
          <VendorIcon active={isActiveButton(SYSTEM_ADMIN.CATEGORIES)} /> &nbsp;
          {`${translate("Categories", "Categories")}`}
        </>
      ),
      isActive: isActiveButton(SYSTEM_ADMIN.CATEGORIES),
    },
    {
      id: SYSTEM_ADMIN.TERMSANDCONITIONS,
      title: (
        <>
          <TermsConditionsIcon
            active={isActiveButton(SYSTEM_ADMIN.TERMSANDCONITIONS)}
          />
          &nbsp;
          {`${translate("Terms_And_Conditions_Text", "Terms and Conditions")}`}
        </>
      ),
      isActive: isActiveButton(SYSTEM_ADMIN.TERMSANDCONITIONS),
    },
    {
      id: SYSTEM_ADMIN.PRODUCTS,
      title: (
        <>
          <TermsConditionsIcon
            active={isActiveButton(SYSTEM_ADMIN.PRODUCTS)}
          />
          &nbsp;
          {`${translate("Products_Text", "Products")}`}
        </>
      ),
      isActive: isActiveButton(SYSTEM_ADMIN.PRODUCTS),
    },
  ];

  return (
    <div className={`flex w-full h-full flex-col p-4`}>
      <Menu
        items={SystemAdminItems}
        isExpandable={false}
        onClick={onButtonClicked}
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  goTo: (route: string) => {
    console.log(route);
    dispatch(push(route));
  },
});

export const SideNavigationBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SideNavigationBar);
