import {
  AppDispatch,
  ApplicationState,
} from "../../../../store/application-state";
import {
  addVendorProduct,
  clearVendorProductItem,
  setSelectedCategoryId,
  setVendorProductItemProp,
} from "../../../../store/slices/vendor-product";
import { connect } from "react-redux";
import { AddVendorProductPopupProps } from "./add-vendor-product-popup.props";
import { Button, TextInput } from "@ofrz/ui-package";
import translate from "../../../../utils/translations";
import { setCloseDialog } from "../../../../store/slices/modal-popup";
import { AutoCompleteOption } from "../../../../components/auto-complete/auto-complete.props";
import { autoProductTypingComplete, setClearProductItem, setProductItem, setProductItemProp } from "../../../../store/slices/product";
import { AutoCompleteComponent } from "../../../../components/auto-complete";
import { SelectOptionComponent } from "../../../../components/select-option";
import { settingLookupList } from "../../vendor-product.util";
import { setSubCategoryLookUp } from "../../../../store/slices/categories";
import { useEffect } from "react";
import { ProductModel } from "../../../../libs/models/product";
import { getCategoryIdBySubCategory } from "../../../AdminProduct/admin-product.util";
import { validateAddVendorProductItemState } from "./add-vendor-product-popup.policy";

const AddVendorProductPopUp = (props: AddVendorProductPopupProps) => {

  const onPropChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    props.onVendorProductPropChange(name, value);
  };
  const addCategory = () => {
    props.addVendorProduct();
  };
  const onCloseDialog = () => {
    props.setCloseDialog();
  };
  const renderAutoCompleteOptions = () => {
    const options = props.productItemLookUp.map((obj) => {
      return {
        value: obj.id!,
        label: obj.name,
        imgUrl: obj.pictureFileId ?? ""
      };
    });
    return options;
  };
  const onProductValueChange = (searchText: string) => {
    props.autoProductTypingComplete(searchText);
    props.onProductPropChange("name", searchText);
  }
  const onSelectProduct = (option: AutoCompleteOption, propKey?: string) => {
    const product = props.productItemLookUp.find((obj) => obj.id === option.value)
    if (product) {
      props.setProductItem(product);
      const categoryId = getCategoryIdBySubCategory(
        product.subCategoryId!,
        props.parentCategoryLoopkUp
      )
      props.changeCategoryId(categoryId!)
      props.onVendorProductPropChange(propKey ?? "", product.id ?? "")

    }
  }
  const onCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    props.changeCategoryId(value);
    props.onProductPropChange("subCategoryId", "");

  };
  const onSubCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    props.onProductPropChange(name, value);
  };
  return (
    <div className="flex flex-col">
      <div className="mt-2">
        <AutoCompleteComponent
          id="test"
          title="Product Name"
          placeholder="Product Name"
          optionsList={renderAutoCompleteOptions()}
          onChange={onProductValueChange}
          onSelect={onSelectProduct}
          name="productId"
          error={props.validationState.productName}
        />
      </div>
      <div className="mt-2">
        <SelectOptionComponent
          disabled={false}
          id="categoryId"
          title={translate("Category_Text", "Category")}
          className="w-full"
          placeholder="Select Category"
          name="categoryId"
          value={props.selectedCategoryId ?? undefined}
          onChange={onCategoryChange}
          error={props.validationState.productCategory}
          optionsList={settingLookupList(props.parentCategoryLoopkUp)}
        />
      </div>
      <div className="mt-2">
        <SelectOptionComponent
          disabled={props.subCategoryLookUp.length === 0}
          id="subCategoryId"
          title={translate("Sub_Category_Text", "Sub Category")}
          className="w-full"
          placeholder="Select Sub Category"
          name="subCategoryId"
          value={props.productItem.subCategoryId ?? ""}
          onChange={onSubCategoryChange}
          error={props.validationState.productSubCategory}
          optionsList={settingLookupList(props.subCategoryLookUp)}
        />
      </div>
      <div className="mt-2">
        <SelectOptionComponent
          id="brandId"
          title={translate("Brand_Text", "Brand")}
          className="w-full"
          placeholder="Select Brand"
          name="brandId"
          value={props.productItem.brandId ?? ""}
          onChange={onSubCategoryChange}
          error={props.validationState.productBrand}
          optionsList={settingLookupList(props.brandLookUp)}
        />
      </div>
      <div className="mt-2 flex items-end gap-2 w-full">
        <div className="flex-1">
          <TextInput
            title={translate("Product_Price_Text", "Product Price")}
            className="w-full"
            placeholder="Product Price"
            type="text"
            name="price"
            value={props.vendorProductItem.price ?? ""}
            onChange={onPropChange}
            error={props.validationState.price}
          />
        </div>
        <div className="flex border-none border-2 rounded-lg h-12 relative bg-primary-100 items-center">
          <span className="p-2 text-center text-primary-700 font-semibold">EGP</span>
        </div>
      </div>
      <div className="flex justify-end self-end gap-3 items-end mt-10">
        <Button
          className="text-[#F04438] rounded-lg !bg-white !w-[115px] !h-[45px]  !text-[14px] !font-medium border-[1px] border-[#F04438]"
          onClick={onCloseDialog}
        >
          {translate("Cancel", "Cancel")}
        </Button>
        <Button
          className="text-white rounded-lg !bg-[#518c7d] w-[115px] h-[45px] !text-[14px] font-medium"
          onClick={addCategory}
        >
          {translate("Add", "Add")}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  productItem: state.product.productItem,
  vendorProductItem: state.vendorproduct.vendorProductItem,
  selectedCategoryId: state.vendorproduct.selectedCategoryId,
  productItemLookUp: state.product.productListLookUp,
  parentCategoryLoopkUp: state.categories.parentCategoryLoopkUp,
  subCategoryLookUp: state.categories.subCategoryLoopkUp,
  brandLookUp: state.brand.brandLoopkUp,
  validationState: validateAddVendorProductItemState(state.vendorproduct, state.product),
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onVendorProductPropChange: (name: string, value: string) => {
    dispatch(setVendorProductItemProp({ name: name, value: value }));
  },
  onProductPropChange: (name: string, value: string) => {
    dispatch(setProductItemProp({ name: name, value: value }));
  },
  addVendorProduct: () => {
    dispatch(addVendorProduct());
  },
  autoProductTypingComplete: (searchText: string) => {
    dispatch(setClearProductItem());
    dispatch(clearVendorProductItem());
    dispatch(autoProductTypingComplete(searchText));
  },
  setProductItem(productItem: ProductModel) {
    dispatch(setProductItem(productItem));
  },
  changeCategoryId: (categoryId: string) => {
    dispatch(setSelectedCategoryId(categoryId!));
    dispatch(setSubCategoryLookUp(categoryId));
  },
  setCloseDialog: () => {
    dispatch(clearVendorProductItem());
    dispatch(setClearProductItem());
    dispatch(setCloseDialog());
  },
  clearModalData: () => {
    dispatch(clearVendorProductItem());
    dispatch(setClearProductItem());
  },
  setSelectedCategoryId: (categoryId: string) => {
    dispatch(setSelectedCategoryId(categoryId));
  }
});

const AddVendorProductPopUpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddVendorProductPopUp);

export { AddVendorProductPopUpContainer };
