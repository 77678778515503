import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VendorProductModel } from "libs/models/vendor-product";
import { validateEditDealItemState } from "pages/Deals/containers/UpdateCategoryContainer/edit-deal-popup.policy";
import { ErrorsCodes } from "../../libs/enums";
import { AppliedOnType, DealsModel, DealType } from "../../libs/models/deals";
import { Filter } from "../../libs/models/filter";
import { PaginatedList } from "../../libs/models/paginated-list";
import {
  addOrUpdateDealService,
  getDealsList,
  uploadFile,
  vendorProductAutoCompleteService
} from "../../services/offerz-service";
import { getFilterQuery } from "../../utils/filter";
import { AsyncAppThunk } from "../application-state";
import { setErrorCode } from "./login";
import { setCloseDialog } from "./modal-popup";
import { showLoaderWhile } from "./ui";
import { ProductModel } from "libs/models/product";

export interface SelectedForDeal {
  id: string;
  name: string;
}
export interface DealsStore {
  isValidating: boolean;
  dealsList: PaginatedList<DealsModel>;
  vendorProductLookUp: VendorProductModel[];
  dealItem: DealsModel;
  file?: File;
  filter: Filter;
  selectedForDealList: SelectedForDeal[]
}
const initialDealItem: DealsModel = {
  amount: 0,
  appliedOn: [],
  appliedOnType: AppliedOnType.Product,
  dealType: DealType.Percentage,
  description: '',
  descriptionEn: '',
  maximumDiscount: 0,
  title: '',
  titleEn: '',
  name: '',
  id: ''
}

const initialState: DealsStore = {
  isValidating: false,
  filter: {
    search: { key: "name", value: "" },
    dateProp: { from: null, to: null },
    orderBy: { key: "Name", sort: "asc" },
  },
  dealsList: {
    list: [],
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
  },
  vendorProductLookUp: [],
  selectedForDealList: [],
  dealItem: initialDealItem
};

const slice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    setIsValidating: (state: DealsStore, action: PayloadAction<boolean>) => {
      state.isValidating = action.payload;
    },
    setDealsList: (
      state: DealsStore,
      action: PayloadAction<PaginatedList<DealsModel>>
    ) => {
      state.dealsList = action.payload;
    },
    setPage: (state: DealsStore, action: PayloadAction<number>) => {
      state.dealsList.pageIndex = action.payload;
    },
    setFile: (state: DealsStore, action: PayloadAction<File | undefined>) => {
      state.file = action.payload;
    },
    setDealItemProp: (
      state: DealsStore,
      action: PayloadAction<{ name: string; value: string | number }>
    ) => {

      const { name, value } = action.payload;
      if (name != 'appliedOn') {
        state.dealItem = { ...state.dealItem, [name]: name == 'amount' || name == 'maximumDiscount' ? Number.parseFloat(value as string) : value };
      } else {
        state.dealItem.appliedOn.push(value.toString());
      }
    },
    setDealItem: (state: DealsStore, action: PayloadAction<DealsModel>) => {
      state.dealItem = action.payload;
    },
    addToSelectedForDealList: (state: DealsStore, action: PayloadAction<SelectedForDeal>) => {
      state.selectedForDealList.push(action.payload);
    },
    setDealFileId: (
      state: DealsStore,
      action: PayloadAction<string>
    ) => {
      state.dealItem && (state.dealItem.pictureFileId = action.payload);
    },
    setSearch: (state: DealsStore, action: PayloadAction<string>) => {
      state.filter.search!.value = action.payload;
    },
    setVendorProductListLookUp: (state: DealsStore,
      action: PayloadAction<VendorProductModel[]>
    ) => {
      state.vendorProductLookUp = action.payload;
    },
    resetDealsList: (state: DealsStore) => {
      state.dealsList = initialState.dealsList;
    },
    setClearDealItem: (state: DealsStore) => {
      state.file = undefined;
      state.isValidating = false;
      state.dealItem = initialDealItem;
      state.vendorProductLookUp = initialState.vendorProductLookUp;
      state.selectedForDealList = initialState.selectedForDealList;
    },
  },
});

export const {
  setIsValidating,
  setPage,
  setSearch,
  setDealItemProp,
  setDealsList,
  setFile,
  setClearDealItem,
  setDealItem,
  resetDealsList,
  setDealFileId,
  setVendorProductListLookUp,
  addToSelectedForDealList
} = slice.actions;
export const reducer = slice.reducer;

export const fetchDealsList = (): AsyncAppThunk => async (dispatch, store) => {
  dispatch(
    showLoaderWhile(async () => {
      const dealsListResponse = await getDealsList(
        getFilterQuery(
          store().deals.filter,
          store().deals.dealsList.pageIndex * store().deals.dealsList.pageSize,
          store().deals.dealsList.pageSize
        )
      );
      if (dealsListResponse && dealsListResponse?.result !== null) {
        dispatch(setDealsList(dealsListResponse!.result));
      }
    })
  );
};

// export const deleteCategory =
//     (id: string): AsyncAppThunk =>
//         async (dispatch, store) => {
//             dispatch(
//                 showLoaderWhile(async () => {
//                     const response = await deleteCategoryService(id);
//                     if (response && response?.result !== null) {
//                         dispatch(setCloseDialog());
//                         dispatch(getCategoriesList());
//                     }
//                 })
//             );
//         };

export const autoVendorProductTypingComplete =
  (searchText: string): AsyncAppThunk =>
    async (dispatch) => {
      if (searchText === "") { dispatch(setVendorProductListLookUp([])) }
      else {
        const queryFilter = `?$filter=contains(ProductName, '${searchText}')`
        const response = await vendorProductAutoCompleteService(queryFilter);
        if (response && response.result !== null) {
          dispatch(setVendorProductListLookUp(response.result.list));
        } else {
          dispatch(setVendorProductListLookUp([]));
        }
      }
    };
export const addOrUpdateDeal =
  (): AsyncAppThunk => async (dispatch, store) => {
    const dealModelItem = store().deals.dealItem!;
    dispatch(setIsValidating(true));
    if (Object.keys(validateEditDealItemState(store().deals)).length == 0) {
      dispatch(
        showLoaderWhile(async () => {
          const response = await addOrUpdateDealService(dealModelItem);
          if (response && response?.result !== null) {
            dispatch(setCloseDialog());
            dispatch(setClearDealItem());
            dispatch(fetchDealsList());
          }
        })
      );
    }
  };

export const UploadDealFile =
  (file: File): AsyncAppThunk =>
    async (dispatch, store) => {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(setFile(file));
      dispatch(
        showLoaderWhile(async () => {
          const response = await uploadFile(formData);

          if (response && response.result != null) {
            dispatch(setDealFileId(response.result));
          } else {
            dispatch(
              setErrorCode(response?.errorCode as ErrorsCodes)
            );
          }
        })
      );
    };
