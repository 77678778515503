import { push } from "@lagunovsky/redux-react-router";
import { Button, TextInput } from "@ofrz/ui-package";
import { useEffect } from "react";
import { connect } from "react-redux";
import { COLORS } from "../../libs/colors";
import { MagazineModel } from "../../libs/models/magazine";
import { NoMagazineImage } from "../../static/images/svgs/no-magazine-img-svg";
import { AppDispatch, ApplicationState } from "../../store/application-state";
import {
  getVendorMagazines,
  resetMagzinList,
  setFromDate,
  setPage,
  setSearch,
  setToDate,
} from "../../store/slices/magazine";
import {
  ModalPopupStore,
  setCloseDialog,
  setDialogStateChange,
} from "../../store/slices/modal-popup";
import translate from "../../utils/translations";
import { MagazineCardItemContainer } from "./components/magazine-card-item";
import AddMagazinePopUpContainer from "./containers/AddMagazine/add-magazine-popup.container";
import { MagazineProps } from "./magazine.props";
import { getUTCDate } from "../../utils/date";
import { CloseIcon } from "static/images/svgs/close-icon-svg";

const Magazine = (props: MagazineProps) => {
  useEffect(() => {
    props.getVendorMagazines();
  }, []);

  const onAddMagazineItem = () => {
    props.onAddMagazineClick({
      isOpen: true,
      title: <p>Upload New Magazine</p>,
      content: <AddMagazinePopUpContainer />,
      actionButtons: [],
      onClose: () => props.setCloseDialog(),
    });
  };

  const RenderingMagazineList = () => {
    return props.magazinesList.list.length > 0 ? (
      <div className="flex  gap-6 w-full flex-wrap">
        {props.magazinesList.list.map((magazineItem: MagazineModel) => {
          return <MagazineCardItemContainer magazineItem={magazineItem} />;
        })}
      </div>
    ) : (
      <div className="flex items-center justify-center mt-[53px]">
        <div className="text-center">
          <NoMagazineImage />
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between py-[25px]">
        <div className="text-[30px] font-medium">
          {translate("Magazines", "Magazines")}
        </div>
        <Button
          className="text-white rounded-lg !bg-[#518c7d]"
          onClick={onAddMagazineItem}
        >
          {translate("Add_New_Magazin_Text", "Add New Magazine")}
        </Button>
      </div>
      <div className="flex items-center mb-8">
        <div className="flex-col w-[250px] px-1">
          <TextInput
            className="w-full border p-2"
            placeholder="Magazine Name"
            type="text"
            name="magazineName"
            value={props.filter}
            onChange={(e) => props.onSearchChange(e.target.value)}
            suffixContent={
              props.filter !== null &&
              props.filter !== "" && (
                <button
                  className="bg-[#518c7d1a] hover:bg-[#518c7d4d] p-1 rounded-[100%]"
                  onClick={(e) => {
                    props.onSearchChange("");
                  }}
                >
                  <CloseIcon />
                </button>
              )
            }
          />
        </div>
        <div className="flex-col w-[250px] px-1">
          <input
            type="date"
            id="from"
            name="from"
            className="w-full align-middle p-2 border-2 rounded-lg border-[#D0D5DD]"
            onChange={(event) =>
              props.onFromDateChange(getUTCDate(event.target.value))
            }
          />
        </div>
        <div className="flex-col w-[250px] px-1">
          <input
            type="date"
            id="to"
            name="to"
            className="w-full align-middle p-2 border-2 rounded-lg border-[#D0D5DD]"
            onChange={(event) =>
              props.onToDateChange(getUTCDate(event.target.value))
            }
          />
        </div>
        <div className="flex-col px-1">
          <Button
            onClick={() => props.onApplyFilter()}
            className={`rounded-lg w-full ${COLORS.TEXT_PRIMARY} !bg-[#F9F5FF] `}
            color="white"
            size="sm"
            primary
          >
            {translate("search", "Search")}
          </Button>
        </div>
      </div>
      <div>
        <RenderingMagazineList />
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => props.onPageChange(props.magazinesList.pageIndex - 1)}
          disabled={props.magazinesList.pageIndex === 0}
          className="mx-2 px-4 py-2 text-sm text-gray-700 bg-gray-200 rounded-md"
        >
          {translate("Previous_Text", "Previous")}
        </button>
        {Array.from(
          {
            length: Math.ceil(
              props.magazinesList.totalCount / props.magazinesList.pageSize
            ),
          },
          (_, i) => i + 1
        ).map((page) => (
          <button
            key={page}
            onClick={() => props.onPageChange(page - 1)}
            className={`mx-1 px-4 py-2 text-sm ${
              props.magazinesList.pageIndex === page - 1
                ? "text-blue-600 bg-blue-100"
                : "text-gray-700 bg-gray-200"
            } rounded-md`}
          >
            {page}
          </button>
        ))}
        <button
          onClick={() => props.onPageChange(props.magazinesList.pageIndex + 1)}
          disabled={
            props.magazinesList.pageIndex ===
            Math.ceil(
              props.magazinesList.totalCount / props.magazinesList.pageSize
            ) -
              1
          }
          className="mx-2 px-4 py-2 text-sm text-gray-700 bg-gray-200 rounded-md"
        >
          {translate("Next_Text", "Next")}
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  magazinesList: state.magazine.magazinesList,
  filter: state.magazine.filter.search?.value,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  goTo: (route: string) => {
    dispatch(push(route));
  },

  getVendorMagazines: () => {
    dispatch(getVendorMagazines());
  },
  onAddMagazineClick: (addMagazineModalProps: ModalPopupStore) => {
    dispatch(setDialogStateChange(addMagazineModalProps));
  },
  setCloseDialog: () => {
    dispatch(setCloseDialog());
  },
  onSearchChange: (value: string) => {
    dispatch(setSearch(value));
  },
  onFromDateChange: (value: Date) => {
    dispatch(setFromDate(value));
  },
  onToDateChange: (value: Date) => {
    dispatch(setToDate(value));
  },
  onPageChange: (pageIndex: number) => {
    dispatch(setPage(pageIndex));
    dispatch(getVendorMagazines());
  },
  onApplyFilter: () => {
    dispatch(resetMagzinList());
    dispatch(getVendorMagazines());
  },
});

const MagazineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Magazine);
export { MagazineContainer };
