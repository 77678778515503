import { DetailsCardProps } from "./details-card.props";
import React from "react";

const DetailsCard = (props: DetailsCardProps) => {
  return (
    <div className="flex flex-col w-full shadow-sm bg-white rounded-lg relative border-2">
      <div className="border-b p-4">
        <h2 className="text-xl font-semibold">{props.title}</h2>
      </div>
      <div className="flex-auto  p-4 w-full">
        {props.details &&
          props.details.map((line) => {
            return (
              <div className="flex flex-row items-center">
                <div className="text-sm font-semibold">{line.key} : &nbsp;</div>
                <div>{line.value}</div>
              </div>
            );
          })}
        {props.body && props.body}
      </div>
    </div>
  );
};

export default DetailsCard;
