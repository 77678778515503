
interface LoginModel {
  userName: string;
  password: string;
  SystemId: string;
}

interface LoginResponse {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isPasswordExpired: boolean;
  token: string;
  refreshToken:string;
  refreshTokenExpiration: Date;
}

interface LoginTermsConditionsResponse {
  id: string;
  content: string;
  version: number;
  createdAt: Date;
}

enum ClaimType {
  Role = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
}

enum RolesTypes {
  Admin = "super_administrator:4ced1291-5c36-4353-acdf-3ddbc48a6292",
  VendorAdmin = "administrator:4ced1291-5c36-4353-acdf-3ddbc48a6292",
}
enum SystemsId{
  OfferzSystemId = "4ced1291-5c36-4353-acdf-3ddbc48a6292"
}
enum LoggedUserStatus {
  NotVerified,
  RegistrationNotComplete,
  RequestSubmited,
  PendingApproval,
  RequestRejected,
  OfferzMember,
}
export { ClaimType, RolesTypes, LoggedUserStatus, SystemsId };
export type { LoginModel, LoginResponse, LoginTermsConditionsResponse };
