import { push } from "@lagunovsky/redux-react-router";
import { connect } from "react-redux";
import { AppDispatch, ApplicationState } from "../../../store";
import translate from "../../../utils/translations";
import { Button } from "@ofrz/ui-package";
import { useEffect, useState } from "react";
import { SYSTEM_ADMIN } from "../../../libs/routes";

import { generatePath, useParams } from "react-router";
import { HomeIcon } from "../../../static/images/svgs/home";
import { COLORS } from "../../../libs/colors";
import { ArrowRight } from "../../../static/images/svgs/arrow-right";
import VendorDetails from "../../../components/vendor-details/vendor-details";
import { VendorDetailsProps } from "./vendor-details-page.props";
import { VendorDetailsResponse } from "../../../libs/models/system-admin";
import { GetVendorDetails } from "../../../services/offerz-service";
import onClickVendorStatus from "../Vendors/vendors-page.helpers";
import {
  ModalPopupStore,
  setCloseDialog,
  setDialogStateChange,
} from "../../../store/slices/modal-popup";
import { getVendorDetails, vendorAction } from "../../../store/slices/vendors";

const VendorDetailsPage = (props: VendorDetailsProps) => {
  const params = useParams<{ id: string }>();
  useEffect(() => {
    params.id && props.onInit(params.id);
  }, []);

  const onActionClick = (activate: boolean) => {
    onClickVendorStatus(
      params.id!,
      props.vendorDetails!.vendorName,
      activate,
      props.onOpenDialog,
      (id, activate) => props.onVendorAction(id, activate),
      props.onCloseDialog
    );
  };

  return (
    <div className="flex flex-col space-y-1 w-full h-full py-2">
      {!!props.vendorDetails && (
        <>
          <div className="flex flex-row p-3 space-x-2 items-center">
            <div
              onClick={() => props.onHomeIconClick()}
              className="cursor-pointer">
              <HomeIcon />
            </div>
            <ArrowRight />
            <div className="text-[#667085]">
              {translate("Vendors_Request_Text", "Vendor Request")}
            </div>
            <ArrowRight />
            <div className={`${COLORS.TEXT_PRIMARY}`}>
              {props.vendorDetails.vendorName}
            </div>
          </div>
          <div className="flex flex-row p-2">
            <div className="text-4xl font-semibold">
              {props.vendorDetails.vendorName}
            </div>
            <div className="flex flex-1 justify-end space-x-2">
              <Button
                className={`${COLORS.TEXT_FORM} rounded-lg border-2`}
                disabled={!props.vendorDetails.isActive}
                onClick={() => {
                  onActionClick(false);
                }}>
                {translate("Deactivate_Text", "Deactivate")}
              </Button>
              <Button
                className={`${COLORS.TEXT_WHITE} rounded-lg`}
                disabled={props.vendorDetails.isActive}
                primary
                onClick={() => {
                  onActionClick(true);
                }}>
                {translate("Activate_Text", "Activate")}
              </Button>
            </div>
          </div>
          <div className="flex flex-col pb-3 w-full space-y-3 justify-center items-center">
            <VendorDetails
              vendorName={props.vendorDetails.vendorName}
              vendorWebsite={props.vendorDetails.vendorWebsite}
              vendorPhoneNumber={props.vendorDetails.vendorPhoneNumber}
              vendorEmail={props.vendorDetails.vendorEmail}
              taxRegistrationFileld={props.vendorDetails.taxRegistrationFileld}
              vendorMobileNumber={props.vendorDetails.vendorMobileNumber}
              vendorBranches={props.vendorDetails.vendorBranches}
              vendorManager={props.vendorDetails.vendorManager}
            />
          </div>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  vendorDetails: state.vendors.VendorDetails,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onInit: (vendorId: string) => {
    dispatch(getVendorDetails(vendorId));
  },
  onHomeIconClick: () => {
    dispatch(push(generatePath(SYSTEM_ADMIN.VENDORS)));
  },
  onOpenDialog: (modalProps: ModalPopupStore) => {
    dispatch(setDialogStateChange(modalProps));
  },
  onCloseDialog: () => {
    dispatch(setCloseDialog());
  },
  onVendorAction: (
    vendorId: string,
    active: boolean
  ) => {
    dispatch(vendorAction(vendorId, active));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorDetailsPage);
