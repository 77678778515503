import React, { useEffect } from "react";
import { SnackBarProps } from "./snack-bar.props";
import toast, { Toaster } from "react-hot-toast";

const SnackBar = (props:SnackBarProps) =>{
    useEffect(()=>{
       if(props.snack){
        toast(props.snack);
        props.onRemoveSnack()
       } 

    },[props.snack]);
    return  <Toaster />
}

export {SnackBar}