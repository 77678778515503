import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ErrorsCodes, UserCulture } from "../../libs/enums";
import { MagazineModel } from "../../libs/models/magazine";
import {
  addMagazineService,
  deleteMagazineService,
  editMagazineService,
  getMagazineService,
  uploadFile,
} from "../../services/offerz-service";
import { AsyncAppThunk } from "../application-state";
import { setCloseDialog } from "./modal-popup";
import { showLoaderWhile } from "./ui";
import { setErrorCode } from "./login";
import { validateMagazineItemState } from "../../pages/Magazine/magazine.policy";
import { PageOptions, PaginatedList } from "../../libs/models/paginated-list";
import { Filter } from "../../libs/models/filter";
import { getFilterQuery } from "../../utils/filter";

export interface MagazineStore {
  isValidating: boolean;
  magazinesList: PaginatedList<MagazineModel>;
  filter: Filter;
  userCulture: UserCulture;
  magazineItem: MagazineModel;
  magazineFile?: File;
}

const initialState: MagazineStore = {
  isValidating: false,
  filter: {
    search: { key: "title", value: "" },
    dateProp: { from: null, to: null },
    orderBy: { key: "Title", sort: "asc" },
  },
  magazinesList: {
    list: [],
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
  },
  userCulture: UserCulture.English,
  magazineItem: {
    id: "",
    title: "",
    description: "",
    fileId: "",
    isActive: false,
    from: "",
    to: "",
  },
};

const slice = createSlice({
  name: "magazine",
  initialState,
  reducers: {
    setIsValidating: (state: MagazineStore, action: PayloadAction<boolean>) => {
      state.isValidating = action.payload;
    },
    setMagazineList: (
      state: MagazineStore,
      action: PayloadAction<PaginatedList<MagazineModel>>
    ) => {
      state.magazinesList = action.payload;
    },
    setPage: (
      state: MagazineStore,
      action: PayloadAction<number>
    ) => {
      state.magazinesList.pageIndex = action.payload;
    },
    setMagazineFile: (
      state: MagazineStore,
      action: PayloadAction<File | undefined>
    ) => {
      state.magazineFile = action.payload;
    },
    setMagazineFileId: (
      state: MagazineStore,
      action: PayloadAction<string>
    ) => {
      state.magazineItem.fileId = action.payload;
    },
    setMagazineItemProp: (
      state: MagazineStore,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      const { name, value } = action.payload;
      state.magazineItem = { ...state.magazineItem, [name]: value };
    },
    setMagazineItem: (
      state: MagazineStore,
      action: PayloadAction<MagazineModel>
    ) => {
      state.magazineItem = action.payload;
    },
    setSearch:( state: MagazineStore, action: PayloadAction<string>)=>{
      state.filter.search!.value = action.payload;
    },
    setFromDate:( state: MagazineStore, action: PayloadAction<Date>)=>{
      state.filter.dateProp!.from = action.payload.toISOString();
    },
    setToDate:( state: MagazineStore, action: PayloadAction<Date>)=>{
      state.filter.dateProp!.to = action.payload.toISOString();
    },
    setClearMagazineItem: (state: MagazineStore) => {
      state.magazineFile = undefined;
      state.isValidating = false;
      state.magazineItem = {
        id: "",
        title: "",
        description: "",
        fileId: "",
        isActive: false,
        from: "",
        to: "",
      };
    },
    resetMagzinList: (state: MagazineStore) => {
      state.magazinesList = initialState.magazinesList
    },
  },
});

export const {
  setIsValidating,
  setMagazineList,
  setPage,
  setSearch,
  setFromDate, 
  setToDate,
  setMagazineItemProp,
  setMagazineFile,
  setMagazineFileId,
  setClearMagazineItem,
  setMagazineItem,
  resetMagzinList
} = slice.actions;
export const reducer = slice.reducer;

export const getVendorMagazines =
  (): AsyncAppThunk => async (dispatch, store) => {
    dispatch(
      showLoaderWhile(async () => {
        const magazineResponse = await getMagazineService(getFilterQuery(store().magazine.filter, store().magazine.magazinesList.pageIndex*store().magazine.magazinesList.pageSize, store().magazine.magazinesList.pageSize));
        if (magazineResponse && magazineResponse?.result !== null) {
          dispatch(setMagazineList(magazineResponse!.result));
        }
      })
    );
  };

export const deleteVendorMagazine =
  (id: string): AsyncAppThunk =>
  async (dispatch, store) => {
    dispatch(
      showLoaderWhile(async () => {
        const magazineResponse = await deleteMagazineService(id);
        if (magazineResponse && magazineResponse?.result !== null) {
          dispatch(setCloseDialog());
          dispatch(setClearMagazineItem());
          dispatch(getVendorMagazines());
        }
      })
    );
  };

export const editVendorMagazine =
  (): AsyncAppThunk => async (dispatch, store) => {
    const magazineItem = store().magazine.magazineItem!;
    dispatch(setIsValidating(true));
    if (Object.keys(validateMagazineItemState(store().magazine)).length == 0) {
      dispatch(
        showLoaderWhile(async () => {
          const magazineResponse = await editMagazineService(magazineItem);
          if (magazineResponse && magazineResponse?.result !== null) {
            dispatch(setCloseDialog());
            dispatch(setClearMagazineItem());
            dispatch(getVendorMagazines());
          }
        })
      );
    }
  };

export const addVendorMagazine =
  (): AsyncAppThunk => async (dispatch, store) => {
    const magazineItem = store().magazine.magazineItem!;
    dispatch(setIsValidating(true));
    if (Object.keys(validateMagazineItemState(store().magazine)).length == 0) {
      dispatch(
        showLoaderWhile(async () => {
          const magazineResponse = await addMagazineService(magazineItem);
          if (magazineResponse && magazineResponse?.result !== null) {
            dispatch(setCloseDialog());
            dispatch(getVendorMagazines());
          }
        })
      );
      dispatch(setClearMagazineItem());
    }
  };

export const UploadMagazineFile =
  (file: File): AsyncAppThunk =>
  async (dispatch, store) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(setMagazineFile(file));
    dispatch(
      showLoaderWhile(async () => {
        const magazineUploadResponse = await uploadFile(formData);

        if (magazineUploadResponse && magazineUploadResponse.result != null) {
          dispatch(setMagazineFileId(magazineUploadResponse.result));
        } else {
          dispatch(
            setErrorCode(magazineUploadResponse?.errorCode as ErrorsCodes)
          );
        }
      })
    );
  };
