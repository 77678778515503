import { Button } from "@ofrz/ui-package";
import { ProductModel } from "../../libs/models/product";
import translate from "../../utils/translations";
import { COLORS } from "../../libs/colors";
import { EditIcon } from "../../static/images/svgs/edit-icon-svg";
import { DeleteIcon } from "../../static/images/svgs/delete-icon-svg";
import { CategoryModel } from "../../libs/models/category";

export const adminProductRequestHeaders = [
  {
    title: translate("Product_Name_Text", "Product name"),
    dataIndex: "name",
    key: "name",
    isSortable: false,
  },
  {
    title: translate("Category_Text", "Category"),
    dataIndex: "productCategory",
    key: "productCategory",
    isSortable: false,
  },
  {
    title: translate("Sub-Category_Text", "Sub-Category"),
    dataIndex: "productSubCategory",
    key: "productSubCategory",
    isSortable: false,
  },
  {
    title: translate("Action_Text", "Action"),
    dataIndex: "actions",
    key: "actions",
    isSortable: false,
  },
];
export const getProductModelList = (
  productModel: ProductModel[],
  onProductEdit: (id: string,productModel:ProductModel) => void,
  onProductDelete: (id: string) => void
) => {
  const data = [];
  for (var i = 0; i < productModel.length; i++) {
    const request = productModel[i];
    const row: Record<string, any> = {
      id: request.id,
      name: request.name,
      productCategory: request.productCategory,
      productSubCategory: request.productSubCategory,
      actions: (
        <div>
          <Button
            className={`${COLORS.TEXT_FORM} !p-0 m-2`}
            onClick={() => {onProductEdit(request.id!,request)}}
          >
            <EditIcon />
          </Button>
          <Button
            className={`${COLORS.TEXT_PRIMARY} !p-0 m-2`}
            onClick={() => onProductDelete(request.id!)}
          >
            <DeleteIcon />
          </Button>
        </div>
      ),
    };
    data.push(row);
  }
  return data;
};

export const getCategoryIdBySubCategory = (subCategoryId:string,categoryList:CategoryModel[])=>{
  const categoryId = categoryList.find((obj)=>obj.children.find(att=>att.id === subCategoryId) !== undefined)?.id;
  return categoryId
}