import * as UiStore from "./slices/ui";
import * as LoginStore from "./slices/login";
import * as ForgetPasswordStore from "./slices/forget-password";
import * as SignUpStore from "./slices/signup";
import * as AccountVerificationStore from "./slices/account-verification";
import * as VendorDetailsStore from "./slices/vendor-details";
import * as StepperStore from "./slices/stepper";
import * as MagazineStore from "./slices/magazine";
import * as ModalUpStore from "./slices/modal-popup";
import * as VendorsStore from "./slices/vendors";
import * as TermsConditionsStore from "./slices/terms-conditions";
import * as CategoriesStore from "./slices/categories";
import * as DealsStore from "./slices/deals";
import * as VendorProductStore from "./slices/vendor-product";
import * as ProductStore from "./slices/product";
import * as BrandStore from "./slices/brand";
import * as OfferzLandingPageStore from "./slices/offerz-landing-page";
const reducers = {
  ui: UiStore.reducer,
  login: LoginStore.reducer,
  forgetPassword: ForgetPasswordStore.reducer,
  signup: SignUpStore.reducer,
  accountverification: AccountVerificationStore.reducer,
  vendordetails: VendorDetailsStore.reducer,
  registrationstepper: StepperStore.reducer,
  magazine: MagazineStore.reducer,
  modalpopup: ModalUpStore.reducer,
  vendors: VendorsStore.reducer,
  termsconditions: TermsConditionsStore.reducer,
  categories: CategoriesStore.reducer,
  deals: DealsStore.reducer,
  product: ProductStore.reducer,
  vendorproduct: VendorProductStore.reducer,
  brand: BrandStore.reducer,
  offerzlandingpage: OfferzLandingPageStore.reducer,
};

export { reducers };
