import React from "react";
import { WarningImg } from "../../../static/images/svgs/warning-img-svg";
import translate from "../../../utils/translations";
import { VendorActionPopUpProps } from "./vendors-page.props";
import { ModalPopupStore } from "../../../store/slices/modal-popup";
import { COLORS } from "../../../libs/colors";

const VendorActionPopUp = (props: VendorActionPopUpProps) => {
  return (
    <div className="flex p-[24px] h-full">
      <div className="self-start">
        <WarningImg />
      </div>
      <div className="flex flex-col ml-2">
        <div className="my-[16px] text-[18px] font-medium justify-center">
          {(props.isActivate
            ? translate("Activate_Text", "Activate")
            : translate("Deactivate_Text", "Deactivate")) +
            " " +
            props.vendorName}
        </div>
        <div>
          {props.isActivate
            ? translate(
                "Activate_Popup_Body_Text",
                "Are you sure you want activate this Vendor?"
              )
            : translate(
                "Deactivate_Popup_Body_Text",
                "Are you sure you want deactivate this Vendor?"
              )}
        </div>
      </div>
    </div>
  );
};

const onClickVendorStatus = (
  vendorId: string,
  vendorName: string,
  isActivate: boolean,
  onOpenDialog: (modalProps: ModalPopupStore) => void,
  onVendorAction: (vendorId: string, active: boolean) => void,
  onCloseDialog: () => void
) => {
  onOpenDialog({
    isOpen: true,
    content: (
      <VendorActionPopUp vendorName={vendorName} isActivate={isActivate} />
    ),
    actionButtons: [
      {
        children: (
          <div className={`${COLORS.TEXT_POPUP} `}>
            {translate("Cancel_Text", "Cancel")}
          </div>
        ),
        size: "lg",
        className: `rounded-lg  border-2  `,
        onClick: () => onCloseDialog(),
      },
      {
        children: (
          <div className={`${COLORS.TEXT_WHITE} `}>
            {isActivate
              ? translate("Activate_Text", "Activate")
              : translate("Deactivate_Text", "Deactivate")}
          </div>
        ),
        size: "lg",
        primary: false,
        className: `!bg-red-600 rounded-lg`,
        onClick: () => onVendorAction(vendorId, isActivate),
      },
    ],
    onClose: () => onCloseDialog(),
  });
};

export default onClickVendorStatus;
