import { push } from "@lagunovsky/redux-react-router";
import { Button, TextInput } from "@ofrz/ui-package";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { COLORS } from "../../libs/colors";
import { CategoryModel } from "../../libs/models/category";
import { RolesTypes } from "../../libs/models/login";
import { NoMagazineImage } from "../../static/images/svgs/no-magazine-img-svg";
import { AppDispatch, ApplicationState } from "../../store";
import {
  getCategoriesList,
  resetCategoriesList,
  setCategorySearch,
} from "../../store/slices/categories";
import { setPage } from "../../store/slices/magazine";
import {
  ModalPopupStore,
  setCloseDialog,
  setDialogStateChange,
} from "../../store/slices/modal-popup";
import translate from "../../utils/translations";
import { CategoriesProps } from "./categories.props";
import { CategoryCardItemContainer } from "./components/category-card-item";
import { AddCategoryPopUpContainer } from "./containers/AddCategoryContainer";
import { CloseIcon } from "static/images/svgs/close-icon-svg";

const Categories = (props: CategoriesProps) => {
  useEffect(() => {
    props.getCategoriesList();
  }, []);

  const onAddCategoryItem = () => {
    props.onAddCategoryClick({
      isOpen: true,
      title: <p>New Category</p>,
      content: <AddCategoryPopUpContainer />,
      actionButtons: [],
      onClose: () => props.setCloseDialog(),
    });
  };

  const RenderingMagazineList = () => {
    return props.categoriesList.list.length > 0 ? (
      <div className="flex  gap-3 w-full flex-wrap">
        {props.categoriesList.list.map((categoryItem: CategoryModel) => {
          return <CategoryCardItemContainer categoryItem={categoryItem} />;
        })}
      </div>
    ) : (
      <div className="flex items-center justify-center mt-[53px]">
        <div className="text-center">
          <NoMagazineImage />
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between py-[25px]">
        <div className="text-[30px] font-medium">
          {translate("Categories", "Categories")}
        </div>
        {props.userRole === RolesTypes.Admin && (
          <Button
            className="text-white rounded-lg !bg-[#518c7d]"
            onClick={onAddCategoryItem}
          >
            {translate("Add_New_Category_Text", "Add New Category")}
          </Button>
        )}
      </div>
      <div className="flex items-center mb-8">
        <div className="flex-col w-[250px] px-1">
          <TextInput
            className="w-full border p-2"
            placeholder="Category Name"
            type="text"
            name="name"
            value={props.filter}
            onChange={(e) => {
              props.onSearchChange(e.target.value);
            }}
            suffixContent={
              props.filter !== null &&
              props.filter !== "" && (
                <button
                  className="bg-[#518c7d1a] hover:bg-[#518c7d4d] p-1 rounded-[100%]"
                  onClick={(e) => {
                    props.onSearchChange("");
                  }}
                >
                  <CloseIcon />
                </button>
              )
            }
          />
        </div>
        <div className="flex-col px-1">
          <Button
            onClick={() => props.onApplyFilter()}
            className={`rounded-lg w-full ${COLORS.TEXT_PRIMARY} !bg-[#F9F5FF] `}
            color="white"
            size="sm"
            primary
          >
            {translate("search", "Search")}
          </Button>
        </div>
      </div>
      <div>
        <RenderingMagazineList />
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => props.onPageChange(props.categoriesList.pageIndex - 1)}
          disabled={props.categoriesList.pageIndex === 0}
          className="mx-2 px-4 py-2 text-sm text-gray-700 bg-gray-200 rounded-md"
        >
          {translate("Previous_Text", "Previous")}
        </button>
        {Array.from(
          {
            length: Math.ceil(
              props.categoriesList.totalCount / props.categoriesList.pageSize
            ),
          },
          (_, i) => i + 1
        ).map((page) => (
          <button
            key={page}
            onClick={() => props.onPageChange(page - 1)}
            className={`mx-1 px-4 py-2 text-sm ${
              props.categoriesList.pageIndex === page - 1
                ? "text-blue-600 bg-blue-100"
                : "text-gray-700 bg-gray-200"
            } rounded-md`}
          >
            {page}
          </button>
        ))}
        <button
          onClick={() => props.onPageChange(props.categoriesList.pageIndex + 1)}
          disabled={
            props.categoriesList.pageIndex ===
            Math.ceil(
              props.categoriesList.totalCount / props.categoriesList.pageSize
            ) -
              1
          }
          className="mx-2 px-4 py-2 text-sm text-gray-700 bg-gray-200 rounded-md"
        >
          {translate("Next_Text", "Next")}
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  categoriesList: state.categories.categoriesList,
  userRole: state.login.userRole!,
  filter: state.categories.filter.search?.value,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  goTo: (route: string) => {
    dispatch(push(route));
  },

  getCategoriesList: () => {
    dispatch(getCategoriesList());
  },
  onAddCategoryClick: (modalProps: ModalPopupStore) => {
    dispatch(setDialogStateChange(modalProps));
  },

  setCloseDialog: () => {
    dispatch(setCloseDialog());
  },
  onSearchChange: (value: string) => {
    dispatch(setCategorySearch(value));
  },

  onPageChange: (pageIndex: number) => {
    dispatch(setPage(pageIndex));
    dispatch(getCategoriesList());
  },
  onApplyFilter: () => {
    dispatch(resetCategoriesList());
    dispatch(getCategoriesList());
  },
});

const CategoriesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Categories);
export { CategoriesContainer };
