import { connect } from "react-redux";
import { COLORS } from "../../../libs/colors";
import { DeleteIcon } from "../../../static/images/svgs/delete-icon-svg";
import { EditIcon } from "../../../static/images/svgs/edit-icon-svg";
import { OfferzLogo } from "../../../static/images/svgs/offerz-logo";
import { ViewIcon } from "../../../static/images/svgs/view-icon-svg";
import { AppDispatch, ApplicationState } from "../../../store";
import {
  deleteVendorMagazine,
  editVendorMagazine,
  setMagazineItem,
} from "../../../store/slices/magazine";
import {
  ModalPopupStore,
  setCloseDialog,
  setDialogStateChange,
} from "../../../store/slices/modal-popup";
import { MagazineCardItemProps } from "./magazine-item.props";
import DeleteMagazinePopUp from "./delete-magazine-popup";
import AddMagazinePopUpContainer from "../containers/AddMagazine/add-magazine-popup.container";
import UpdateMagazinePopUpContainer from "../containers/UpdateMagazine/update-magazine-popup.container";
import { MagazineModel } from "../../../libs/models/magazine";
import translate from "../../../utils/translations";
import { UploadIcon } from "../../../static/images/svgs/upload-icon.svg";
import { getFileUrl } from "utils/mediaUrlFormater";
import { CrosspayLogo } from "static/images/svgs/crosspay-logo";

const MagazineCardItem = (props: MagazineCardItemProps) => {
  const onDeleteItem = () => {
    props.onDeleteMagazineClick({
      isOpen: true,
      title: <></>,
      content: <DeleteMagazinePopUp />,
      actionButtons: [
        {
          children: <div className={`${COLORS.TEXT_WHITE} bg-red`}>close</div>,
          className: `rounded-lg`,
          primary: true,
          onClick: props.setCloseDialog,
        },
        {
          children: <div className={`${COLORS.TEXT_WHITE} bg-red`}>Delete</div>,
          className: `rounded-lg`,
          primary: true,

          onClick: () => props.deleteVendorMagazine(props.magazineItem.id),
        },
      ],
      onClose: () => props.setCloseDialog(),
    });
  };

  const onEditItem = () => {
    props.onEditMagazineClick(
      {
        isOpen: true,
        title: <p>{translate("Edit_Magazine", "Edit Magazine")}</p>,
        content: <UpdateMagazinePopUpContainer />,
        actionButtons: [],
        onClose: () => props.setCloseDialog(),
      },
      props.magazineItem
    );
  };

  const onViewMagazin = () => {
    const link = document.createElement("a");
    link.href = getFileUrl(props.magazineItem.fileId);
    link.target = "_blank";
    link.download = "downloaded-file";
    link.click();
  };

  return (
    <div
      className="rounded-[8px] shadow-lg w-[300px] h-[140px] p-3 flex"
      key={props.magazineItem.id}
    >
      <div className="flex w-[80px] h-[115px] bg-white rounded-[10px] mr-3 justify-center items-center">
        <CrosspayLogo width="60" height="50" />
      </div>
      <div className="flex flex-col h-full justify-between w-[180px]">
        <div>
          <div className="flex justify-between mb-[16px]">
            <div>{props.magazineItem.title}</div>
            <div>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={props.magazineItem.isActive}
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#7F56D9]"></div>
              </label>
            </div>
          </div>
          <div className="text-[#518c7d80] text-[14px] text-ellipsis line-clamp-2">
            {props.magazineItem.description}
          </div>
        </div>
        <div className="flex justify-between px-[18px] items-center content-end">
          <div className="cursor-pointer" onClick={onDeleteItem}>
            <DeleteIcon />
          </div>
          <div className="cursor-pointer" onClick={onEditItem}>
            <EditIcon />
          </div>
          <div className="cursor-pointer" onClick={onViewMagazin}>
            <UploadIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  deleteVendorMagazine: (id: string) => {
    dispatch(deleteVendorMagazine(id));
  },
  editVendorMagazine: () => {
    dispatch(editVendorMagazine());
  },
  onDeleteMagazineClick: (deleteModalDialogProps: ModalPopupStore) => {
    dispatch(setDialogStateChange(deleteModalDialogProps));
  },
  onEditMagazineClick: (
    editModalDialogProps: ModalPopupStore,
    magazineItem: MagazineModel
  ) => {
    dispatch(setMagazineItem(magazineItem));
    dispatch(setDialogStateChange(editModalDialogProps));
  },
  setCloseDialog: () => {
    dispatch(setCloseDialog());
  },
});

const MagazineCardItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MagazineCardItem);
export { MagazineCardItemContainer };
