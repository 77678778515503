const getUTCDate = (date:string) =>{
    console.log('date', date)
    const convertedDate:string[] = date.split('-');
    const numberArray: number[] = convertedDate.map((str) => Number(str));
    if(numberArray.length == 3){
        numberArray[1] = numberArray[1] - 1 // Date.UTC expects a value from 0-11
    }
    return new Date(Date.UTC(numberArray[0], numberArray[1], numberArray[2]))
}

export {getUTCDate}