import React, { useEffect, useState } from "react";
import { TermsConditionsListProps } from "./terms-conditions-list.props";
import { COLORS } from "../../../../../libs/colors";
import translate from "../../../../../utils/translations";
import { formatDistance } from "date-fns";
import { CollipsibleCard } from "../../../../../components/collipsible-card";
import RenderHtml from "../../../../../components/html-render/html-render";

const TermsConditionsList = (props: TermsConditionsListProps) => {
  return (
    <div className="flex flex-1 w-full flex-col">
      {props.list.map((item) => (
        <CollipsibleCard
          content={
            <>
              <div
                className={`flex flex-1 ${COLORS.TEXT_PRIMARY}`}>{`${translate(
                "Version_Text",
                "Verison"
              )} ${item.version}`}</div>
              <div className="flex flex-1" />
              <div className="flex flex-1 justify-end">{`${formatDistance(
                item.createdAt,
                Date.now(),
                {
                  addSuffix: true,
                }
              )}`}</div>
            </>
          }
          details={
            <div className="overflow-y-auto h-max-10">
              <RenderHtml html={item.content} />
            </div>
          }
        />
      ))}
    </div>
  );
};

export { TermsConditionsList };
