import { connect } from "react-redux";
import { AppDispatch, ApplicationState } from "../../store";
import { Button, Grid } from "@ofrz/ui-package";
import {
  productPricingModelList,
  vendorsProductRequestHeaders,
} from "./vendor-product.util";
import translate from "../../utils/translations";
import { VendorProductProps } from "./vendor-product.props";
import { AddVendorProductPopUpContainer } from "./containers/AddVendorProduct";
import {
  ModalPopupStore,
  setCloseDialog,
  setDialogStateChange,
} from "../../store/slices/modal-popup";
import { getBrandsLookUp } from "../../store/slices/brand";
import {
  getCategoriesLookUp,
  setSubCategoryLookUp,
} from "../../store/slices/categories";
import { useEffect } from "react";
import {
  RequestVendorProductPricing,
  VendorProductModel,
} from "../../libs/models/vendor-product";
import {
  clearVendorProductItem,
  deleteVendorProductPrice,
  getVendorProductPriceList,
  setVendorProductItem,
  setVendorProductListPage,
} from "../../store/slices/vendor-product";
import { UpdateVendorProductPopUpContainer } from "./containers/UpdateVendorProduct";
import { setClearProductItem } from "../../store/slices/product";

const VendorProduct = (props: VendorProductProps) => {
  useEffect(() => {
    props.loadCategoryLookUp();
    props.loadBrandLookUp();
    props.getVendorProductPriceList();
  }, []);
  const onAddProducPricetItem = () => {
    props.onAddVendorProductPriceClick({
      isOpen: true,
      title: <p>Add Product</p>,
      content: <AddVendorProductPopUpContainer />,
      actionButtons: [],
      onClose: () => props.setCloseDialog(),
    });
  };
  const onEdit = (productPriceRequestModel: RequestVendorProductPricing, productPriceModel: VendorProductModel) => {
    props.setVendorProductItem(productPriceRequestModel);
    props.onAddVendorProductPriceClick({
      isOpen: true,
      title: <p>Update Product</p>,
      content: (
        <UpdateVendorProductPopUpContainer
          vendorProductDetails={productPriceModel}
        />
      ),
      actionButtons: [],
      onClose: () => props.setCloseDialog(),
    });
  };
  const onDelete = (id: string) => {
    props.deleteProductPricing(id);
  };
  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between py-[25px]">
        <div className="text-[30px] font-medium">
          {translate("Products_Text", "Products")}
        </div>
        <Button
          className="text-white rounded-lg !bg-[#518c7d]"
          onClick={onAddProducPricetItem}
        >
          {translate("Add_New_Product_Text", "Add New Product")}
        </Button>
      </div>
      <Grid
        columns={vendorsProductRequestHeaders}
        dataSource={productPricingModelList(
          props.vendorProductList.list,
          onEdit,
          onDelete
        )}
        currentPage={props.vendorProductList.pageIndex + 1}
        onPageChange={(newPage: number) => { props.onPageChange(newPage - 1) }}
        pagination={true}
        pageSize={props.vendorProductList.pageSize}
        totalCount={props.vendorProductList.totalCount}
        onSort={(dataIndex: string, order: "desc" | "asc") => { }}
      />
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  vendorProductList: state.vendorproduct.vendorProductList,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onAddVendorProductPriceClick: (modalProps: ModalPopupStore) => {
    dispatch(setDialogStateChange(modalProps));
  },
  setCloseDialog: () => {
    dispatch(clearVendorProductItem());
    dispatch(setClearProductItem());
    dispatch(setCloseDialog());
  },
  loadCategoryLookUp: () => {
    dispatch(getCategoriesLookUp());
  },
  setSubCategory: (categoryId: string) => {
    dispatch(setSubCategoryLookUp(categoryId));
  },
  loadBrandLookUp: () => {
    dispatch(getBrandsLookUp());
  },
  getVendorProductPriceList: () => {
    dispatch(getVendorProductPriceList());
  },
  deleteProductPricing: (productPriceId: string) => {
    dispatch(deleteVendorProductPrice(productPriceId));
  },
  setVendorProductItem: (vendorProductItem: RequestVendorProductPricing) => {
    dispatch(setVendorProductItem(vendorProductItem));
  },
  onPageChange: (pageNumber: number) => {
    dispatch(setVendorProductListPage(pageNumber));
    dispatch(getVendorProductPriceList());
  }
});

const VendorProductContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorProduct);
export { VendorProductContainer };
