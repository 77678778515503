import { PaginatedList } from "libs/models/paginated-list";
import { VendorsRequestsResponse } from "../../../libs/models/system-admin";

export const getVendorRequestDetails = (
  requests: PaginatedList<VendorsRequestsResponse>,
  requestId?: string
) => {
  if (!requestId) {
    return undefined;
  }
  const foundRequest = requests.list.find(
    (request) => request.id === requestId
  );
  return foundRequest;
};
