import { createRouterMiddleware, createRouterReducer } from '@lagunovsky/redux-react-router';
import { configureStore } from '@reduxjs/toolkit';
import { History } from 'history';
import { combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use localStorage by default
import { ApplicationState } from './application-state';
import { reducers } from './root-reducer';


const persistConfig = {
  key: "offerzStore",
  storage,
  whitelist: ["loggedUser", "userRole"],
};

function configureOfferzStore(history: History, preloadedState?: ApplicationState) {
  const middleware = [createRouterMiddleware(history)];
  if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger());
  }
  const store = configureStore({
    reducer: getRootReducer(history),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  });

  const persistor = persistStore(store);

  return { store, persistor };
}

function getRootReducer(history: History) {
  return combineReducers({
    ...reducers,
    login: persistReducer(persistConfig, reducers.login),
    router: createRouterReducer(history)
  });
}

export { configureOfferzStore };

