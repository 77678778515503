import { Button, TextInput } from "@ofrz/ui-package";
import React, { useState } from "react";
import { connect } from "react-redux";
import { ApplicationState, AppDispatch } from "store";
import {
  forgetPasswordOtpRequest,
  setEmail,
} from "store/slices/forget-password";
import translate from "utils/translations";
import { SubmitForgetPasswordFormViewProps } from "./request-forget-password.props";
import { COLORS } from "libs/colors";
import { ForgetPasswordRequestOtp } from "libs/models/forget-password";
import { ValidationModel } from "libs/models/validate-model";
import { validateRequestForgetPasswordState } from "./request-forget-password.policy";

const SubmitForgetPasswordView = (props: SubmitForgetPasswordFormViewProps) => {
  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeEmail(event.target.value.trim());
  };
  const [errors, setErrors] = useState<
    ValidationModel<ForgetPasswordRequestOtp>
  >({ email: "" });

  const onSubmitClicked = () => {
    const validateResult = Object.keys(
      validateRequestForgetPasswordState({ email: props.email })
    );
    if (validateResult.length == 0) {
      props.onSubmit();
    } else setErrors(validateRequestForgetPasswordState({ email: props.email }));
  };
  return (
    <div>
      <h2 className="text-5xl font-semibold font-sans my-5">
        {translate("Forget_Password_Text", "Forget Password")}
      </h2>
      <div className="w-full flex flex-col mb-auto ">
        <div className="flex-col">
          <p className="font-medium text-lg text-[#667085] my-2">
            {translate(
              "Forget_Password_Description_Text",
              "Please enter your Email to reset your password."
            )}
          </p>
          <div className="flex flex-col py-2">
            <div className="my-5">
              <label className={`${COLORS.TEXT_FORM} font-semibold`}>
                {translate("Login_Email_Text", "Email")}
              </label>
              <TextInput
                className="w-full"
                placeholder="store@offerz.com"
                type="text"
                autoComplete="user-name"
                disableSpace
                value={props.email}
                onInput={onEmailChange}
                onChange={onEmailChange}
                error={errors.email}
              />
            </div>
            <div>
              <Button
                onClick={onSubmitClicked}
                className={`rounded-lg w-full ${COLORS.TEXT_WHITE}`}
                color="white"
                size="md"
                primary
              >
                {translate("Submit_Text", "Submit")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  email: state.forgetPassword.email,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onChangeEmail: (email: string) => {
    dispatch(setEmail(email));
  },
  onSubmit: () => {
    dispatch(forgetPasswordOtpRequest());
  },
});
const SubmitForgetPasswordViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitForgetPasswordView);
export { SubmitForgetPasswordViewContainer };
