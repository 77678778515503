import { connect } from "react-redux";
import { AppDispatch, ApplicationState } from "../../../store";
import { TermsConditionsProps } from "./terms-conditions-page.props";
import translate from "../../../utils/translations";
import { Button, Dialog } from "@ofrz/ui-package";
import { useEffect } from "react";
import { TermsConditionsType } from "../../../libs/models/system-admin";
import { COLORS } from "../../../libs/colors";
import { PlusIcon } from "../../../static/images/svgs/plus-icon";
import AddTermsConditionsPopUpContainer from "./containers/terms-condition-create-dialog/add-terms-conditions-popup.container";
import { TermsConditionsList } from "./containers/terms-conditions-list/terms-conditions-list.container";
import {
  initTermsConditionsList,
  onCreate,
  reset,
  setCreateDialogOpen,
  setType,
} from "../../../store/slices/terms-conditions";
import { checkIfContentChanged } from "./containers/terms-condition-create-dialog/add-terms-conditions-popup.utils";
import { UnconfirmedTermsListContainer } from "./containers/unconfirmed-terms-list";

const TermsConditionsPage = (props: TermsConditionsProps) => {
  const isActive = (view: TermsConditionsType) => {
    return view === props.activeView;
  };

  useEffect(() => props.onInit(), []);

  return (
    <>
      <div className="flex flex-col space-y-1 w-full h-full py-2">
        <div className="flex flex-row space-x-1 items-center justify-between">
          <p className="text-3xl font-bold text-[#1D2939] p-4 select-none">
            {translate("Terms_And_Conditions_Text", "Terms and Conditions")}
          </p>
          <Button
            primary
            className="rounded-lg"
            onClick={() => props.onOpenDialog()}>
            <div className="flex items-center space-x-1">
              <PlusIcon />
              <p className={`${COLORS.TEXT_WHITE}`}>
                {translate("Add_Text", "Add")}
              </p>
            </div>
          </Button>
        </div>
        <div className="flex flex-row space-x-1 items-center">
          <div className="flex flex-row w-2/5 space-x-1">
            <Button
              primary={isActive(TermsConditionsType.Consumer)}
              className="rounded-lg "
              onClick={() => props.setActiveView(TermsConditionsType.Consumer)}>
              <p
                className={
                  isActive(TermsConditionsType.Consumer)
                    ? `${COLORS.TEXT_WHITE}`
                    : `${COLORS.TEXT_PRIMARY}`
                }>
                {translate("Consumers_T_C_Versions_Text", "Consumer Versions")}
              </p>
            </Button>
            <Button
              primary={isActive(TermsConditionsType.Vendor)}
              className="rounded-lg "
              onClick={() => props.setActiveView(TermsConditionsType.Vendor)}>
              <p
                className={
                  isActive(TermsConditionsType.Vendor)
                    ? `${COLORS.TEXT_WHITE}`
                    : `${COLORS.TEXT_PRIMARY}`
                }>
                {translate("Vendors_T_C_Versions_Text", "Vendors Versions")}
              </p>
            </Button>
          </div>
        </div>
        <div className="flex space-x-2">
          <div className="flex flex-2 w-2/3 mt-4">
            <TermsConditionsList
              list={
                isActive(TermsConditionsType.Consumer)
                  ? props.consumerList
                  : props.vendorList
              }
            />
          </div>

          <div className="flex flex-2 w-1/3 mt-4">
            <UnconfirmedTermsListContainer />
          </div>
        </div>
      </div>
      <Dialog
        isOpen={props.createDialogOpen}
        title={translate(
          "Add_Terms_And_Conditions_Text",
          "Add terms and conditions"
        )}
        onClose={props.onCloseDialog}
        actionButtons={[
          {
            children: (
              <div className={`${COLORS.TEXT_POPUP}`}>
                {translate("Cancel_Text", "Cancel")}
              </div>
            ),
            size: "lg",
            className: `rounded-lg  border-2`,
            onClick: () => props.onCloseDialog(),
          },
          {
            children: (
              <div className={`${COLORS.TEXT_WHITE}`}>
                {translate("Add_Text", "Add")}
              </div>
            ),
            primary: true,
            disabled: !props.contentChanged,
            size: "lg",
            className: `rounded-lg w-`,
            onClick: () => props.onCreate(),
          },
        ]}
        content={<AddTermsConditionsPopUpContainer />}
      />
    </>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  vendorList: state.termsconditions.VendorList,
  consumerList: state.termsconditions.ConsumerList,
  activeView: state.termsconditions.type,
  createDialogOpen: state.termsconditions.createDialogOpen,
  contentChanged: checkIfContentChanged(
    state.termsconditions.content,
    state.termsconditions.type == TermsConditionsType.Consumer
      ? state.termsconditions.ConsumerList
      : state.termsconditions.VendorList
  ),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onInit: () => {
    dispatch(initTermsConditionsList());
  },
  onCreate: () => {
    dispatch(onCreate());
  },
  setActiveView: (type: TermsConditionsType) => {
    dispatch(setType(type));
  },
  onCloseDialog: () => {
    dispatch(setCreateDialogOpen(false));
    dispatch(reset());
  },
  onOpenDialog: () => {
    dispatch(setCreateDialogOpen(true));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsConditionsPage);
