import { connect } from "react-redux";
import { AppDispatch, ApplicationState } from "../../store";
import { TermsConditionsDialogProps } from "./terms-condition-dialog-container.props";
import { Dialog } from "@ofrz/ui-package";
import translate from "../../utils/translations";
import { COLORS } from "../../libs/colors";
import { useEffect } from "react";
import { OnInitTermsConditions } from "../../store/slices/login";
import RenderHtml from "../../components/html-render/html-render";

const TermsConditionsDialog = (props: TermsConditionsDialogProps) => {
  useEffect(() => {
    props.onInit();
  }, []);

  return (
    <Dialog
      title={
        <div className={`${COLORS.TEXT_PRIMARY}`}>
          {translate("Terms_And_Conditions_Text", "Terms and Conditions")}
        </div>
      }
      onClose={() => {}}
      isOpen={props.open}
      actionButtons={[
        {
          children: <div className={`${COLORS.TEXT_WHITE}`}>I agree</div>,
          primary: true,
          className: `rounded-lg w-full`,
          onClick: () => props.onConfirm(props.termsConditionsId),
        },
      ]}
      content={<RenderHtml html={`${props.termsConditionsBody}`} />}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  termsConditionsBody:
    state.login.termsConditions && state.login.termsConditions.content,
    termsConditionsId: state.login.termsConditions &&  state.login.termsConditions.id
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onInit: () => {
    dispatch(OnInitTermsConditions());
  },
});

export const TermsConditionsDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsConditionsDialog);
