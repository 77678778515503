import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AsyncAppThunk } from '../application-state';

export interface UiStore {
  isLoading: boolean;
  snacks:{
    [key: string]: string;
  }
}

export const initialState: UiStore = {
  isLoading: false,
  snacks:{}
};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showLoader: (state: UiStore) => {
      state.isLoading = true;
    },
    hideLoader: (state: UiStore) => {
      state.isLoading = false;
    },
    addSnack:(state:UiStore, action:PayloadAction<string>) => {
      state.snacks= {...state.snacks,[Object.keys(state.snacks).length]:action.payload}
    },
    removeSnack:(state:UiStore) => {
      delete state.snacks[Object.keys(state.snacks)[0]];
    },
  }
});

export const { showLoader, hideLoader, addSnack, removeSnack } = slice.actions;

export const reducer = slice.reducer;

export const showLoaderWhile = (action: () => Promise<any>): AsyncAppThunk => async (dispatch, store) => {
  dispatch(showLoader());

  try {
    await action();
  } finally {
    dispatch(hideLoader());
  }
};
