import { push } from "@lagunovsky/redux-react-router";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { ROUTES } from "../../libs";
import { ErrorsCodes, UserCulture } from "../../libs/enums";
import { Response } from "../../libs/models/generic-response";
import {
  ClaimType,
  LoggedUserStatus,
  LoginModel,
  LoginResponse,
  LoginTermsConditionsResponse,
  RolesTypes,
  SystemsId,
} from "../../libs/models/login";
import { SYSTEM_ADMIN, VENDOR_ROUTES } from "../../libs/routes";
import { validateState } from "../../pages/Signin/login-form-container.policy";
import {
  changePassword,
  confirmTermsConditions,
  getTermsConditions,
  loginService,
  userIsRegistered,
} from "../../services/offerz-service";
import { ApplicationState, AsyncAppThunk } from "../application-state";
import { setAccountVerificationDialog } from "./account-verification";
import { nextStep, setActiveStep } from "./stepper";
import { showLoaderWhile } from "./ui";
import { setCookie } from "../../utils/cookies";
import { ChangePasswordModel } from "../../libs/models/change-password";

export interface LoginStore {
  email: string;
  password: string;
  ErrorCode?: ErrorsCodes;
  isValidating: boolean;
  userCulture: UserCulture;
  loggedUser: LoginResponse | null;
  userRole?: RolesTypes;
  loggedUserRegistrationStatus: LoggedUserStatus | null;
  isTermsConditionsDialogOpen: boolean;
  isPasswordExpired:boolean
  termsConditions: LoginTermsConditionsResponse | null;
}

const initialState: LoginStore = {
  isValidating: false,
  email: "",
  password: "",
  userCulture: UserCulture.English,
  loggedUser: null,
  loggedUserRegistrationStatus: null,
  isTermsConditionsDialogOpen: false,
  termsConditions: null,
  isPasswordExpired:false
};

const slice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setEmail: (state: LoginStore, action: PayloadAction<string>) => {
      state.email = action.payload;
      state.ErrorCode && (state.ErrorCode = undefined);
    },
    setPassword: (state: LoginStore, action: PayloadAction<string>) => {
      state.password = action.payload;
      state.ErrorCode && (state.ErrorCode = undefined);
    },
    setErrorCode: (
      state: LoginStore,
      action: PayloadAction<ErrorsCodes | undefined>
    ) => {
      state.ErrorCode = action.payload;
    },
    setIsValidating: (state: LoginStore, action: PayloadAction<boolean>) => {
      state.isValidating = action.payload;
    },
    setLoggedUser: (
      state: LoginStore,
      action: PayloadAction<LoginResponse>
    ) => {
      state.loggedUser = action.payload;
    },
    setUserRole: (state: LoginStore, action: PayloadAction<RolesTypes>) => {
      state.userRole = action.payload;
    },
    setLoggedUserStatus: (
      state: LoginStore,
      action: PayloadAction<LoggedUserStatus | null>
    ) => {
      state.loggedUserRegistrationStatus = action.payload;
    },
    setTermsConditions: (
      state: LoginStore,
      action: PayloadAction<LoginTermsConditionsResponse | null>
    ) => {
      state.termsConditions = action.payload;
    },
    setIsTermsConditionsDialogOpen: (
      state: LoginStore,
      action: PayloadAction<boolean>
    ) => {
      state.isTermsConditionsDialogOpen = action.payload;
    },
    setIsPasswordExpired: (
      state: LoginStore,
      action: PayloadAction<boolean>
    ) => {
      state.isPasswordExpired = action.payload;
    },
    clearLoginData: () => {
      return initialState;
    },
  },
});

export const {
  setEmail,
  setPassword,
  setErrorCode,
  setLoggedUser,
  setIsValidating,
  setUserRole,
  setLoggedUserStatus,
  setTermsConditions,
  setIsTermsConditionsDialogOpen,
  clearLoginData,
  setIsPasswordExpired
} = slice.actions;
export const reducer = slice.reducer;

export const setLoginLogic = createAsyncThunk(
  'login/setLoginLogic',
  async (loginResponse: Response<LoginResponse>|null, {dispatch, getState}) => {
    if (loginResponse && loginResponse.result != null) {
      let token: any = jwtDecode(loginResponse.result.token);
      dispatch(setLoggedUser(loginResponse.result));
      dispatch(setUserRole(token[ClaimType.Role]));
      dispatch(setIsValidating(false));
      dispatch(setIsPasswordExpired(loginResponse.result.isPasswordExpired));
      if ((getState()as ApplicationState).accountverification.dialogOpen) {
        dispatch(setAccountVerificationDialog(false));
        dispatch(nextStep());
        dispatch(setLoggedUserStatus(LoggedUserStatus.RegistrationNotComplete));
      }
    } else {
      if (loginResponse && loginResponse.errorCode === 7) {
        // TODO the code should be well named not just a number
        dispatch(setLoggedUserStatus(LoggedUserStatus.NotVerified));
        dispatch(setAccountVerificationDialog(true));
      }
      dispatch(setErrorCode(loginResponse!.errorCode as ErrorsCodes));
      loginResponse &&
        loginResponse.errorCode &&
        dispatch(setErrorCode(loginResponse.errorCode as ErrorsCodes));
    }
  },
)  

export const login = (): AsyncAppThunk => async (dispatch, store) => {
  dispatch(setIsValidating(true));

  var loginState = store().login;
  var validationState = validateState(loginState);
  if (Object.keys(validationState).length < 1) {
    const loginModel: LoginModel = {
      userName: loginState.email,
      password: loginState.password,
      SystemId: SystemsId.OfferzSystemId
    };

    const loginResponse = await loginService(loginModel);
    dispatch(setLoginLogic(loginResponse));
  }
};
export const UserRegistered = (): AsyncAppThunk => async (dispatch, store) => {
  const userName: string = store().login.loggedUser?.email ?? "";
  dispatch(
    showLoaderWhile(async () => {
      const vendorRegisterResponse = await userIsRegistered(userName);
      if (vendorRegisterResponse && vendorRegisterResponse.result != null) {
        dispatch(push(VENDOR_ROUTES.MAGAZINE));
        dispatch(setLoggedUserStatus(LoggedUserStatus.OfferzMember));
      } else {
        if (vendorRegisterResponse && vendorRegisterResponse.errorCode === 2) {
          // pending approval //TODO Align Codes with backend
          dispatch(push(VENDOR_ROUTES.REGISTRATION_STATUS));

          dispatch(setLoggedUserStatus(LoggedUserStatus.PendingApproval));
        } else if (
          vendorRegisterResponse &&
          vendorRegisterResponse.errorCode === 10
        ) {
          dispatch(push(VENDOR_ROUTES.REGISTRATION_STATUS));

          dispatch(setLoggedUserStatus(LoggedUserStatus.RequestRejected));
        } else {
          dispatch(setActiveStep(1));
          dispatch(push(ROUTES.SIGN_UP_VENDOR));
          dispatch(
            setLoggedUserStatus(LoggedUserStatus.RegistrationNotComplete)
          );
        }
      }
    })
  );
};

export const OnInitTermsConditions =
  (): AsyncAppThunk => async (dispatch, store) => {
    const termsConditions = await getTermsConditions();
    if (termsConditions !== null && termsConditions.result !== null) {
      dispatch(setTermsConditions(termsConditions.result));
      dispatch(setIsTermsConditionsDialogOpen(true));
    }
  };

export const OnConfirmTermsConditions =
  (): AsyncAppThunk => async (dispatch, store) => {
    const termsConditions = store().login.termsConditions;
    var response: Response<string> | null;
    if (termsConditions != null) {
      response = await confirmTermsConditions(termsConditions.id);
      console.log(response);
      if (response != null && response.result !== null) {
        dispatch(setIsTermsConditionsDialogOpen(false));
      }
    }
  };
export const isSystemOrVendorAdmin =
  (): AsyncAppThunk => async (dispatch, store) => {
    const userRole = store().login.userRole;
    if (userRole === RolesTypes.VendorAdmin) {
      dispatch(push(VENDOR_ROUTES.MAGAZINE));
    } else {
      dispatch(push(SYSTEM_ADMIN.VENDORS));
    }
  };

  export const onPasswordChangeSubmit =
  (passwordModel: ChangePasswordModel): AsyncAppThunk => async (dispatch, store) => {
   var result = await changePassword(passwordModel)
   if(result?.result && store().login.isPasswordExpired){
    dispatch(setIsPasswordExpired(false));
   }
  };
