import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ForgetPasswordChange,
  ForgetPasswordRequestOtp,
  ForgetPasswordVerifyOtp,
} from "libs/models/forget-password";
import { UserCulture } from "../../libs/enums";
import {
  forgetPasswordChangePasswordService,
  requestForgetPasswordOtpService,
  verifyForgetPasswordOtpService,
} from "../../services/offerz-service";
import { AsyncAppThunk } from "../application-state";
import { showLoaderWhile } from "./ui";
import { ReactNode } from "react";
import { push } from "@lagunovsky/redux-react-router";
import { ROUTES } from "libs";

export interface ForgetPasswordStore {
  email: string;
  forgetPasswordViewIndex: number;
  userCulture: UserCulture;
}

const initialState: ForgetPasswordStore = {
  userCulture: UserCulture.English,
  email: "",
  forgetPasswordViewIndex: 0,
};

const slice = createSlice({
  name: "forgetPassword",
  initialState,
  reducers: {
    setEmail: (state: ForgetPasswordStore, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    increaseForgetPasswordViewIndex: (state: ForgetPasswordStore) => {
      state.forgetPasswordViewIndex = (state.forgetPasswordViewIndex + 1) % 3;
    },
    resetForgetPasswordState: () => {
      return initialState;
    },
  },
});

export const {
  setEmail,
  increaseForgetPasswordViewIndex,
  resetForgetPasswordState,
} = slice.actions;
export const reducer = slice.reducer;

export const forgetPasswordOtpRequest =
  (): AsyncAppThunk => async (dispatch, store) => {
    var email = store().forgetPassword.email;
    var requestBody = { email: email } as ForgetPasswordRequestOtp;
    dispatch(
      showLoaderWhile(async () => {
        const response = await requestForgetPasswordOtpService(requestBody);
        if (response && response.result && response.result !== null) {
          dispatch(increaseForgetPasswordViewIndex());
        }
      })
    );
  };
export const forgetPasswordOtpRerequest =
  (): AsyncAppThunk => async (dispatch, store) => {
    var email = store().forgetPassword.email;
    var requestBody = { email: email } as ForgetPasswordRequestOtp;
    dispatch(
      showLoaderWhile(async () => {
        const response = await requestForgetPasswordOtpService(requestBody);
        if (response && response.result && response.result !== null) {
        }
      })
    );
  };
export const forgetPasswordOtpVerify =
  (otpCode: string): AsyncAppThunk =>
  async (dispatch, store) => {
    var email = store().forgetPassword.email;
    var requestBody = { email: email, otp: otpCode } as ForgetPasswordVerifyOtp;
    dispatch(
      showLoaderWhile(async () => {
        const response = await verifyForgetPasswordOtpService(requestBody);
        if (response && response.result && response.result !== null) {
          dispatch(increaseForgetPasswordViewIndex());
        }
      })
    );
  };
export const forgetPasswordChange =
  (newPassword: string): AsyncAppThunk =>
  async (dispatch, store) => {
    var email = store().forgetPassword.email;
    var requestBody = {
      username: email,
      newPassword: newPassword,
    } as ForgetPasswordChange;
    dispatch(
      showLoaderWhile(async () => {
        const response = await forgetPasswordChangePasswordService(requestBody);
        if (response && response.result && response.result !== null) {
          dispatch(push(ROUTES.LOGIN));
        }
      })
    );
  };
