import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import React from "react";
import { ISteps } from "../../libs/models/signup";
import { AsyncAppThunk } from "../application-state";
import { push } from "@lagunovsky/redux-react-router";
import { ROUTES } from "../../libs";
import { STEPPER_FORMS } from "../../libs/stepper-forms";
import { LoggedUserStatus } from "../../libs/models/login";

export interface StepperStore {
  steps: {
    content?: React.ReactNode;
    headline?: string;
    description?: string;
  }[];
  activeStep: number;
}

const initialState: StepperStore = {
  steps: [],
  activeStep: 0,
};

const slice = createSlice({
  name: "registration-stepper",
  initialState,
  reducers: {
    onInit: (state: StepperStore, action: PayloadAction<ISteps[]>) => {
      state.steps = action.payload;
    },
    nextStep: (state: StepperStore) => {
      const nextStep = state.activeStep + 1;
      if (nextStep < state.steps.length) {
        state.activeStep = nextStep;
      }
    },
    setActiveStep: (state: StepperStore, action: PayloadAction<number>) => {
      state.activeStep = action.payload;

    },
    clearStepperData: () => {
      return initialState

    },
  },
});


export const {
  nextStep,
  onInit,
  setActiveStep,
  clearStepperData

} = slice.actions;
export const reducer = slice.reducer;

export const initStepper = (): AsyncAppThunk => async (dispatch, store) => {
  const loggedUser = store().login.loggedUser;
  const currentStep = store().registrationstepper.activeStep;
  dispatch(onInit(STEPPER_FORMS.SIGNUP));
  if (loggedUser !== null && loggedUser.token !== null && currentStep == 0) {
    dispatch(push(ROUTES.LOGIN))
  }


};