import { Button } from "@ofrz/ui-package";
import { CategoryModel } from "../../libs/models/category";
import {
  RequestVendorProductPricing,
  VendorProductModel,
} from "../../libs/models/vendor-product";
import translate from "../../utils/translations";
import { COLORS } from "../../libs/colors";
import { EditIcon } from "../../static/images/svgs/edit-icon-svg";
import { DeleteIcon } from "../../static/images/svgs/delete-icon-svg";

export const vendorsProductRequestHeaders = [
  {
    title: translate("Product_Name_Text", "Product name"),
    dataIndex: "productName",
    key: "productName",
    isSortable: false,
    className: "!bg-black",
  },
  {
    title: translate("Category_Text", "Category"),
    dataIndex: "productCategory",
    key: "productCategory",
    isSortable: false,
  },
  {
    title: translate("Sub-Category_Text", "Sub-Category"),
    dataIndex: "productSubCategory",
    key: "productSubCategory",
    isSortable: false,
  },
  {
    title: translate("Brand_Text", "Brand"),
    dataIndex: "productBrand",
    key: "productBrand",
    isSortable: false,
  },
  {
    title: translate("Price_Text", "Price"),
    dataIndex: "price",
    key: "price",
    isSortable: false,
  },
  {
    title: translate("Action_Text", "Action"),
    dataIndex: "actions",
    key: "actions",
    isSortable: false,
  },
];
export const productPricingModelList = (
  productModel: VendorProductModel[],
  onEdit: (
    productPriceRequestModel: RequestVendorProductPricing,
    productPriceModel: VendorProductModel
  ) => void,
  onDelete: (id: string) => void
) => {
  const data = [];
  for (var i = 0; i < productModel.length; i++) {
    const request = productModel[i];
    const row: Record<string, any> = {
      id: request.id,
      productName: request.productName,
      productCategory: request.productCategory,
      productSubCategory: request.productSubCategory,
      productBrand:request.productBrand,
      price: request.price,
      actions: (
        <div>
          <Button
            className={`${COLORS.TEXT_FORM} !p-0 m-2`}
            onClick={() => {
              onEdit(
                {
                  price: request.price,
                  productId: request.productId,
                  id: request.id,
                } as RequestVendorProductPricing,
                request
              );
            }}
          >
            <EditIcon />
          </Button>
          <Button
            className={`${COLORS.TEXT_PRIMARY} !p-0 m-2`}
            onClick={() => onDelete(request.id!)}
          >
            <DeleteIcon />
          </Button>
        </div>
      ),
    };
    data.push(row);
  }
  return data;
};

export const settingLookupList = (lookup: any[]) => {
  const options = lookup.map((obj: any) => {
    return { value: obj.id, label: obj.name };
  });
  return options;
};
