import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AsyncAppThunk } from "../application-state";
import {
  TermsConditionsCreate,
  TermsConditionsModel,
  TermsConditionsType,
  UnconfirmedEntityTerms,
} from "../../libs/models/system-admin";
import {
  createTermsConditions,
  getTermsConditionsList,
  getTermsConditionsUnconfirmedList,
} from "../../services/offerz-service";
import { showLoaderWhile } from "./ui";

export interface TermsConditionsStore {
  content: string;
  createDialogOpen: boolean;
  type: TermsConditionsType;
  ConsumerList: TermsConditionsModel[];
  VendorList: TermsConditionsModel[];
  UnconfirmedList: UnconfirmedEntityTerms[];
}

const initialState: TermsConditionsStore = {
  content: "",
  createDialogOpen: false,
  type: TermsConditionsType.Consumer,
  ConsumerList: [],
  VendorList: [],
  UnconfirmedList: [],
};

const slice = createSlice({
  name: "terms-conditions",
  initialState,
  reducers: {
    setContent: (
      state: TermsConditionsStore,
      action: PayloadAction<string>
    ) => {
      state.content = action.payload;
    },
    setCreateDialogOpen: (
      state: TermsConditionsStore,
      action: PayloadAction<boolean>
    ) => {
      state.createDialogOpen = action.payload;
    },
    setType: (
      state: TermsConditionsStore,
      action: PayloadAction<TermsConditionsType>
    ) => {
      state.type = action.payload;
    },
    reset: (state: TermsConditionsStore) => {
      state.type = TermsConditionsType.Consumer;
      state.content = "";
    },
    setConsumerList: (
      state: TermsConditionsStore,
      action: PayloadAction<TermsConditionsModel[]>
    ) => {
      state.ConsumerList = action.payload;
    },
    setVendorList: (
      state: TermsConditionsStore,
      action: PayloadAction<TermsConditionsModel[]>
    ) => {
      state.VendorList = action.payload;
    },
    setUnconfirmedList: (
      state: TermsConditionsStore,
      action: PayloadAction<UnconfirmedEntityTerms[]>
    ) => {
      state.UnconfirmedList = action.payload;
    },
  },
});

export const {
  setContent,
  setType,
  reset,
  setConsumerList,
  setVendorList,
  setUnconfirmedList,
  setCreateDialogOpen,
} = slice.actions;
export const reducer = slice.reducer;

export const initTermsConditionsList =
  (): AsyncAppThunk => async (dispatch, store) => {
    var consumerResult = await getTermsConditionsList(
      TermsConditionsType.Consumer
    );
    if (consumerResult?.result) {
      dispatch(setConsumerList(consumerResult.result));
    }

    var vendorResult = await getTermsConditionsList(TermsConditionsType.Vendor);
    if (vendorResult?.result) {
      dispatch(setVendorList(vendorResult.result));
    }
  };

export const fetchTermsConditionsUnconfirmedList =
  (): AsyncAppThunk => async (dispatch, store) => {
    dispatch(
      showLoaderWhile(async () => {
        var result = await getTermsConditionsUnconfirmedList(
          store().termsconditions.type
        );
        if (result?.result) {
          dispatch(setUnconfirmedList(result.result));
        }
      })
    );
  };

export const onInit =
  (type?: TermsConditionsType): AsyncAppThunk =>
  async (dispatch, store) => {
    if (type) {
      dispatch(setType(type));
    } else {
      type = store().termsconditions.type;
    }

    var list = [];
    if (type === TermsConditionsType.Consumer) {
      list = store().termsconditions.ConsumerList;
    } else {
      list = store().termsconditions.VendorList;
    }
    const lastCreated = list.reduce(
      (max, obj) => (obj.version > max.version ? obj : max),
      list[0]
    );
    if (lastCreated && lastCreated.content) {
      dispatch(setContent(lastCreated.content));
    } else {
      dispatch(setContent(""));
    }
  };

export const onCreate = (): AsyncAppThunk => async (dispatch, store) => {
  const model: TermsConditionsCreate = {
    content: store().termsconditions.content,
    type: store().termsconditions.type,
  };
  const result = await createTermsConditions(model);

  if (result?.result) {
    dispatch(initTermsConditionsList());
    dispatch(setCreateDialogOpen(false));
    dispatch(reset());
  }
};
