import { connect } from "react-redux";
import { AddTermsConditionsPopupProps } from "./add-terms-conditions-popup.container.props";
import { AppDispatch, ApplicationState } from "../../../../../store";
import { TermsConditionsType } from "../../../../../libs/models/system-admin";
import {
  onInit,
  setContent,
} from "../../../../../store/slices/terms-conditions";
import translate from "../../../../../utils/translations";
import { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

const AddTermsConditionsPopUp = (props: AddTermsConditionsPopupProps) => {
  const [isEditorLoading, setIsEditorLoading] = useState(true);
  useEffect(() => {
    props.onInit();
  }, []);

  const isActive = (selectedType: TermsConditionsType) => {
    if (props.selectedType === selectedType) {
      return true;
    } else return false;
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col">
        <legend className="text-base mb-2">
          {translate("Select_User_Text", "Select User")}&nbsp;:
        </legend>
        <div className="flex flex-row space-x-2">
          <div className="flex items-center ">
            <label className="flex items-center text-sm font-medium space-x-2 cursor-pointer">
              <input
                type="radio"
                className="hidden"
                value={TermsConditionsType.Consumer}
                checked={isActive(TermsConditionsType.Consumer)}
                onChange={(e: any) => props.onInit(+e.target.value)}
              />
              <span
                className={`inline-block w-5 h-5 bg-white border ${
                  isActive(TermsConditionsType.Consumer) && "border-[#518c7d]"
                } rounded-full relative`}>
                {isActive(TermsConditionsType.Consumer) && (
                  <span className="block w-2 h-2 bg-[#518c7d] rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></span>
                )}
              </span>
              <span>{translate("Consumer_Text", "Consumer")}</span>
            </label>
          </div>
          <div className="flex items-center ">
            <label className="flex items-center text-sm font-medium space-x-2 cursor-pointer">
              <input
                type="radio"
                className="hidden"
                value={TermsConditionsType.Vendor}
                checked={isActive(TermsConditionsType.Vendor)}
                onChange={(e: any) => props.onInit(+e.target.value)}
              />
              <span
                className={`inline-block w-5 h-5 bg-white border ${
                  isActive(TermsConditionsType.Vendor) && "border-[#518c7d]"
                } rounded-full relative`}>
                {isActive(TermsConditionsType.Vendor) && (
                  <span className="block w-2 h-2 bg-[#518c7d] rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></span>
                )}
              </span>
              <span>{translate("Vendor_Text", "Vendor")}</span>
            </label>
          </div>
        </div>
      </div>

      <div className={`${isEditorLoading && "hidden"}`}>
        <Editor
          apiKey={`${process.env.REACT_APP_TINYMCE_APIKEY}`}
          onInit={() => setIsEditorLoading(false)}
          init={{
            height: 300,
            menubar: false,
            plugins: "textcolor lists advlist wordcount",
            toolbar:
              "fontsize | forecolor | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | numlist bullist indent outdent",
            statusbar: false,
          }}
          onEditorChange={(content: string) => {
            props.onContentChange(content);
          }}
          value={props.htmlContent}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  selectedType: state.termsconditions.type,
  htmlContent: state.termsconditions.content
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onInit: (type?: TermsConditionsType) => {
    dispatch(onInit(type));
  },
  onContentChange: (value: string) => {
    dispatch(setContent(value));
  },
});

const AddTermsConditionsPopUpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTermsConditionsPopUp);

export default AddTermsConditionsPopUpContainer;
