import { ChangeForgetPasswordViewContainer } from "pages/ForgetPassword/views/ChangeForgetPassword";
import { SubmitForgetPasswordViewContainer } from "pages/ForgetPassword/views/RequestForgetPasswordView";
import { VerifyOtpPasswordViewContainer } from "pages/ForgetPassword/views/VerifyOtpPasswordView";
import { ReactNode } from "react";

export const ForgetPasswordViewsList: ReactNode[] = [
  <SubmitForgetPasswordViewContainer />,
  <VerifyOtpPasswordViewContainer />,
  <ChangeForgetPasswordViewContainer />,
];
