import { AutoCompleteOption } from "../auto-complete/auto-complete.props";
import ErrorText from "../error-text";
import { SelectOptionProps } from "./select-option.props";

const SelectOptionComponent = (props: SelectOptionProps) => {
  return (
    <div className="flex flex-col box-border">
      <label className="pb-1">{props.title}</label>
      <div className={`flex item-center border-solid border-2 
        ${props.error && props.error !== null && props.error !== "" ? 'border-red-500' : 'border-gray-300'}
          rounded-lg h-12`}>
        <select
          disabled={props.disabled}
          name={props.name}
          defaultValue={props.defaultValue}
          value={props.optionsList.length === 0 ? undefined : props.value}
          className={`flex-1 focus:outline-none rounded-lg p-2 ${props.className}`}
          onChange={props.onChange}

        >
          <option value="" disabled selected>{props.placeholder ?? "Select your option"}</option>
          {props.optionsList.map((o: AutoCompleteOption) => {
            return <option key={o.value} value={o.value}>{o.label}</option>;
          })}
        </select>
      </div>
      {props.error && props.error !== null && props.error !== "" && <ErrorText text={props.error} />}

    </div>
  );
};

export { SelectOptionComponent };
