import { ReactElement } from "react";
import PendingRequestImg from "../static/images/svgs/pending-request-svg";
import RejectedRequestImg from "../static/images/svgs/rejected-request-svg";
import SuccessfulySumbittedImg from "../static/images/svgs/successfuly-sumbitted-img";
import translate from "../utils/translations";

interface item { img: ReactElement, content: string, headline: string }
const STATUS_PAGE_FORM: {
    RequestSubmitted: { img: ReactElement, content: string, headline: string },
    RequestPending: { img: ReactElement, content: string, headline: string },
    RequestRejected: { img: ReactElement, content: string, headline: string },
    RequestApproved: { img: ReactElement, content: string, headline: string }
} = {
    RequestSubmitted:
    {
        img: <SuccessfulySumbittedImg />,
        headline: translate(
            "Data_Submited_Text", "Data Submitted Successfully"
        ),
        content: translate(
            "Vendor_Request_Pending_Description_Text",
            "Our team will review your application. You will be notified on the decision by email, usually in 2/3 working days."
        ),
    },
    RequestPending:
    {
        img: <PendingRequestImg />,
        headline: translate(
            "Application_Is_Pending",
            "Your application is pending"
        ),
        content: translate(
            "Vendor_Request_Pending_Description_Text",
            "Our team will review your application. You will be notified on the decision by email, usually in 2/3 working days."
        ),
    },
    RequestRejected:
    {
        img: <RejectedRequestImg />,
        headline: translate(
            "Application_Is_Rejected",
            "Your application is rejected"
        ),
        content: translate(
            "Application_Rejected_Description_Text",
            "We are sorry but your application is rejected, you can contact with our customer team"
        ),
    },
    RequestApproved:
    {
        img: <SuccessfulySumbittedImg />,
        headline: translate(
            "Application_Is_Acceppted",
            "Your application is Acceppted"
        ),
        content: translate(
            "Application_Approved_Description_Text",
            "Now, you can login and upload all your offers and magazine"
        ),
    },

};
export { STATUS_PAGE_FORM };
