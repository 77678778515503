import { UserDetails } from "./vendor-register-request";

interface VendorsRequestsResponse {
  id: string;
  vendorName: string;
  vendorPhoneNumber: string;
  vendorMobileNumber: string;
  vendorWebsite: string;
  vendorEmail: string;
  taxRegistrationFileld: string;
  vendorBranches: VendorBranch[];
  requestedUser: RequestedUser;
  status: RequestStatus;
}

interface VendorsDetailsResponse {
  id: string;
  vendorName: string;
  vendorMobileNumber: string;
  accountManager: string;
  isActive: boolean;
  approvedAt: Date;
  profilePictureFileId: string;
}

interface VendorDetailsResponse {
  vendorName: string;
  vendorPhoneNumber: string;
  vendorMobileNumber: string;
  vendorWebsite: string;
  vendorEmail: string;
  taxRegistrationFileld: string;
  vendorBranches: VendorBranch[];
  vendorManager: UserDetails;
  isActive: boolean;
}

interface TermsConditionsCreate {
  content: string;
  type: TermsConditionsType;
}

interface VendorBranch {
  country: string;
  city: string;
  area: string;
  street: string;
  buildingNumber: string;
}

interface RequestedUser {
  id: string;
  userName: string;
  firstName: string;
  lastName: string;
}

const enum RequestStatus {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
}

const enum SystemVendorsView {
  Requests = "requests",
  List = "list",
}

interface TermsConditionsModel {
  id: string;
  content: string;
  type: string;
  version: number;
  createdAt: Date;
  createdBy: string;
}

interface UnconfirmedEntityTerms {
  id: string;
  name: string;
  type: string;
  version: number;
  email: string;
}

enum TermsConditionsType {
  Consumer = 1,
  Vendor = 2,
}

export { SystemVendorsView, RequestStatus, TermsConditionsType };
export type {
  VendorsRequestsResponse,
  VendorDetailsResponse,
  VendorsDetailsResponse,
  TermsConditionsModel,
  TermsConditionsCreate,
  UnconfirmedEntityTerms,
  VendorBranch,
};
