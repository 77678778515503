import { TermsConditionsModel } from "../../../../../libs/models/system-admin";

const checkIfContentChanged = (
  content: string,
  list: TermsConditionsModel[]
) => {
  const lastCreated = list.reduce(
    (max, obj) => (obj.version > max.version ? obj : max),
    list[0]
  );

    if (
      content &&
      content.replace(/;/g, "") == lastCreated.content.replace(/;/g, "")
    ) {
      return false;
    }
     
  return true;
};

export { checkIfContentChanged };
