import { useNavigate } from "react-router";

export function CrosspayLogo({ width = "87", height = "46" }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/"); // Navigate to the home page
  };
  return (
    <svg
      version="1.1"
      viewBox="0 0 2048 1238"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
      className="cursor-pointer"
    >
      <path
        transform="translate(1638)"
        d="m0 0h5l11 27 18 47 15 39 22 57 20 52 18 47 20 52 22 58 13 35 6 16-1 3-31 6-118 21-305 54-11 1 2-6 11-20 28-48 15-26 14-24 12-22 3-5-1-4-13-10-20-13-28-17-26-15-26-14-28-14-27-12-30-12-28-10-34-10-30-7-32-6-45-5-21-1h-32l-28 2-24 3-24 4-26 6-29 9-27 10-28 12-10 3 2-6 13-18 14-17 9-11 11-13 9-10 7-8 20-20 8-7 14-12 17-13 17-12 14-9 24-14 26-13 30-12 31-10 33-8 37-6 34-3h58l44 4 34 5 34 7 36 9 36 11 35 12 28 11 33 15 40 20 3 1 28-50 16-29 13-23 16-27z"
        fill="#528D7F"
      />
      <path
        transform="translate(619,22)"
        d="m0 0 4 1-11 14-13 16-11 14-13 16-22 28-13 16-22 28-5 7 6 7 19 19 11 9 11 10 10 8 16 13 16 12 19 14 15 10 22 14 15 9 21 12 23 12 22 11 33 14 34 12 31 9 31 7 32 5 30 3h52l33-3 36-6 29-7 26-8 5-1-1 4-12 14-24 24-8 7-12 11-10 8-14 11-18 13-15 10-22 13-23 12-17 8-26 10-25 8-28 7-36 6-30 3-16 1h-61l-39-6-44-9-30-8-36-12-29-11-30-13-29-14-26-13-23-13-23-14-27-18-17-12-9-7-3-1-2 4-8 11-16 21-18 24-16 21-15 20-13 17-8 11-3-1-12-54-14-61-25-110-28-124-19-87-3-14v-6l28-2 64-2 21-1 78-2 21-1 81-2 19-1 77-2 19-1 41-1z"
        fill="#528D7F"
      />
      <path
        transform="translate(266,830)"
        d="m0 0h140l13 1 12 4 14 8 12 11 7 9 8 16 4 15 1 6v30l-3 15-4 11-5 10-9 11-8 7-15 10-3 3 49 98 1 6-3 1h-37l-25-1-14-29-13-28-18-38-1-5h-45v100l-1 1h-57zm58 49v73h23l32-1 21-2 8-4 5-5 2-6 1-9v-16l-2-15-4-6-5-4-11-3-32-2z"
        fill="#000101"
      />
      <path
        transform="translate(633,824)"
        d="m0 0h13l21 2 19 5 14 6 11 7 10 8 10 10 9 13 8 15 7 21 5 27v51l-4 25-5 18-7 16-7 11-8 10-13 13-14 10-16 7-15 4-5 1-14 1h-23l-16-1-15-4-16-7-11-7-13-11-10-11-10-16-8-20-6-23-3-23v-33l3-23 7-26 8-18 7-12 9-12 9-9 13-9 16-8 16-5 10-2zm4 52-17 4-11 6-12 12-8 14-3 10-2 30v15l2 38 2 10 5 12 7 10 8 7 10 5 7 2 9 1h20l12-3 10-7 10-10 8-16 3-12 2-20 1-21-2-29-5-22-7-14-9-10-10-6-11-4-11-2z"
        fill="#010202"
      />
      <path
        transform="translate(1664,827)"
        d="m0 0h37l3 8 17 52 16 50 23 70 14 43 12 36 5 14v2l-3 1-18-1 11 27v3h2l2-9 8-20 1-1h11v42h-7l-1-4v-28l-12 31-5 1-5-10-8-20-1 30h-6l-1-1v-41h-28l-3-1-9-27-11-38h-90l-12 41-7 21-2 3-6 1-34 1-15-1-3-1 1-9 10-31 18-54 14-43 45-135 1-1zm-2 56-3 8-14 46-13 46v2l9 1h53l-3-12-21-72-5-17-1-2z"
        fill="#010101"
      />
      <path
        transform="translate(1302,830)"
        d="m0 0h140l13 1 14 5 13 8 10 9 7 8 8 15 5 18 1 25-1 18-4 16-8 16-8 10-9 9-14 8-13 4-23 3-57 2-13 1v95l-1 1h-60l-1-9v-259zm61 49-1 4v68l1 1h21l48-2 8-1 5-4 6-10 2-7 1-17-2-13-4-8-6-5-7-3-18-2-19-1z"
        fill="#010101"
      />
      <path
        transform="translate(894,824)"
        d="m0 0h24l18 2 19 5 18 8 17 10 8 7 2 5-5 9-7 7-1 2h-2l-2 4-15 14-4 4-23-15-13-6-12-2h-19l-12 2-10 4-5 4-5 10v11l4 9 5 5 8 4 13 4 40 8 21 6 12 5 11 7 10 9 7 10 5 11 3 12 2 12v14l-3 19-5 13-7 12-11 12-14 10-19 9-19 4h-62l-16-5-21-10-17-11-11-9-3-4 1-5 12-14 20-20 1-2 5 1 10 8 11 7 15 7 14 4 4 1 17 1 11-1 11-4 9-6 4-7 2-6v-14l-4-10-5-5-10-5-13-3-21-3-20-4-20-6-16-8-8-6-5-4-9-12-6-14-3-12-1-8v-15l2-12 6-16 8-13 9-10 11-9 17-8 17-5 10-2z"
        fill="#010201"
      />
      <path
        transform="translate(1140,824)"
        d="m0 0h24l18 2 21 6 16 7 15 9 10 8 1 7-6 9-29 29-20-13-14-7-6-2-8-1h-19l-12 2-10 4-6 5-4 8v12l4 10 6 5 13 5 21 5 25 5 19 5 16 7 11 7 10 9 7 11 5 12 4 23v10l-3 21-6 15-7 11-8 9-13 10-17 9-13 4-11 2h-63l-16-5-20-10-17-11-10-8-4-4v-5l11-13 16-16 7-8 5 1 11 9 14 8 11 5 14 4 4 1 17 1 11-1 11-4 9-6 4-7 2-6v-13l-4-11-2-4-4-2-9-4-13-3-21-3-20-4-20-6-16-8-8-6-6-5-8-11-5-11-4-15-1-6v-18l3-15 7-16 8-12 11-11 11-7 13-6 17-5 9-2z"
        fill="#010201"
      />
      <path
        transform="translate(106,825)"
        d="m0 0h39l16 3 12 5 14 8 10 8 9 9 9 13 8 14-1 4-50 25-10-18-8-9-14-7-12-2h-15l-14 3-11 6-12 12-5 8-4 13-2 28v32l2 28 4 15 6 11 9 8 12 6 7 2 9 1h18l11-2 9-5 10-9 7-10 5-9 5-1 21 11 19 12 4 4-2 6-8 13-11 14-8 8-15 10-14 6-19 5-15 1h-21l-17-1-16-4-13-6-11-6-16-13-9-10-10-15-8-17-6-20-4-19v-54l7-29 5-15 11-21 8-11 12-13 12-10 17-9 13-4z"
        fill="#010201"
      />
      <path
        transform="translate(1826,827)"
        d="m0 0h42l5 8 17 32 12 22 14 26 10 18 2 1 2-5 13-22 13-25 12-22 13-23 5-7 4-2 8-1h36l13 1 1 1v6l-7 10-15 26-16 28-14 25-13 23-15 27-11 19-1 4v105h-59v-102l-3-8-11-19-13-23-14-25-12-21-15-27-16-29-8-16v-4z"
        fill="#010202"
      />
      <path
        transform="translate(784,1148)"
        d="m0 0h15l5 2 8 11 12 21 9 13 2-21 1-26h17v85l-18-2-7-7-9-15-12-21-5-9v52l-1 1h-17z"
        fill="#010202"
      />
      <path
        transform="translate(931,1148)"
        d="m0 0h13l8 2 10 6 6 8 4 10 2 10v10l-3 15-6 12-8 7-7 3-5 1h-18l-8-3-8-6-7-11-4-12v-20l4-13 5-9 7-6 8-3zm0 16-6 4-3 5-2 9v16l2 9 4 6 5 3 7 1 9-3 5-6 2-10v-17l-3-10-4-5-4-2z"
        fill="#070909"
      />
      <path
        transform="translate(1263,1147)"
        d="m0 0h15l13 4 8 6 3 6-2 5-8 5-5-1-8-7-11-1-8 3-4 5-3 14v9l3 13 5 6 7 2 10-1 6-4 2-3-1-5-5-2-7-2v-12l1-1h30l1 1-1 44-3 1h-8l-3-3-2-3-9 5-5 1h-13l-9-3-8-6-6-8-5-12-1-7v-10l2-10 5-12 9-10 8-5z"
        fill="#070808"
      />
      <path
        transform="translate(1143,1148)"
        d="m0 0h20l9 3 6 5 6 10 4 11 1 13-2 15-7 14-10 10-8 3h-19l-9-2-8-6-7-11-2-5v-37l4-9 5-6 11-7zm6 15-7 3-5 6-3 14v8l2 12 5 8 8 3 9-1 8-7 3-8 1-13-1-11-4-8-5-4-7-2z"
        fill="#050706"
      />
      <path
        transform="translate(665,1148)"
        d="m0 0h17l1 1v34h31v-34l1-1h16l1 1v83h-17l-1-1v-34l-31 1v34h-18z"
        fill="#030505"
      />
      <path
        transform="translate(449,1148)"
        d="m0 0h57v13l-1 1-13 1h-26l3 17 23 2 8 2v13h-34l2 18 23 1 14 2 1 1v12l-2 1h-56v-83z"
        fill="#040707"
      />
      <path
        transform="translate(1438,1148)"
        d="m0 0h57l1 3-1 11-13 1h-20l-7-1 3 18 24 2 7 2 1 2-1 11h-34l2 13v6h23l14 2 1 1v12l-2 1h-55l-1-50v-30z"
        fill="#040605"
      />
      <path
        transform="translate(1564,1147)"
        d="m0 0h17l12 3 9 6-1 5-8 7-9-1-7-2h-10l-5 3v5l5 4 26 9 5 3 4 7v22l-6 8-12 5-7 1h-16l-11-3-8-6v-5l4-5 4-3 5 1 9 4 4 1h7l7-3 1-2v-7l-4-4-22-6-9-6-5-6-1-3v-11l4-10 6-7 8-3z"
        fill="#050706"
      />
      <path
        transform="translate(581,1147)"
        d="m0 0h15l11 3 8 7 3 4v5l-6 4-9 1-9-6h-13l-6 4-3 8-1 5v14l3 11 4 6 4 2h11l9-6 2-2h6l8 5 1 4-7 8-10 6-7 2h-18l-9-3-7-6-7-14-2-11v-15l2-12 6-12 4-5 8-5z"
        fill="#060707"
      />
      <path
        transform="translate(1355,1148)"
        d="m0 0h28l8 1v12l-8 3-1 2-1 9v31l2 10 8 3v12l-2 1h-37l-1-1v-12l9-2 1-11v-31l-1-9-1-2-8-3v-12z"
        fill="#070908"
      />
      <path
        transform="translate(342,1148)"
        d="m0 0h63v11l-1 3-21 1-1 68-1 1h-16l-1-1v-68l-22-1-1-2v-11z"
        fill="#070B0A"
      />
      <path
        transform="translate(1021,1148)"
        d="m0 0h17l1 1 1 24 1 42 27 1 4 2 1 2v11l-2 1h-50z"
        fill="#030404"
      />
      <path
        transform="translate(1759,1102)"
        d="m0 0h11l11 27v3h2l2-9 8-20 1-1h11v42h-7l-1-4v-28l-12 31-5 1-5-10-8-20-1 30h-6l-1-1z"
        fill="#FBFBFB"
      />
      <path
        transform="translate(1631,1100)"
        d="m0 0h12l9 6 3 5 1 10-2 6-6 7-9 3-9-1-5-3-5-6-2-6v-7l4-8 6-5zm2 4-7 4-2 4v11l3 5 5 3h9l5-3 4-5 1-8-2-3-2 10-4 3h-9l-4-4-1-8 4-5 8-1 4-1-4-2z"
        fill="#F9F9F9"
      />
      <path
        transform="translate(1877,1099)"
        d="m0 0h6l1 1v44h-6l-2-3-4 3-7 1-7-3-5-6-1-3v-11l3-6 5-4 3-1h7l6 3zm-13 18-4 4-1 2v8l3 6 3 2 7-1 5-6v-8l-4-6-2-1z"
        fill="#FBFBFB"
      />
      <path
        transform="translate(1628,1089)"
        d="m0 0h14l10 4 8 7 5 7 2 7v12l-3 9-4 6-9 8-7 3-5 1h-10l-10-3-9-6-6-9-3-7v-15l5-10 6-7 11-6zm0 3-9 3-8 6-5 9-1 3v16l4 8 4 5 10 6 8 2h7l11-4 8-6 6-10 1-5-1-14-6-10-7-6-8-3z"
        fill="#F1F1F1"
      />
      <path
        transform="translate(1630,1094)"
        d="m0 0h13l10 5 6 7 2 5v17l-5 9-8 6-6 2h-13l-7-3-7-6-5-10v-14l4-8 8-7zm4 3-10 3-6 5-4 10v8l3 8 8 7 5 2h14l7-4 5-6 2-6v-9l-2-7-4-5-8-5z"
        fill="#F3F3F3"
      />
      <path
        transform="translate(1821,1111)"
        d="m0 0 4 1 3 1 4-2h7l6 3 3 5 1 14v10l-1 1h-6l-1-1-1-22-2-3h-7l-3 3-1 22-1 1h-6l-1-8v-15l1-9z"
        fill="#F9F9F9"
      />
      <path
        transform="translate(1735,1111)"
        d="m0 0h7l7 3 3 3 1 5-4 1-5-5-8-1h-2v5l16 5 4 4v6l-3 5-7 3h-8l-5-2-4-4-1-5 2-2 8 6h9l1-5-15-4-4-3v-9l4-4z"
        fill="#FAFAFA"
      />
      <path
        transform="translate(1696,1111)"
        d="m0 0 5 2h3l4-2 10 1 5 6 1 5v21h-7l-1-20-2-6h-8l-3 5-1 21h-7v-32z"
        fill="#FAFAFA"
      />
      <path
        transform="translate(1809,1110)"
        d="m0 0h6l1 1v33h-7l-1-1v-32z"
        fill="#FCFCFC"
      />
      <path
        transform="translate(690,298)"
        d="m0 0 6 2 19 10 24 12 33 14 34 12 31 9 4 2-11-1-18-5-17-6-24-9-20-8-15-7-23-11-22-12z"
        fill="#B0AFAF"
      />
      <path transform="translate(1684,1111)" d="m0 0h7v33h-7z" fill="#FEFEFE" />
      <path
        transform="translate(1935,1128)"
        d="m0 0h13l3 2 1 3v10l-5-1v-11h-4v11l-5 1v-12h-4v9l-2 4-3-1 1-13z"
        fill="#E8E8E8"
      />
      <path
        transform="translate(66,916)"
        d="m0 0 1 4-2 28v32l2 28 2 11-2-2-3-8-1-10v-68l2-12z"
        fill="#9EADAA"
      />
      <path
        transform="translate(1918,1128)"
        d="m0 0 6 1 3 4v7l-4 4h-7l-3-2-1-9 3-4zm1 3-3 4 1 5 3 1 3-2-1-7z"
        fill="#F3F3F3"
      />
      <path
        transform="translate(1901,1128)"
        d="m0 0h6l3 4v8l-3 4h-7l-3-3-1-5 2-6zm1 3-1 1v9l5-1 2-5-2-4z"
        fill="#DCE1E0"
      />
      <path
        transform="translate(1291,279)"
        d="m0 0 5 1 19 9 19 10 10 6 2 3-5-2-28-15-22-11z"
        fill="#C6C5C5"
      />
      <path
        transform="translate(835,1174)"
        d="m0 0h1v29l-2 1-5-11 1-2 3 4z"
        fill="#809993"
      />
      <path
        transform="translate(1946,1129)"
        d="m0 0 5 1 1 3v10l-5-1z"
        fill="#FCFCFC"
      />
      <path
        transform="translate(892,876)"
        d="m0 0h21v1l-24 2-12 4-5 3 2-4 7-4z"
        fill="#73988F"
      />
      <path
        transform="translate(1931,1129)"
        d="m0 0h6l-2 2-2 12-2 1-2-1 1-13z"
        fill="#F5F5F5"
      />
      <path
        transform="translate(1138,876)"
        d="m0 0h20v1l-23 2-12 4-5 2 5-5 8-3z"
        fill="#7A9A93"
      />
      <path
        transform="translate(1684,1099)"
        d="m0 0h6l2 2-1 5-1 1h-5l-2-3z"
        fill="#F9F9F9"
      />
      <path
        transform="translate(1811,1099)"
        d="m0 0 5 1 1 4-3 3h-5l-1-1v-6z"
        fill="#F6F6F6"
      />
      <path
        transform="translate(1346,308)"
        d="m0 0 6 1 21 13 4 4-5-2-26-15z"
        fill="#BFBEBE"
      />
      <path
        transform="translate(803,348)"
        d="m0 0 10 2 6 2v2l-7-1-9-3z"
        fill="#ACABAB"
      />
      <path
        transform="translate(1934,923)"
        d="m0 0 1 2-6 12-3-1v-2h2l2-5z"
        fill="#9AADA9"
      />
      <path
        transform="translate(834,1189)"
        d="m0 0h1v11h-3l-3-9 4 4z"
        fill="#E0E6E5"
      />
      <path
        transform="translate(617,880)"
        d="m0 0 3 1-11 6-5 4 1-4 10-6z"
        fill="#7A9C94"
      />
      <path
        transform="translate(64 1e3)"
        d="m0 0 2 1 3 12-1 3-4-10z"
        fill="#789991"
      />
      <path
        transform="translate(1560,1168)"
        d="m0 0h1l2 6 9 6-7-1-5-5z"
        fill="#A9B8B4"
      />
      <path
        transform="translate(1891,1139)"
        d="m0 0h3l-1 5-4-1v-3z"
        fill="#E6E6E6"
      />
      <path
        transform="translate(1577,1168)"
        d="m0 0 1 2 4 2-5-1-2-2z"
        fill="#528D7F"
      />
      <path transform="translate(1582,1172)" d="m0 0 3 1z" fill="#528D7F" />
      <path transform="translate(1572,1167)" d="m0 0 2 1z" fill="#528D7F" />
      <path transform="translate(1919,1135)" d="m0 0" fill="#528D7F" />
      <path transform="translate(1574,1168)" d="m0 0" fill="#528D7F" />
      <path transform="translate(1571,1166)" d="m0 0" fill="#528D7F" />
    </svg>
  );
}
