import { Button, FileUploader, TextInput } from "@ofrz/ui-package";
import { FilesTypes } from "@ofrz/ui-package/dist/components/FileUploader/types";
import { AutoCompleteComponent } from "components/auto-complete";
import { AutoCompleteOption } from "components/auto-complete/auto-complete.props";
import Tag from "components/tag/tag";
import { DealType } from "libs/models/deals";
import React from "react";
import { connect } from "react-redux";
import {
  addOrUpdateDeal,
  addToSelectedForDealList,
  autoVendorProductTypingComplete,
  SelectedForDeal,
  setClearDealItem,
  setDealItemProp,
  setVendorProductListLookUp,
  UploadDealFile,
} from "store/slices/deals";
import { AppDispatch, ApplicationState } from "../../../../store";
import { setCloseDialog } from "../../../../store/slices/modal-popup";
import translate from "../../../../utils/translations";
import { validateEditDealItemState } from "./edit-deal-popup.policy";
import { EditDealPopupProps } from "./edit-deal-popup.props";
import { renderAutoCompleteVendorProductOptions } from "./edit-deal-popup.util";

const EditDealPopUp = (props: EditDealPopupProps) => {
  const onPropChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    props.onPropChange(name, value);
  };

  const onChangeFile = (file?: File) => {
    props.onChangeFile(file);
  };

  const editDeal = () => {
    props.editDeal();
  };

  const onCloseDialog = () => {
    props.setCloseDialog();
  };
  const onProductValueChange = (searchText: string) => {
    props.autoVendorProductTypingComplete(searchText);
  }
  const onSelectProduct = (option: AutoCompleteOption, propKey?: string) => {
    props.onPropChange(propKey ?? "", option.value ?? "");
    props.addToSelectedForDealList({ id: option.value, name: option.label } as SelectedForDeal);
    props.clearVendorProductLookup();

  }
  return (
    <div className="flex flex-col">
      <div className="mt-2">
        <TextInput
          title={translate("Deal_Name", "Deal name")}
          className="w-full"
          placeholder={translate("Type_Deal_Name_Text", "Type deal name")}
          type="text"
          name="name"
          value={props.dealItem.name}
          onChange={onPropChange}
          error={props.validationState.name}
        />
      </div>
      <div className="mt-2">
        <TextInput
          title={translate("Deal_TitleEn", "Deal title in English")}
          className="w-full"
          placeholder={translate(
            "Type_Deal_Name_Text",
            "Type deal title in English"
          )}
          type="text"
          name="titleEn"
          value={props.dealItem.titleEn}
          onChange={onPropChange}
          error={props.validationState.titleEn}
        />
      </div>
      <div className="mt-2">
        <TextInput
          title={translate("Deal_Title", "Deal title in Arabic")}
          className="w-full"
          placeholder={translate(
            "Type_Deal_Name_Text",
            "Type deal title in Arabic"
          )}
          type="text"
          name="title"
          value={props.dealItem.title}
          onChange={onPropChange}
          error={props.validationState.title}
        />
      </div>
      <div className="mt-2">
        <TextInput
          title={translate("Deal_Desc", "Deal Description in Arabic")}
          className="w-full"
          placeholder={translate(
            "Type_Deal_Name_Text",
            "Type deal description in Arabic"
          )}
          type="text"
          name="description"
          value={props.dealItem.description}
          onChange={onPropChange}
          error={props.validationState.description}
        />
      </div>
      <div className="mt-2">
        <TextInput
          title={translate("Deal_DescEn", "Deal Description in English")}
          className="w-full"
          placeholder={translate(
            "Type_Deal_Name_Text",
            "Type deal description in English"
          )}
          type="text"
          name="descriptionEn"
          value={props.dealItem.descriptionEn}
          onChange={onPropChange}
          error={props.validationState.descriptionEn}
        />
      </div>
      <div className="mt-2">
        <label>{translate("Deal_Type_Text", "Deal type")}</label>
        <div className="space-y-1">
          <div key={DealType.Percentage.toString()} className="flex items-center">
            <input
              type="radio"
              name="option"
              value={DealType.Percentage}
              checked={props.dealItem.dealType === DealType.Percentage}
              onChange={() =>
                props.onPropChange("dealType", DealType.Percentage)
              }
              className="mr-1 rounded-full border-gray-300 focus:ring-blue-500"
            />
            <label
              htmlFor={DealType.Percentage.toString()}
              className="text-gray-700"
              onClick={() =>
                props.onPropChange("dealType", DealType.Percentage)}
            >
              {translate("Deal_Type_Percentage_Text", "Percentage")}
            </label>
          </div>
          <div key={DealType.Discount.toString()} className="flex items-center">
            <input
              type="radio"
              name="option"
              value={DealType.Discount}
              checked={props.dealItem.dealType === DealType.Discount}
              onChange={() => props.onPropChange("dealType", DealType.Discount)}
              className="mr-1 rounded-full border-gray-300 focus:ring-blue-500"
            />
            <label
              htmlFor={DealType.Discount.toString()}
              className="text-gray-700"
              onClick={() => props.onPropChange("dealType", DealType.Discount)}
            >
              {translate("Deal_Type_Discount_Text", "Discount")}
            </label>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <TextInput
          title={translate("Amount_Text", "Amount")}
          className="w-full"
          placeholder={translate("Type_Deal_Name_Text", "Type deal amount")}
          type="text"
          name="amount"
          value={props.dealItem.amount.toString()}
          onChange={onPropChange}
          error={props.validationState.amount}
        />
      </div>
      {props.dealItem.dealType === DealType.Percentage && <div className="mt-2">
        <TextInput
          title={translate("Max_Discount_Text", "Deal max discount")}
          className="w-full"
          placeholder={translate(
            "Type_Deal_Name_Text",
            "Type deal max discount"
          )}
          type="text"
          name="maximumDiscount"
          value={props.dealItem.maximumDiscount.toString()}
          onChange={onPropChange}
          error={props.validationState.maximumDiscount}
        />
      </div>}

      <div className="mt-2">
        <AutoCompleteComponent
          id="appliedOn"
          title={translate("Add_AppliedOn", "Applied on list")}
          placeholder={translate(
            "Add_AppliedOn",
            "Add applied on list"
          )}
          optionsList={renderAutoCompleteVendorProductOptions(props.vendorProductLookUp)}
          onSelectClear
          onChange={onProductValueChange}
          onSelect={onSelectProduct}
          name="appliedOn"
          error={props.validationState.appliedOn}
        />

      </div>
      <div className="mt-2 flex flex-wrap gap-1 w-full">
        {props.selectedForDealList.map(({ name }) => <Tag title={name} />)}
      </div>
      <div className="mt-2">
        <label>{translate("Add_Image", "Add image")}</label>
        <FileUploader
          placeholder={translate("Upload_Deal_Image", "Upload deal image")}
          error={props.validationState.pictureFileId}
          selectedFile={props.file}
          supportedFiles={[FilesTypes.pdf, FilesTypes.png, FilesTypes.jpg]}
          onChange={onChangeFile}
        />
      </div>
      <div className="flex justify-end self-end gap-3 items-end mt-10">
        <Button
          className="text-[#F04438] rounded-lg !bg-white !w-[115px] !h-[45px]  !text-[14px] !font-medium border-[1px] border-[#F04438]"
          onClick={onCloseDialog}
        >
          {translate("Cancel", "Cancel")}
        </Button>
        <Button
          className="text-white rounded-lg !bg-[#518c7d] w-[115px] h-[45px] !text-[14px] font-medium"
          onClick={editDeal}
        >
          {translate("Edit", "Edit")}
        </Button>
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  dealItem: state.deals.dealItem,
  vendorProductLookUp: state.deals.vendorProductLookUp,
  selectedForDealList: state.deals.selectedForDealList,
  file: state.deals.file,
  validationState: validateEditDealItemState(state.deals),
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onPropChange: (name: string, value: string | number) => {
    dispatch(setDealItemProp({ name: name, value: value }));
  },
  onChangeFile: (file?: File) => {
    dispatch(UploadDealFile(file!));
  },
  editDeal: () => {
    dispatch(addOrUpdateDeal());
  },
  setCloseDialog: () => {
    dispatch(setClearDealItem());
    dispatch(setCloseDialog());
  },
  autoVendorProductTypingComplete: (searchText: string) => {
    dispatch(autoVendorProductTypingComplete(searchText));
  },
  addToSelectedForDealList: (item: SelectedForDeal) => {
    dispatch(addToSelectedForDealList(item));
  },
  clearVendorProductLookup: () => {
    dispatch(setVendorProductListLookUp([]))
  }
});

const EditDealPopUpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDealPopUp);

export { EditDealPopUpContainer };

