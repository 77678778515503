import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserCulture } from "../../libs/enums";
import { Filter } from "../../libs/models/filter";
import { PaginatedList } from "../../libs/models/paginated-list";
import { ProductModel } from "../../libs/models/product";
import {
  RequestVendorProductPricing,
  VendorProductModel,
} from "../../libs/models/vendor-product";
import { validateAddVendorProductItemState } from "../../pages/VendorProduct/containers/AddVendorProduct/add-vendor-product-popup.policy";
import {
  addVendorProductService,
  deleteVendorProductPriceService,
  editVendorProductPriceService,
  getVendorProductPriceListService
} from "../../services/offerz-service";
import { getFilterQuery } from "../../utils/filter";
import { AsyncAppThunk } from "../application-state";
import { setCloseDialog } from "./modal-popup";
import { setClearProductItem } from "./product";
import { showLoaderWhile } from "./ui";
import { validateUpdateVendorProductItemState } from "pages/VendorProduct/containers/UpdateVendorProduct/update-vendor-product-popup.policy";

export interface VendorProductStore {
  selectedCategoryId: string;
  vendorProductList: PaginatedList<VendorProductModel>;
  vendorProductItem: RequestVendorProductPricing;
  filter: Filter;
  userCulture: UserCulture;
  isValidating: boolean;
}
const initialState: VendorProductStore = {
  isValidating: false,
  selectedCategoryId: "",
  filter: {
    search: { key: "ProductName", value: "" },
    dateProp: { from: null, to: null },
    orderBy: { key: "ProductName", sort: "asc" },
  },
  vendorProductList: {
    list: [],
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
  },
  vendorProductItem: <RequestVendorProductPricing>{
    price: "",
    productId: null,
    product: null,
  },
  userCulture: UserCulture.English,
};
const slice = createSlice({
  name: "vendor-products",
  initialState,
  reducers: {
    setSelectedCategoryId: (
      state: VendorProductStore,
      action: PayloadAction<string>
    ) => {
      state.selectedCategoryId = action.payload;
    },
    setIsValidating: (
      state: VendorProductStore,
      action: PayloadAction<boolean>
    ) => {
      state.isValidating = action.payload;
    },
    setVendorProductListPage: (state: VendorProductStore,
      action: PayloadAction<number>
    ) => {
      state.vendorProductList.pageIndex = action.payload;
    },
    setVendorProductList: (
      state: VendorProductStore,
      action: PayloadAction<PaginatedList<VendorProductModel>>
    ) => {
      state.vendorProductList = action.payload;
    },
    addProductToVendorProduct: (
      state: VendorProductStore,
      action: PayloadAction<ProductModel>
    ) => {
      state.vendorProductItem.product = { ...action.payload };
    },
    setVendorProductItem: (
      state: VendorProductStore,
      action: PayloadAction<RequestVendorProductPricing>
    ) => {
      state.vendorProductItem = action.payload;
    },
    setVendorProductItemProp: (
      state: VendorProductStore,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      const { name, value } = action.payload;
      state.vendorProductItem = { ...state.vendorProductItem, [name]: value };
    },
    clearVendorProductItem: (state: VendorProductStore) => {
      state.isValidating = false;
      state.vendorProductItem = initialState.vendorProductItem;
      state.selectedCategoryId = "";
    },
  },
});
export const {
  setIsValidating,
  setVendorProductList,
  setSelectedCategoryId,
  setVendorProductItemProp,
  clearVendorProductItem,
  addProductToVendorProduct,
  setVendorProductItem,
  setVendorProductListPage
} = slice.actions;
export const reducer = slice.reducer;

export const addVendorProduct =
  (): AsyncAppThunk => async (dispatch, store) => {
    const productItem = store().product.productItem;
    const vendorProductModelItem = store().vendorproduct.vendorProductItem!;
    const requestModel = <RequestVendorProductPricing>{
      ...vendorProductModelItem,
      product:
        vendorProductModelItem.productId === null ||
          vendorProductModelItem.productId === ""
          ? productItem
          : null,
    };

    dispatch(setIsValidating(true));
    if (
      Object.keys(validateAddVendorProductItemState(store().vendorproduct, store().product)).length == 0
    ) {
      dispatch(
        showLoaderWhile(async () => {
          const response = await addVendorProductService(requestModel);
          if (response && response?.result !== null) {
            dispatch(setCloseDialog());
            dispatch(clearVendorProductItem());
            dispatch(setClearProductItem());
            dispatch(getVendorProductPriceList());
          }
        })
      );
    }
  };

export const getVendorProductPriceList =
  (): AsyncAppThunk => async (dispatch, store) => {
    dispatch(
      showLoaderWhile(async () => {
        const vendorProductPriceListResponse =
          await getVendorProductPriceListService(
            getFilterQuery(
              store().vendorproduct.filter,
              store().vendorproduct.vendorProductList.pageIndex *
              store().vendorproduct.vendorProductList.pageSize,
              store().vendorproduct.vendorProductList.pageSize
            )
          );
        if (
          vendorProductPriceListResponse &&
          vendorProductPriceListResponse?.result !== null
        ) {
          dispatch(
            setVendorProductList(vendorProductPriceListResponse!.result)
          );
        }
      })
    );
  };
export const deleteVendorProductPrice =
  (id: string): AsyncAppThunk =>
    async (dispatch, store) => {
      dispatch(
        showLoaderWhile(async () => {
          const response = await deleteVendorProductPriceService(id);
          if (response && response?.result !== null) {
            dispatch(setCloseDialog());
            dispatch(getVendorProductPriceList());
          }
        })
      );
    };
export const editVendorProductPrice = (): AsyncAppThunk => async (dispatch, store) => {
  const vendorProductModelItem = store().vendorproduct.vendorProductItem;
  dispatch(setIsValidating(true));
  if (Object.keys(validateUpdateVendorProductItemState(store().vendorproduct)).length == 0) {
    dispatch(
      showLoaderWhile(async () => {
        const response = await editVendorProductPriceService(vendorProductModelItem);
        if (response && response?.result !== null) {
          dispatch(setCloseDialog());
          dispatch(clearVendorProductItem());
          dispatch(setClearProductItem());
          dispatch(getVendorProductPriceList());
        }
      })
    );
  }
};
