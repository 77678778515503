import { CookiesKeys, UserCulture } from "../libs/enums";
import { getCookies } from "./cookies";

const translate = (key: string, fallback: string) => {
  const userCulture = getCookies[CookiesKeys.userCulture] as UserCulture;

  const translations = {
    "en-GB": require("../translations.json")["en-GB"],
    "ar-EG": require("../translations.json")["ar-EG"],
  };

  const translation = translations[userCulture]
    ? translations[userCulture][key]
    : fallback;

  return translation || fallback;
};

export default translate;
