enum DealType {
  Percentage = 1,
  Discount = 2,
}

enum AppliedOnType {
  Product = 1,
  Category = 2,
}

interface DealsModel {
  id: string;
  name: string;
  vendorId?: string;
  dealType: DealType;
  appliedOnType: AppliedOnType;
  amount: number;
  maximumDiscount: number;
  pictureFileId?: string;
  title: string;
  description: string;
  titleEn: string;
  descriptionEn: string;
  createdBy?: string;
  createdOn?: string;
  appliedOn: string[];
}
interface AppliedOn {
  appliedOnId:string
}
export type { DealsModel, AppliedOn };
export { DealType, AppliedOnType };
