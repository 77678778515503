import translate from "./translations";
import { ErrorsCodes } from "../libs/enums";

const getErrorDescription = (ErrorCode: ErrorsCodes) => {
  switch (ErrorCode) {
    case ErrorsCodes.InvalidEmail:
      return translate("Login_Wrong_Email_Error_Text", "Wrong Email");
    case ErrorsCodes.InvalidPassword:
      return translate("Login_Wrong_Password_Error_Text", "Wrong Password");

    default:
      return translate("Common_Wrong_Text", "Wrong Value");
  }
};

export { getErrorDescription };
