import { connect } from 'react-redux'
import { ApplicationState } from "../../store"
import { LoaderProps } from './loader-container.props'


const LoaderComponent = (props: LoaderProps) => {

    return (
        props.isLoading ? <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-25 flex justify-center items-center vh-100 z-20"><div className="animate-pulse bg-primary-500 h-10 w-10 rounded-full"></div>
        </div>:<div></div>
    )
}
const mapStateToProps = (state: ApplicationState) => ({
    isLoading: state.ui.isLoading,
});

const LoaderContainer = connect(
    mapStateToProps,

)(LoaderComponent);
export { LoaderContainer }

