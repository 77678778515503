import { ProductModel } from "../../../../libs/models/product";
import { ValidationModel } from "../../../../libs/models/validate-model";
import { ProductStore } from "../../../../store/slices/product";
import translate from "../../../../utils/translations";

export const validateAddProductItemState = (
  state: ProductStore
): ValidationModel<ProductModel> => {
  let validationModel = {} as ValidationModel<ProductModel>;
  console.log(state.productItem);

  if (!state.isValidating) {
    return validationModel;
  }
  if (!state.productItem.name || state.productItem.name === null || state.productItem.name === "") {
    validationModel.name = translate("Required_Text", "This field is required");
  }
  console.log(validationModel);

  if (!state.productItem.subCategoryId ||
    state.productItem.subCategoryId === null ||
    state.productItem.subCategoryId === ""
  ) {
    validationModel.subCategoryId = translate("Required_Text", "This field is required");
  }
  if (!state.productItem.pictureFileId ||
    state.productItem.pictureFileId === null ||
    state.productItem.pictureFileId === ""
  ) {
    validationModel.pictureFileId = translate("Required_Text", "This field is required");
  }
  return validationModel;
};
