import { ProductStore } from "store/slices/product";
import { ValidationModel } from "../../../../libs/models/validate-model";
import { VendorProductModel } from "../../../../libs/models/vendor-product";
import { VendorProductStore } from "../../../../store/slices/vendor-product";
import translate from "../../../../utils/translations";

export const validateAddVendorProductItemState = (
    vendorProductState: VendorProductStore,
    productState: ProductStore
): ValidationModel<VendorProductModel> => {
    let validationModel = {} as ValidationModel<VendorProductModel>;
    if (!vendorProductState.isValidating) {
        return validationModel;
    }
    if (!vendorProductState.vendorProductItem.productId || vendorProductState.vendorProductItem.productId === null || vendorProductState.vendorProductItem.productId === "") {
        if (!productState.productItem.name || productState.productItem.name === null || productState.productItem.name === "") {
            validationModel.productName = translate("Required_Text", "This field is required");
        }
        if (!productState.productItem.subCategoryId || productState.productItem.subCategoryId === null || productState.productItem.subCategoryId === "") {
            validationModel.productSubCategory = translate("Required_Text", "This field is required");
        }
        if (!productState.productItem.brandId || productState.productItem.brandId === null || productState.productItem.brandId === "") {
            validationModel.productBrand = translate("Required_Text", "This field is required");
        }
    }

    if (!vendorProductState.vendorProductItem.price || vendorProductState.vendorProductItem.price === null || vendorProductState.vendorProductItem.price === "") {
        validationModel.price = translate("Required_Text", "This field is required");
    }
    return validationModel;
};
