import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ErrorsCodes, UserCulture } from "../../libs/enums";
import {
  UserDetails,
  VendorRegisterRequestModel,
} from "../../libs/models/vendor-register-request";
import {
  uploadFile,
  vendorRegisterService,
} from "../../services/offerz-service";
import { AsyncAppThunk } from "../application-state";
import { showLoaderWhile } from "./ui";
import { validateVendorRegisterRequestState } from "../../pages/VendorDetails/vendor-details.policy";
import { push } from "@lagunovsky/redux-react-router";
import { ROUTES } from "../../libs";
import { BranchModel } from "../../libs/models/branch";
import { LoggedUserStatus } from "../../libs/models/login";
import { setErrorCode, setLoggedUserStatus } from "./login";
import { BranchState } from "../../pages/AddBranch/add-branch.props";
import { VENDOR_ROUTES } from "../../libs/routes";

export interface VendorDetailsStore {
  vendorName: string;
  vendorWebsite: string;
  vendorTaxRegistrationFile?: File;
  vendorTaxRegistrationFileId: string;
  vendorMobileNumber: string;
  vendorPhoneNumber: string;
  isValidating: boolean;
  vendorBranches: BranchModel[];
  vendorTermsConditionId: string | null;
  userCulture: UserCulture;
  addBranchDialogIsOpen: boolean;
}

const initialState: VendorDetailsStore = {
  isValidating: false,
  vendorName: "",
  vendorWebsite: "",
  vendorMobileNumber: "",
  vendorPhoneNumber: "",
  vendorBranches: [],
  vendorTermsConditionId: null,
  userCulture: UserCulture.English,
  addBranchDialogIsOpen: false,
  vendorTaxRegistrationFileId: "",
};

const slice = createSlice({
  name: "vendor-details",
  initialState,
  reducers: {
    setVendorName: (
      state: VendorDetailsStore,
      action: PayloadAction<string>
    ) => {
      state.vendorName = action.payload;
    },
    setWebsite: (state: VendorDetailsStore, action: PayloadAction<string>) => {
      state.vendorWebsite = action.payload;
    },
    setVendorTaxRegistrationFile: (
      state: VendorDetailsStore,
      action: PayloadAction<File | undefined>
    ) => {
      state.vendorTaxRegistrationFile = action.payload;
    },
    setVendorTaxRegistrationFileId: (
      state: VendorDetailsStore,
      action: PayloadAction<string>
    ) => {
      state.vendorTaxRegistrationFileId = action.payload;
    },
    setVendorMobileNumber: (
      state: VendorDetailsStore,
      action: PayloadAction<string>
    ) => {
      state.vendorMobileNumber = action.payload;
    },
    setVendorPhoneNumber: (
      state: VendorDetailsStore,
      action: PayloadAction<string>
    ) => {
      state.vendorPhoneNumber = action.payload;
    },
    setVendorAcceptedTerms: (
      state: VendorDetailsStore,
      action: PayloadAction<string | null>
    ) => {
      state.vendorTermsConditionId = action.payload;
    },
    setIsValidating: (
      state: VendorDetailsStore,
      action: PayloadAction<boolean>
    ) => {
      state.isValidating = action.payload;
    },
    addNewBranch: (
      state: VendorDetailsStore,
      action: PayloadAction<BranchState>
    ) => {
      const newBranchId = state.vendorBranches.length + 1;
      var branchItem = action.payload;
      state.vendorBranches.push({
        ...branchItem,
        id: newBranchId,
        city: branchItem.city ? branchItem.city.id!.toString() : null,
        country: branchItem.country ? branchItem.country.id!.toString() : null,
        area: branchItem.area ? branchItem.area.id!.toString() : null,
      });
      state.addBranchDialogIsOpen = false;
    },
    removeBranch: (
      state: VendorDetailsStore,
      action: PayloadAction<number>
    ) => {
      const itemId = action.payload;
      state.vendorBranches = state.vendorBranches.filter(
        (item) => item.id !== itemId
      );
    },
    setAddBranchDialogIsOpen: (
      state: VendorDetailsStore,
      action: PayloadAction<boolean>
    ) => {
      state.addBranchDialogIsOpen = action.payload;
    },
  },
});

export const {
  setVendorName,
  setWebsite,
  setVendorTaxRegistrationFile,
  setVendorTaxRegistrationFileId,
  setVendorMobileNumber,
  setVendorPhoneNumber,
  setIsValidating,
  setVendorAcceptedTerms,
  addNewBranch,
  removeBranch,
  setAddBranchDialogIsOpen,
} = slice.actions;
export const reducer = slice.reducer;

export const registerVendor = (): AsyncAppThunk => async (dispatch, store) => {
  dispatch(setIsValidating(true));

  var vendorDetailsState = store().vendordetails;
  var validationState = validateVendorRegisterRequestState(vendorDetailsState);
  const loggedUser = store().login.loggedUser;
  if (loggedUser === null) {
    dispatch(push(ROUTES.LOGIN));
    return;
  }
  const userDetails: UserDetails = {
    id: loggedUser.id,
    firstName: loggedUser!.firstName,
    lastName: loggedUser!.lastName,
    userName: loggedUser!.email,
  };
  if (Object.keys(validationState).length < 1) {
    const vendorDetailsModel: VendorRegisterRequestModel = {
      vendorName: vendorDetailsState.vendorName,
      vendorWebsite: vendorDetailsState.vendorWebsite,
      vendorMobileNumber: vendorDetailsState.vendorMobileNumber,
      vendorPhoneNumber: vendorDetailsState.vendorPhoneNumber,
      taxRegistrationFileld: vendorDetailsState.vendorTaxRegistrationFileId,
      requestedUser: userDetails,
      vendorBranches: vendorDetailsState.vendorBranches,
      vendorTermsConditionId: vendorDetailsState.vendorTermsConditionId,
    };
    dispatch(
      showLoaderWhile(async () => {
        const vendorRegisterResponse = await vendorRegisterService(
          vendorDetailsModel
        );

        if (vendorRegisterResponse && vendorRegisterResponse.result != null) {
          dispatch(setIsValidating(false));
          dispatch(setLoggedUserStatus(LoggedUserStatus.RequestSubmited));
          dispatch(push(VENDOR_ROUTES.REGISTRATION_STATUS));
        } else {
          dispatch(
            setErrorCode(vendorRegisterResponse?.errorCode as ErrorsCodes)
          );
        }
      })
    );
  }
};
export const UploadFile = (file: File): AsyncAppThunk => async (dispatch, store) => {
    const formData = new FormData()
    formData.append("file", file);
    dispatch(setVendorTaxRegistrationFile(file));
    dispatch(showLoaderWhile(async () => {


        const vendorRegisterResponse = await uploadFile(formData);

        if (vendorRegisterResponse && vendorRegisterResponse.result != null) {
            dispatch(setVendorTaxRegistrationFileId(vendorRegisterResponse.result));
        } else {
            dispatch(setErrorCode(vendorRegisterResponse?.errorCode as ErrorsCodes));
        }
    }))





}
