import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { requestVerificationService } from "../../services/offerz-service";
import { AsyncAppThunk } from "../application-state";

export interface AccountVerificationStore {
  dialogOpen: boolean;
}

const initialState: AccountVerificationStore = {
  dialogOpen: false
};

const slice = createSlice({
  name: "account-verification",
  initialState,
  reducers: {
    setAccountVerificationDialog: (state: AccountVerificationStore, action: PayloadAction<boolean>) => {
      state.dialogOpen = action.payload;
    },
  },
});

export const {
  setAccountVerificationDialog,
} = slice.actions;
export const reducer = slice.reducer;



export const requestVerification = (email: string): AsyncAppThunk => async (dispatch, store) => {
  dispatch(setAccountVerificationDialog(true));

  const verificationRequestResponse = await requestVerificationService(email);
};
