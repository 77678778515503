import { connect } from "react-redux";
import { CrosspayLogo } from "static/images/svgs/crosspay-logo";
import { AppDispatch, ApplicationState } from "store";
import {
  forgetPasswordOtpRequest,
  resetForgetPasswordState,
  setEmail,
} from "store/slices/forget-password";
import { LoginImg } from "../../static/images/svgs/login-img";
import { ForgetPasswordViewsList } from "libs/forget-password-forms";
import { ForgetPasswordProps } from "./forget-password.props";
import { useEffect } from "react";

const ForgetPassword = (props: ForgetPasswordProps) => {
  useEffect(() => {
    props.resetForgetPasswordState();
  }, []);
  return (
    <div className="flex w-full h-screen">
      <div className="flex flex-col lg:w-1/2 px-20  ">
        <div className="w-[10rem] h-[10rem] mt-[5rem]">
          <CrosspayLogo width="auto" height="auto" />
        </div>
        {ForgetPasswordViewsList[props.forgetPasswordViewIndex]}
      </div>
      <div className="lg:w-1/2 h-screen px-10 flex justify-center">
        <LoginImg />
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  forgetPasswordViewIndex: state.forgetPassword.forgetPasswordViewIndex,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  resetForgetPasswordState: () => {
    dispatch(resetForgetPasswordState());
  },
});

const ForgetPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgetPassword);
export { ForgetPasswordContainer };
