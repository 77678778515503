import React from 'react'
import { WarningImg } from '../../../static/images/svgs/warning-img-svg'
import translate from '../../../utils/translations'

const DeleteCategoryPopUp = () => {
  return (
    <div className='flex p-[24px] h-full'>
      <div className='self-start'><WarningImg /></div>
      <div className='flex flex-col'>
        <div className='mb-[32px] text-[18px] font-medium justify-center'>{translate("Delete_Offer","Delete Offer")}</div>
        <div>{translate("Delete_Category_Confirmation","Are you sure you want delete this Category?")}</div>
      </div>
    </div>
  )
}

export default DeleteCategoryPopUp