import { Button, TextInput } from "@ofrz/ui-package";
import { COLORS } from "libs/colors";
import React, { useState } from "react";
import { connect } from "react-redux";
import { AppDispatch, ApplicationState } from "store";
import { forgetPasswordChange } from "store/slices/forget-password";
import translate from "utils/translations";
import { ChangePasswordProps } from "./change-forget-password.props";
import { ChangeForgetPasswordModel } from "libs/models/change-password";
import { validateChangeForgetPassword } from "./change-forget-password.policy";
import { ValidationModel } from "libs/models/validate-model";

const ChangeForgetPasswordView = (props: ChangePasswordProps) => {
  const [passwords, setPasswords] = useState<ChangeForgetPasswordModel>({
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState<
    ValidationModel<ChangeForgetPasswordModel>
  >({
    newPassword: "",
    confirmPassword: "",
  });

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setPasswords((prev: any) => ({ ...prev, [name]: value }));
  };
  const onChangePasswordConfirm = () => {
    const validateResult = Object.keys(validateChangeForgetPassword(passwords));
    if (validateResult.length == 0) {
      props.onChangePassword(passwords.newPassword);
    } else setErrors(validateChangeForgetPassword(passwords));
  };
  return (
    <div className="flex-col">
      <div className="my-5">
        <label className={`${COLORS.TEXT_FORM} font-semibold`}>
          {translate("New_Password_Text", "New Password")}
        </label>
        <TextInput
          name="newPassword"
          className="w-full"
          placeholder="New Password"
          type="password"
          autoComplete="password"
          disableSpace
          onChange={onPasswordChange}
          value={passwords.newPassword}
          error={errors.newPassword}
        />
      </div>
      <div className="my-5">
        <label className={`${COLORS.TEXT_FORM} font-semibold`}>
          {translate("Confirm_New_Password_Text", "Confirm New Password")}
        </label>
        <TextInput
          name="confirmPassword"
          className="w-full"
          placeholder="Confirm New Password"
          type="password"
          autoComplete="password"
          disableSpace
          onChange={onPasswordChange}
          value={passwords.confirmPassword}
          error={errors.confirmPassword}
        />
      </div>
      <div>
        <Button
          onClick={onChangePasswordConfirm}
          className={`rounded-lg w-full ${COLORS.TEXT_WHITE}`}
          color="white"
          size="md"
          primary
        >
          {translate("Submit_Text", "Submit")}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onChangePassword: (newPassword: string) => {
    dispatch(forgetPasswordChange(newPassword));
  },
});

const ChangeForgetPasswordViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeForgetPasswordView);
export { ChangeForgetPasswordViewContainer };
