import { Button, TextInput } from "@ofrz/ui-package";
import { COLORS } from "libs/colors";
import React, { useState } from "react";
import { connect } from "react-redux";
import { RefreshIcon } from "static/images/svgs/refresh-icon-svg";
import { AppDispatch, ApplicationState } from "store";
import {
  forgetPasswordOtpRerequest,
  forgetPasswordOtpVerify,
} from "store/slices/forget-password";
import translate from "utils/translations";
import { VerifyOtpPasswordProps } from "./verify-otp-password.props";

const VerifyOtpPasswordView = (props: VerifyOtpPasswordProps) => {
  const [otpCode, setOtpCode] = useState<string>("");
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length > 4) return;
    setOtpCode(value);
  };
  const onSubmitClicked = () => {
    props.onVerify(otpCode);
  };
  const onRerequestOtp = () => {
    props.onRerequestOtp();
  };
  return (
    <div>
      <div className="my-5">
        <label className={`${COLORS.TEXT_FORM} font-semibold`}>
          {translate("Code_Text", "Code")}
        </label>
        <TextInput
          className="w-full"
          placeholder={translate("Code_Text", "Code")}
          type="text"
          disableSpace
          onInput={onInputChange}
          onChange={onInputChange}
          value={otpCode}
          suffixContent={
            <button
              className="bg-[#518c7d1a] hover:bg-[#518c7d4d] p-1 rounded-[100%]"
              onClick={onRerequestOtp}
            >
              <RefreshIcon />
            </button>
          }
        />
      </div>
      <div>
        <Button
          onClick={onSubmitClicked}
          className={`rounded-lg w-full ${COLORS.TEXT_WHITE}`}
          color="white"
          size="md"
          primary
        >
          {translate("Verify_Text", "Verify")}
        </Button>
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onVerify: (otpCode: string) => {
    dispatch(forgetPasswordOtpVerify(otpCode));
  },
  onRerequestOtp: () => {
    dispatch(forgetPasswordOtpRerequest());
  },
});
const VerifyOtpPasswordViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyOtpPasswordView);
export { VerifyOtpPasswordViewContainer };
