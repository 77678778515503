import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { Dialog } from "@ofrz/ui-package";
import { ModalPopUpProps } from "./modal-popup.props";

const ModalPopUpComponent = (props: ModalPopUpProps) => {

    return (
        <div className="flex-col">
            <Dialog title={props.title === null ? "" : props.title} onClose={props.onClose} isOpen={props.isOpen} actionButtons={props.actionButtons} content={props.content === null ? <></> : props.content} />
        </div>
    )
}
const mapStateToProps = (state: ApplicationState) => ({
    isOpen: state.modalpopup.isOpen,
    content: state.modalpopup.content,
    title: state.modalpopup.title,
    actionButtons: state.modalpopup.actionButtons,
    onClose: state.modalpopup.onClose,
});

const ModalPopUpContainer = connect(
    mapStateToProps,

)(ModalPopUpComponent);
export { ModalPopUpContainer };
