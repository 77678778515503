import { Stepper } from "@ofrz/ui-package";
import { useEffect } from "react";
import { connect } from "react-redux";
import { LoginImg } from "../../static/images/svgs/login-img";
import { OfferzLogo } from "../../static/images/svgs/offerz-logo";
import { AppDispatch, ApplicationState } from "../../store/application-state";
import { initStepper, nextStep } from "../../store/slices/stepper";
import translate from "../../utils/translations";
import { VendorRegistrationStepperProps } from "./vendor-registration-stepper.props";
import { CrosspayLogo } from "static/images/svgs/crosspay-logo";

const RegistrationStepperContainer = (
  props: VendorRegistrationStepperProps
) => {
  useEffect(() => {
    props.onInit();
  }, []);
  return (
    <div className="flex w-full h-screen">
      <div className="flex flex-col lg:w-1/2 px-20">
        <div className="w-[7rem] h-[7rem] mt-[3rem]">
          <CrosspayLogo width="auto" height="auto" />
        </div>

        <h2 className="text-5xl font-semibold font-sans my-4">
          {translate("Signup_Text", "Sign Up")}
        </h2>
        <div className="mr-auto ml-auto w-full my-[1.5rem]">
          <Stepper activeStep={props.activeStep} steps={props.steps} />
        </div>
        {/* <div className="w-full flex flex-col  ">
          {props.currentForm ? <props.currentForm /> : <div></div>}
        </div> */}
      </div>
      <div className="lg:w-1/2 h-screen px-10 flex justify-center">
        <LoginImg />
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  //userCulture: state.vendordetails.userCulture,
  activeStep: state.registrationstepper.activeStep,
  steps: state.registrationstepper.steps,
  loggedUserRegistrationStatus: state.login.loggedUserRegistrationStatus,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onNextStep: () => {
    dispatch(nextStep());
  },
  onInit: () => {
    dispatch(initStepper());
  },
});
const VendorRegistrationStepperContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationStepperContainer);
export { VendorRegistrationStepperContainer };
