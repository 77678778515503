const USER_MANAGMENT_END_POINTS = {
  LOGIN_ADMIN: "Login/Admin",
  VENDOR_ADMIN_REGISTRATION: "Registration/VendorAdmin",
  VENDOR_RESEND_VERIFICATION:
    "Verification/VendorAdmin/RequestEmailVerification",
  VERIFICATION_CHECKING: "/Verification/VerificationChecking",
  REFRESH_TOKEN: "Login/RefreshToken",
  Change_Password: "User/profile/ChangePassword",
  FORGET_PASSWORD_REQUEST_OTP: "/ForgetPassword/RequestOtp",
  FORGET_PASSWORD_VERIFY_OTP: "/ForgetPassword/VerifyOtp",
  FORGET_PASSWORD_CHANGE_PASSWORD: "/ForgetPassword/ChangePassword",
};
const OFFERZ_END_POINTS = {
  VENDOR_REGISTER_REQUEST: "api/VendorRequest/Request",
  USER_IS_MEMBER: "api/OfferzUsers/UserIsVednorAdminMemeber",
  TERMS_CONDITIONS: "api/TermsConditions",
  CREATE_TERMS_CONDITIONS: "api/TermsConditions/create",
  VENDOR_TERMS_CONDITIONS: "api/TermsConditions/vendor/unconfimed",
  VENDOR_TERMS_CONDITIONS_CONFIRM: "api/TermsConditions/confirm/",
  TERMS_CONDITIONS_LIST: "api/TermsConditions/",
  TERMS_CONDITIONS_UNCONFIRMED_LIST: "api/TermsConditions/unconfirmed/list/",
  MAGAZINE_VENDOR: "api/Magazine/Vendor",
  MAGAZINE: "api/Magazine",
  VENDORS_REQUESTS_LIST: "/api/VendorRequest/GetVendorsRequests",
  VENDORS_LIST: "/api/Vendor/list",
  VENDOR_DETAILS: "/api/Vendor/",
  APPROVE_VENDOR_REQUEST: "/api/VendorRequest/Approve/",
  REJECT_VENDOR_REQUEST: "/api/VendorRequest/Reject/",
  SUSPEND_VENDOR: "/api/Vendor/Suspend/",
  ACTIVATE_VENDOR: "/api/Vendor/Activate/",
  CATEGORY: "/api/Category",
  DEALS: "/api/Deals",
  PRODUCT_AUTO_COMPLETE: "/api/product/AutoComplete",
  PRODUCT: "/api/product",
  Brand: "/api/brand",
  VENDOR_PRODUCT: "/api/VendorProduct",
};
const FILE_MANAGMENT_END_POINTS = {
  DOWNLOAD: "api/Storage/Download",
  UPLOAD_FILE: "api/Storage/Upload",
};

export {
  USER_MANAGMENT_END_POINTS,
  OFFERZ_END_POINTS,
  FILE_MANAGMENT_END_POINTS,
};
