
const PendingRequestImg = ({ width = "66", height = "67" }) => {
  return (<svg width={width} height={height} viewBox="0 0 66 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="5.81738" width="56" height="56" rx="28" fill="#FEF0C7" />
    <rect x="5" y="5.81738" width="56" height="56" rx="28" stroke="#FFFAEB" strokeWidth="10" />
    <g clipPath="url(#clip0_1156_18627)">
      <path d="M44.0691 40.8185C43.5844 40.4579 42.8993 40.5586 42.5387 41.0432C42.3394 41.311 42.129 41.5702 41.908 41.8203C41.508 42.2729 41.5504 42.9641 42.003 43.3642C42.2026 43.5412 42.4602 43.6388 42.727 43.6385C43.0295 43.6385 43.3307 43.5137 43.5469 43.2691C43.8086 42.973 44.0578 42.6659 44.2938 42.3488C44.6543 41.8642 44.5538 41.179 44.0691 40.8185ZM45.8983 35.6891C45.3084 35.5606 44.7254 35.935 44.5969 36.5251C44.526 36.8509 44.4412 37.1735 44.3428 37.4921C44.1645 38.0692 44.4878 38.6816 45.0649 38.86C45.1725 38.8932 45.2812 38.909 45.3882 38.909C45.8552 38.909 46.2877 38.6074 46.4328 38.1378C46.5496 37.7598 46.6502 37.377 46.7344 36.9904C46.8628 36.4002 46.4885 35.8176 45.8983 35.6891ZM38.8045 44.3214C38.5131 44.484 38.215 44.6342 37.9109 44.7716C37.3603 45.0202 37.1156 45.6681 37.3642 46.2186C37.5469 46.6231 37.945 46.8624 38.3617 46.8624C38.5122 46.8624 38.6651 46.8312 38.8112 46.7653C39.1718 46.6023 39.5254 46.4242 39.8709 46.2313C40.3983 45.9369 40.5872 45.2705 40.2926 44.7431C39.9983 44.2158 39.3319 44.027 38.8045 44.3214ZM31.9063 25.25V33.547L27.8955 37.5576C27.4684 37.9848 27.4684 38.6773 27.8955 39.1044C27.997 39.2062 28.1175 39.2868 28.2503 39.3418C28.383 39.3968 28.5253 39.425 28.669 39.4248C28.8126 39.425 28.9549 39.3967 29.0877 39.3418C29.2204 39.2868 29.341 39.2061 29.4424 39.1044L33.7734 34.7734C33.9785 34.5683 34.0938 34.2901 34.0938 34V25.25C34.0938 24.6459 33.6041 24.1562 33 24.1562C32.3959 24.1562 31.9063 24.6459 31.9063 25.25Z" fill="#DC6803" />
      <path d="M45.9062 22.3516C45.3022 22.3516 44.8125 22.8412 44.8125 23.4453V26.4824C42.2667 22.4898 37.8208 20 33 20C29.2605 20 25.7448 21.4563 23.1005 24.1005C20.4563 26.7448 19 30.2605 19 34C19 37.7395 20.4563 41.2552 23.1005 43.8995C25.7448 46.5437 29.2605 48 33 48C33.0092 48 33.0182 47.9989 33.0273 47.9986C33.0365 47.9989 33.0454 48 33.0547 48C33.4489 48 33.8469 47.9834 34.238 47.9507C34.8399 47.9002 35.287 47.3714 35.2367 46.7695C35.1862 46.1675 34.6581 45.7202 34.0554 45.7708C33.7249 45.7984 33.3882 45.8125 33.0547 45.8125C33.0454 45.8125 33.0365 45.8136 33.0273 45.8139C33.0182 45.8136 33.0092 45.8125 33 45.8125C26.4866 45.8125 21.1875 40.5134 21.1875 34C21.1875 27.4866 26.4866 22.1875 33 22.1875C37.1977 22.1875 41.0586 24.4249 43.1684 27.9844H40.1609C39.5569 27.9844 39.0672 28.474 39.0672 29.0781C39.0672 29.6822 39.5569 30.1719 40.1609 30.1719H43.5C44.1422 30.1726 44.7721 29.9956 45.3198 29.6604C45.3537 29.6411 45.3865 29.6201 45.4181 29.5974C46.3699 28.9712 47 27.894 47 26.6719V23.4453C47 22.8412 46.5103 22.3516 45.9062 22.3516Z" fill="#DC6803" />
    </g>
    <defs>
      <clipPath id="clip0_1156_18627">
        <rect width="28" height="28" fill="white" transform="translate(19 20)" />
      </clipPath>
    </defs>
  </svg>

  );


}

export default PendingRequestImg