import { go, push } from "@lagunovsky/redux-react-router";
import { connect } from "react-redux";
import { VendorsPageProps } from "./vendors-page.props";
import { AppDispatch, ApplicationState } from "../../../store";
import translate from "../../../utils/translations";
import { Button, Grid, TextInput } from "@ofrz/ui-package";
import { useEffect, useState } from "react";
import { COLORS } from "../../../libs/colors";
import { SystemVendorsView } from "../../../libs/models/system-admin";
import {
  getVendorsList,
  getVendorsRequests,
  onSkip,
  onSort,
  searchByName,
  vendorAction,
  vendorsRequestAction,
} from "../../../store/slices/vendors";
import {
  getVendorsDataList,
  getVendorsRequestsDataList,
  vendorsListHeaders,
  vendorsRequestHeaders,
} from "./vendors-page.utils";
import { SYSTEM_ADMIN } from "../../../libs/routes";
import { generatePath } from "react-router";
import {
  ModalPopupStore,
  setCloseDialog,
  setDialogStateChange,
} from "../../../store/slices/modal-popup";
import VendorActionPopUp from "./vendors-page.helpers";
import onClickVendorStatus from "./vendors-page.helpers";
import { CloseIcon } from "static/images/svgs/close-icon-svg";

const VendorsPage = (props: VendorsPageProps) => {
  const [activeView, setActiveView] = useState<SystemVendorsView>(
    SystemVendorsView.Requests
  );
  const onViewChange = (view: SystemVendorsView) => {
    setActiveView(view);
    setSearch("");
    props.onFilterByName("", view);
  };
  const [search, setSearch] = useState<string>("");

  const isActive = (view: SystemVendorsView) => {
    return view === activeView;
  };

  useEffect(() => {
    props.onInit();
  }, []);

  return (
    <div className="flex flex-col space-y-2 w-full">
      <p className="text-3xl font-bold text-[#1D2939] p-4 select-none">
        {translate("Vendors_Text", "Vendors")}
      </p>
      <div className="flex flex-row space-x-1 items-center">
        <div className="flex flex-row w-3/6 space-x-1">
          <Button
            primary={isActive(SystemVendorsView.Requests)}
            className="rounded-lg "
            onClick={() => onViewChange(SystemVendorsView.Requests)}
          >
            <p
              className={
                isActive(SystemVendorsView.Requests)
                  ? `${COLORS.TEXT_WHITE}`
                  : `${COLORS.TEXT_PRIMARY}`
              }
            >
              {translate("Vendors_Requests_Text", "Vendors requests")}
            </p>
          </Button>
          <Button
            primary={isActive(SystemVendorsView.List)}
            className="rounded-lg "
            onClick={() => onViewChange(SystemVendorsView.List)}
          >
            <p
              className={
                isActive(SystemVendorsView.List)
                  ? `${COLORS.TEXT_WHITE}`
                  : `${COLORS.TEXT_PRIMARY}`
              }
            >
              {translate("Vendors_List_Text", "Vendors list")}
            </p>
          </Button>
        </div>
        <div className="w-2/6">
          <TextInput
            placeholder={translate("Search_Placeholder_Text", "Search by name")}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            suffixContent={
              search !== null &&
              search !== "" && (
                <button
                  className="bg-[#518c7d1a] hover:bg-[#518c7d4d] p-1 rounded-[100%]"
                  onClick={(e) => {
                    setSearch("");
                  }}
                >
                  <CloseIcon />
                </button>
              )
            }
          />
        </div>
        <div className="w-1/6">
          <Button
            onClick={() => props.onFilterByName(search, activeView)}
            className={`rounded-lg w-full ${COLORS.TEXT_PRIMARY} !bg-[#F9F5FF] `}
            color="white"
            size="sm"
            primary
          >
            {translate("search", "Search")}
          </Button>
        </div>
      </div>
      <div>
        {isActive(SystemVendorsView.Requests) ? (
          <div>
            <Grid
              columns={vendorsRequestHeaders}
              dataSource={getVendorsRequestsDataList(
                props.vendorsRequests.list,
                props.onVendorsRequestAction,
                props.onVendorRequestClick
              )}
              currentPage={props.vendorsRequests.pageIndex + 1}
              onPageChange={(newPage: number) => props.onSkip(newPage - 1)}
              pagination={true}
              pageSize={props.vendorsRequests.pageSize}
              totalCount={props.vendorsRequests.totalCount}
              onSort={(dataIndex: string, order: "desc" | "asc") => {}}
            />
          </div>
        ) : (
          <div>
            <Grid
              columns={vendorsListHeaders}
              dataSource={getVendorsDataList(
                props.vendorsList.list,
                (vendorId: string, vendorName: string, isActivate: boolean) =>
                  onClickVendorStatus(
                    vendorId,
                    vendorName,
                    isActivate,
                    props.onOpenDialog,
                    props.onVendorAction,
                    props.onCloseDialog
                  ),
                props.onVendorClick
              )}
              onSort={(dataIndex: string, order: "desc" | "asc") =>
                props.onSort(dataIndex, order)
              }
              currentPage={props.vendorsList.pageIndex + 1}
              onPageChange={(newPage: number) => props.onSkip(newPage - 1)}
              pagination={true}
              pageSize={props.vendorsList.pageSize}
              totalCount={props.vendorsList.totalCount}
            />
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  vendorsRequests: state.vendors.vendorsRequests,
  vendorsList: state.vendors.vendorsList,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onInit: () => {
    dispatch(getVendorsRequests());
    dispatch(getVendorsList());
  },
  onFilterByName: (value: string, type: SystemVendorsView) => {
    dispatch(searchByName(value, type));
  },
  onSkip: (currentPage: number) => {
    dispatch(onSkip(currentPage));
  },
  onSort: (dataIndex: string, order: "desc" | "asc") => {
    dispatch(onSort(dataIndex, order));
  },
  onOpenDialog: (modalProps: ModalPopupStore) => {
    dispatch(setDialogStateChange(modalProps));
  },
  onCloseDialog: () => {
    dispatch(setCloseDialog());
  },
  onVendorsRequestAction: (requestId: string, approve: boolean) => {
    dispatch(vendorsRequestAction(requestId, approve));
  },
  onVendorAction: (vendorId: string, active: boolean) => {
    dispatch(vendorAction(vendorId, active));
  },
  onVendorRequestClick: (requestId: string) => {
    dispatch(
      push(generatePath(SYSTEM_ADMIN.VENDOR_REQUEST, { id: requestId }))
    );
  },
  onVendorClick: (vendorId: string) => {
    dispatch(push(generatePath(SYSTEM_ADMIN.VENDOR_DETAILS, { id: vendorId })));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorsPage);
