import { push } from "@lagunovsky/redux-react-router";
import { Button, TextInput } from "@ofrz/ui-package";
import { useEffect } from "react";
import { connect } from "react-redux";
import { COLORS } from "../../libs/colors";
import { AppDispatch, ApplicationState } from "../../store";
import {
  ModalPopupStore,
  setCloseDialog,
  setDialogStateChange,
} from "../../store/slices/modal-popup";
import translate from "../../utils/translations";
import { DealsProps } from "./deals.props";
import { DealCardItemContainer } from "./deal-card-item/deal-card-item";
import { DealsModel } from "libs/models/deals";
import { fetchDealsList, resetDealsList, setPage, setSearch } from "store/slices/deals";
import { NoDealsImage } from "static/images/svgs/no_deals.svg";
import { EditDealPopUpContainer } from "./containers/UpdateCategoryContainer";

const Deals = (props: DealsProps) => {
  useEffect(() => {
    props.getDealsList();
  }, []);

  const onAddDealItem = () => {
    props.onAddDealClick({
        isOpen: true,
        title: <p>{translate("New_Deal_Text", "New Deal")}</p>,
        content: <EditDealPopUpContainer />,
        actionButtons: [],
        onClose: () => props.setCloseDialog(),
    });
  };

  const RenderingDealsList = () => {
    return props.dealsList.list.length > 0 ? (
      <div className="flex  gap-3 w-full flex-wrap">
        {props.dealsList.list.map((dealItem: DealsModel) => {
          return <DealCardItemContainer dealItem={dealItem} />;
        })}
      </div>
    ) : (
      <div className="flex items-center justify-center mt-[53px]">
        <div className="text-center">
          <NoDealsImage />
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between py-[25px]">
        <div className="text-[30px] font-medium">
          {translate("Deals_Text", "Deals")}
        </div>
        <Button
          className="text-white rounded-lg !bg-[#518c7d]"
          onClick={onAddDealItem}
        >
          {translate("Add_New_Deal_Text", "Add New deal")}
        </Button>
      </div>
      <div className="flex items-center mb-8">
        <div className="flex-col w-[250px] px-1">
          <TextInput
            className="w-full border p-2"
            placeholder="Deal Name"
            type="text"
            name="name"
            onChange={(e) => props.onSearchChange(e.target.value)}
            onSubmit={() => props.onApplyFilter()}
            onSubmitCapture={() => props.onApplyFilter()}
          />
        </div>
        <div className="flex-col px-1">
          <Button
            onClick={() => props.onApplyFilter()}
            className={`rounded-lg w-full ${COLORS.TEXT_PRIMARY} !bg-[#F9F5FF] `}
            color="white"
            size="sm"
            primary
          >
            {translate("search", "Search")}
          </Button>
        </div>
      </div>
      <div>
        <RenderingDealsList />
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => props.onPageChange(props.dealsList.pageIndex - 1)}
          disabled={props.dealsList.pageIndex === 0}
          className="mx-2 px-4 py-2 text-sm text-gray-700 bg-gray-200 rounded-md"
        >
          {translate("Previous_Text", "Previous")}
        </button>
        {Array.from(
          {
            length: Math.ceil(
              props.dealsList.totalCount / props.dealsList.pageSize
            ),
          },
          (_, i) => i + 1
        ).map((page) => (
          <button
            key={page}
            onClick={() => props.onPageChange(page - 1)}
            className={`mx-1 px-4 py-2 text-sm ${
              props.dealsList.pageIndex === page - 1
                ? "text-blue-600 bg-blue-100"
                : "text-gray-700 bg-gray-200"
            } rounded-md`}
          >
            {page}
          </button>
        ))}
        <button
          onClick={() => props.onPageChange(props.dealsList.pageIndex + 1)}
          disabled={
            props.dealsList.pageIndex ===
            Math.ceil(props.dealsList.totalCount / props.dealsList.pageSize) - 1
          }
          className="mx-2 px-4 py-2 text-sm text-gray-700 bg-gray-200 rounded-md"
        >
          {translate("Next_Text", "Next")}
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  dealsList: state.deals.dealsList,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  goTo: (route: string) => {
    dispatch(push(route));
  },

  getDealsList: () => {
    dispatch(fetchDealsList());
  },
  onAddDealClick: (modalProps: ModalPopupStore) => {
    dispatch(setDialogStateChange(modalProps));
  },

  setCloseDialog: () => {
    dispatch(setCloseDialog());
  },
  onSearchChange: (value: string) => {
    dispatch(setSearch(value));
  },

  onPageChange: (pageIndex: number) => {
    dispatch(setPage(pageIndex));
    dispatch(fetchDealsList());
  },
  onApplyFilter: () => {
    dispatch(resetDealsList());
    dispatch(fetchDealsList());
  },
});

const DealsContainer = connect(mapStateToProps, mapDispatchToProps)(Deals);
export { DealsContainer };
