import { CategoryModel } from "../../../../libs/models/category";
import { ValidationModel } from "../../../../libs/models/validate-model";
import { CategoriesStore } from "../../../../store/slices/categories";
import translate from "../../../../utils/translations";

export const validateAddCategoryItemState = (state: CategoriesStore): ValidationModel<CategoryModel> => {
    let validationModel = {} as ValidationModel<CategoryModel>;

    if (!state.isValidating) {
        return validationModel;
    }
    if (state.categoryItem.name  === null || state.categoryItem.name === "") {
        validationModel.name = translate(
          "Required_Text",
          "This field is required"
        );
      }
      if (state.categoryItem.pictureFileId  === null || state.categoryItem.pictureFileId === "") {
        validationModel.name = translate(
          "Required_Text",
          "This field is required"
        );
      }
    return validationModel;
};
