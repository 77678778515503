import { Button, FileUploader, TextInput } from '@ofrz/ui-package';
import { FilesTypes } from '@ofrz/ui-package/dist/components/FileUploader/types';
import { connect } from 'react-redux';
import { COLORS } from '../../../../libs/colors';
import { AppDispatch, ApplicationState } from '../../../../store';
import { UploadMagazineFile, addVendorMagazine, setClearMagazineItem, setMagazineItemProp } from '../../../../store/slices/magazine';
import translate from '../../../../utils/translations';
import { AddMagazinePopupProps } from './add-magazine.props';
import { setCloseDialog } from '../../../../store/slices/modal-popup';
import moment from 'moment';
import { validateMagazineItemState } from '../../magazine.policy';

const AddMagazinePopUp = (props: AddMagazinePopupProps) => {
    const onPropChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        props.onPropChange(name, value);
    }

    const onChangeMagazineFile = (file?: File) => {
        props.onChangeMagazineFile(file);
    };
    const addMagazine = () => {
        props.addMagazine();
    }
    const onCloseDialog = () => {
        props.setCloseDialog();
    }
    return (
        <div className="flex flex-col">
            <div className="mt-2">
                <TextInput
                    title={translate("Magazine_Name", "TODO Magazine Name")}
                    className="w-full"
                    placeholder={translate("Enter_Magazine_Name_Here", "Please enter magazine name here")}
                    type="text"
                    name="title"
                    value={props.magazineItem.title}
                    onChange={onPropChange}
                    error={props.validationState.title}
                />
            </div>
            <div className="flex mt-2 justify-between w-full gap-5 align-middle items-center mb-4">
                <div className='w-full flex-col justify-between self-end'>
                    <label className={`${COLORS.TEXT_FORM} font-semibold`}>
                        {translate("From", "From")}
                    </label>
                    <div className='w-full h-12'>
                        <input type="date" id="from" name="from" className='w-full align-middle p-2 border-2 rounded-lg border-[#D0D5DD]' onChange={onPropChange} value={moment(props.magazineItem.from).format("YYYY-MM-DD")} />
                        {props.validationState.from && <p className="text-red-500 text-sm mt-1">{props.validationState.from}</p>}
                    </div>
                </div>
                <div className='w-full flex-col justify-between self-end'>
                    <label className={`${COLORS.TEXT_FORM} font-semibold`}>
                        {translate("To", "To")}
                    </label>
                    <div className='w-full h-12'>
                        <input type="date" id="to" name="to" className='w-full align-middle p-2 border-2 rounded-lg border-[#D0D5DD]' onChange={onPropChange} value={moment(props.magazineItem.to).format("YYYY-MM-DD")} />  
                        {props.validationState.to && <p className="text-red-500 text-sm mt-1">{props.validationState.to}</p>}
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <TextInput
                    title={translate("Magazine_Description", "Magazine Description")}
                    className="w-full"
                    placeholder="Magazine Description"
                    type="text"
                    name="description"
                    value={props.magazineItem.description}
                    onChange={onPropChange}
                    error={props.validationState.description}
                />
            </div>
            <div className="mt-2">
                <FileUploader
                    placeholder={translate(
                        "Upload_Magazine",
                        "Upload Magazine"
                    )}
                    error={props.validationState.fileId}
                    selectedFile={props.magazineFile}
                    supportedFiles={[FilesTypes.pdf]}
                    onChange={onChangeMagazineFile}
                />
            </div>
            <div className='flex justify-end self-end gap-3 items-end mt-10'>
                <Button className='text-[#F04438]  rounded-lg !bg-white !w-[115px] !h-[45px]  !text-[14px] !font-medium border-[1px] border-[#F04438]' onClick={onCloseDialog} >
                   {translate(
                        "Cancel",
                        "Cancel"
                    )}
                </Button>
                <Button className='text-white rounded-lg !bg-[#518c7d] w-[115px] h-[45px] !text-[14px] font-medium' disabled={props.isLoading} onClick={addMagazine} >Add</Button>
            </div>
        </div>
    )
}
const mapStateToProps = (state: ApplicationState) => ({
    magazineItem: state.magazine.magazineItem,
    magazineFile: state.magazine.magazineFile,
    isLoading: state.ui.isLoading,
    validationState: validateMagazineItemState(state.magazine),
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onPropChange: (name: string, value: string) => {
        dispatch(setMagazineItemProp({ name: name, value: value }));
    },
    onChangeMagazineFile: (file?: File) => {
        dispatch(UploadMagazineFile(file!));
    },
    addMagazine: () => {
        dispatch(addVendorMagazine());
    },
    setCloseDialog: () => {
        dispatch(setClearMagazineItem());
        dispatch(setCloseDialog());

    }
});

const AddMagazinePopUpContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddMagazinePopUp);

export default AddMagazinePopUpContainer;