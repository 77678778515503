import { useState } from "react";
import { getFileUrl } from "../../utils/mediaUrlFormater";
import { AutoCompleteOption, AutoCompleteProps } from "./auto-complete.props";
import ErrorText from "components/error-text";

const AutoCompleteComponent = (props: AutoCompleteProps) => {
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    props.onChange?.(newValue);
  };

  const onSelect = (option: AutoCompleteOption) => {
    props.onSelectClear? setInputValue(''):setInputValue(option.label)
    setIsOpen(false);
    props.onSelect?.(option, props.name);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="flex flex-col box-border shadow-sm ">
      <label className="pb-1 text-base" htmlFor="autocomplete-input">
        {props.title}
      </label>
      <div className={`flex item-center border-solid border-2
        ${props.error && props.error !== null && props.error !== "" ? 'border-red-500' : 'border-gray-300'} 
        rounded-lg h-12 relative`}
        onBlur={() => setTimeout(() => setIsOpen(false), 100)}>
        <input
          autoComplete="off"
          className="flex-1 focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-blue-500 p-2 rounded-lg text-gray-700"
          id="autocomplete-input"
          placeholder={props.placeholder}
          value={inputValue}
          onChange={onValueChange}
          onFocus={toggleDropdown}

        />
        {isOpen && props.optionsList.length > 0 && (
          <ul id={`${props.id}`} className="absolute z-10 w-full mt-11 bg-white border border-gray-300 rounded-md shadow-lg">
            {props.optionsList.map((option, index) => (
              <li
                key={option.value}

                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                onClick={() => onSelect(option)}
              >
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <div>
                      {option.label}
                    </div>
                    <div className="text-[14px]">
                      {option.description ?? ""}
                    </div>
                  </div>
                  {option.imgUrl !== null && option.imgUrl !== "" &&
                    <div>
                      <img src={getFileUrl(option.imgUrl!)} alt="Downloaded Image" className='object-cover  rounded-lg h-4 w-4' />
                    </div>}
                </div>

              </li>
            ))}
          </ul>
        )}
      </div>
      {props.error && props.error !== null && props.error !== "" && <ErrorText text={props.error} />}

    </div>
  );
};
export { AutoCompleteComponent };

