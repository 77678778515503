import { push } from "@lagunovsky/redux-react-router";
import { Button } from "@ofrz/ui-package";
import { ReactElement, useEffect } from "react";
import { connect } from "react-redux";
import { ROUTES } from "../../libs";
import { LoggedUserStatus } from "../../libs/models/login";
import { STATUS_PAGE_FORM } from "../../libs/status-page-form";
import { LoginImg } from "../../static/images/svgs/login-img";
import { OfferzLogo } from "../../static/images/svgs/offerz-logo";
import { AppDispatch, ApplicationState } from "../../store";
import translate from "../../utils/translations";
import { RegistrationStatusPageProps } from "./registration-status-page.props";
import { VENDOR_ROUTES } from "../../libs/routes";
import { CrosspayLogo } from "static/images/svgs/crosspay-logo";

const RegistrationStatusPage = (props: RegistrationStatusPageProps) => {
  useEffect(() => {
    if (props.loggedUserRegistrationStatus === null) {
      props.goTo(ROUTES.LOGIN);
    }
  }, [props.loggedUserRegistrationStatus]);
  const onDoneBtnClick = () => {
    props.goTo(VENDOR_ROUTES.DASHBOARD);
  };
  let PageRendering = (): {
    img: ReactElement;
    content: string;
    headline: string;
  } => {
    if (
      props.loggedUserRegistrationStatus == LoggedUserStatus.PendingApproval
    ) {
      return STATUS_PAGE_FORM.RequestPending;
    } else if (
      props.loggedUserRegistrationStatus == LoggedUserStatus.RequestRejected
    ) {
      return STATUS_PAGE_FORM.RequestRejected;
    } else if (
      props.loggedUserRegistrationStatus == LoggedUserStatus.RequestSubmited
    ) {
      return STATUS_PAGE_FORM.RequestSubmitted;
    } else if (
      props.loggedUserRegistrationStatus == LoggedUserStatus.OfferzMember
    ) {
      return STATUS_PAGE_FORM.RequestApproved;
    } else {
      return { img: <div></div>, content: "", headline: "" };
    }
  };
  return (
    <div className="flex w-full h-screen">
      <div className="flex flex-col lg:w-1/2 px-20">
        <div className="w-[7rem] h-[7rem] mt-[3rem]">
          <CrosspayLogo width="auto" height="auto" />
        </div>

        <div className="w-full flex flex-col text-center px-[80px] mt-[30%]">
          <div className="text-center w-[80px] h-[80px] self-center">
            {PageRendering().img}
          </div>
          {/* <div className="font-medium text-[1.25rem]">{translate("Data_Submited_Text", "Data Submitted Successfully")}</div> */}
          {
            <div className="font-medium text-[1.25rem]">
              {PageRendering().headline}
            </div>
          }
          {
            <div className="font-medium text-[1rem] text-[#667085]">
              {PageRendering().content}
            </div>
          }

          <Button
            onClick={onDoneBtnClick}
            className={`rounded-lg w-[22rem] h-[3.75rem] text-white self-center !text-[18px] mt-[2rem]`}
            color="white"
            primary
          >
            {translate("Done_Text", "Done")}
          </Button>
        </div>
      </div>
      <div className="lg:w-1/2 h-screen px-10 flex justify-center">
        <LoginImg />
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  loggedUserRegistrationStatus: state.login.loggedUserRegistrationStatus,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  goTo: (route: string) => {
    dispatch(push(route));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationStatusPage);
