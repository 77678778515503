import { Button } from "@ofrz/ui-package";
import {
  VendorsDetailsResponse,
  VendorsRequestsResponse,
} from "../../../libs/models/system-admin";
import translate from "../../../utils/translations";
import { COLORS } from "../../../libs/colors";
import moment from "moment";

import ImageIcon from "../../../static/images/images/img-icon-test.png";
import ImageWithFallback from "../../../components/imageWithFallback/image-fallback";

export const vendorsRequestHeaders = [
  { title: "ID", dataIndex: "id", key: "id", isSortable: false },
  {
    title: translate("Vendors_Name_Text", "Vendor name"),
    dataIndex: "vendorName",
    key: "vendorName",
    isSortable: false,
  },
  {
    title: translate("Phone_Text", "Phone"),
    dataIndex: "vendorPhoneNumber",
    key: "vendorPhoneNumber",
    isSortable: false,
  },
  {
    title: translate("Actions_Text", "Actions"),
    dataIndex: "actions",
    key: "actions",
    isSortable: false,
  },
  {
    title: translate("Status_Text", "Status"),
    dataIndex: "status",
    key: "status",
    isSortable: false,
  },
];

export const getVendorsRequestsDataList = (
  vendorsRequests: VendorsRequestsResponse[],
  onVendorsRequestAction: (id: string, approve: boolean) => void,
  onVendorRequestClick: (requestId: string) => void
) => {
  const data = [];
  for (var i = 0; i < vendorsRequests.length; i++) {
    const request = vendorsRequests[i];
    const row: Record<string, any> = {
      id: i + 1,
      vendorName: (
        <div onClick={() => onVendorRequestClick(request.id)}>
          {request.vendorName}
        </div>
      ),
      vendorPhoneNumber: request.vendorPhoneNumber,
      actions: (
        <div>
          <Button
            disabled={request.status != null}
            className={`${COLORS.TEXT_FORM}`}
            onClick={() => onVendorsRequestAction(request.id, false)}>
            {translate("Reject_Text", "Reject")}
          </Button>
          <Button
            disabled={request.status != null}
            className={`${COLORS.TEXT_PRIMARY}`}
            onClick={() => onVendorsRequestAction(request.id, true)}>
            {translate("Approve_Text", "Approve")}
          </Button>
        </div>
      ),
      status: (
        <div>
          {request.status == null
            ? "PENDING"
            : request.status == 1
            ? "Approved"
            : "Rejected"}
        </div>
      ),
    };
    data.push(row);
  }
  return data;
};

export const vendorsListHeaders = [
  { title: "ID", dataIndex: "Id", key: "id", isSortable: false },
  {
    title: translate("Vendors_Name_Text", "Vendor name"),
    dataIndex: "VendorName",
    key: "vendorName",
    isSortable: true,
  },
  {
    title: translate("Vendor_Account_Manager_Text", "Vendor`s account manager"),
    dataIndex: "AccountManager",
    key: "accountManager",
    isSortable: true,
  },
  {
    title: translate("Phone_Text", "Phone"),
    dataIndex: "VendorMobileNumber",
    key: "vendorMobileNumber",
    isSortable: false,
  },
  {
    title: translate("Date_Text", "Date"),
    dataIndex: "ApprovedAt",
    key: "approvedAt",
    isSortable: true,
  },
  {
    title: translate("Status_Text", "Status"),
    dataIndex: "Status",
    key: "status",
    isSortable: false,
  },
];

export const getVendorsDataList = (
  vendorsList: VendorsDetailsResponse[],
  onVendorActionClick: (
    vendorId: string,
    vendorName: string,
    isActivate: boolean
  ) => void,
  onVendorClick: (vendorId: string) => void
) => {
  const data = [];
  for (var i = 0; i < vendorsList.length; i++) {
    const vendor = vendorsList[i];
    const row: Record<string, any> = {
      Id: i + 1,
      VendorName: (
        <div className="flex flex-row items-center space-x-2" onClick={() => onVendorClick(vendor.id)}>
          <ImageWithFallback
            alt="Profile"
            className="rounded-[200px] w-[40px] h-[40px]"
            src={`${
              process.env.REACT_APP_FILES_MANAGMENT_BASE_URL +
              "api/Storage/Download/" +
              vendor.profilePictureFileId
            }`}
            fallbackSrc={ImageIcon}
          />
          <div>{vendor.vendorName}</div>
        </div>
      ),
      AccountManager: vendor.accountManager,
      VendorMobileNumber: vendor.vendorMobileNumber,
      ApprovedAt: moment(vendor.approvedAt).format("YYYY-MM-DD"),
      Status: vendor.isActive ? (
        <Button
          className={`text-sm font-bold ${COLORS.TEXT_PRIMARY} `}
          onClick={() =>
            onVendorActionClick(vendor.id, vendor.vendorName, false)
          }>
          {translate("Active_Text", "Active")}
        </Button>
      ) : (
        <Button
          className={`text-sm font-bold text-red-600`}
          onClick={() =>
            onVendorActionClick(vendor.id, vendor.vendorName, true)
          }>
          {translate("Deactivated_Text", "Deactivated")}
        </Button>
      ),
    };
    data.push(row);
  }
  return data;
};