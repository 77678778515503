import translate from "./translations";

export const MenuItemLabel = ({
  defaultValue,
  key,
}: {
  defaultValue: string;
  key: string;
}) => {
  return { id: key, title: <p>{translate(key, defaultValue)}</p> };
};
