import "./html-render.css";

const RenderHtml = (props: { html: string }) => {
  return (
    <div
      className="render-html"
      dangerouslySetInnerHTML={{ __html: props.html }}
    />
  );
};

export default RenderHtml;
