import {
  AreaPicker,
  Button,
  CountryPicker,
  DropdownOption,
  StatePicker,
  TextInput,
} from "@ofrz/ui-package";
import translate from "../../utils/translations";
import { AppDispatch, ApplicationState } from "../../store";
import { connect } from "react-redux";
import { COLORS } from "../../libs/colors";
import { AddBranchProps, BranchState } from "./add-branch.props";
import { useState } from "react";
import { BranchModel } from "../../libs/models/branch";
import { addNewBranch } from "../../store/slices/vendor-details";

const AddBranchs = (props: AddBranchProps) => {
  const [branchItem, setBranchItem] = useState<BranchState>({
    id: null,
    country: { id: 65, value: "Egypt" },
    street: "",
    buildingNumber: "",
  });
  const onTextFieldChange = (fieldName: string, value: string) => {
    setBranchItem((prev) => ({ ...prev, [fieldName]: value }));
  };

  const onOptionFieldChange = (fieldName: string, value: DropdownOption) => {
    setBranchItem((prev) => ({ ...prev, [fieldName]: value }));
  };

  const onAddBranchClicked = () => {
    props.onAddBranchClicked(branchItem);
  };
  return (
    <div className="flex-col">
      <div className="flex mt-2 justify-around w-full relative">
        <div className="flex-col w-1/3 px-1">
          <label className={`${COLORS.TEXT_FORM} font-semibold`}>
            {translate("Country_Text", "Country")}
          </label>
          <CountryPicker
            placeholder={translate("Country_Text", "Country")}
            selectedOption={{ id: 65, value: "Egypt" }}
            disabled
            onSelect={(countryOption) =>
              countryOption && onOptionFieldChange("country", countryOption)
            }
          />
        </div>
        <div className="flex-col w-1/3 px-1">
          <label className={`${COLORS.TEXT_FORM} font-semibold`}>
            {translate("City_Text", "City")}
          </label>
          <StatePicker
            placeholder={translate("City_Text", "City")}
            selectedCountryOption={branchItem["country"]}
            selectedOption={branchItem["city"]}
            onSelect={(stateOption) =>
              stateOption && onOptionFieldChange("city", stateOption)
            }
          />
        </div>
        <div className="flex-col w-1/3 px-1">
          <label className={`${COLORS.TEXT_FORM} font-semibold`}>
            {translate("Area_Text", "Area")}
          </label>
          <AreaPicker
            placeholder={translate("Area_Text", "Area")}
            selectedCountryOption={branchItem["country"]}
            selectedOption={branchItem["area"]}
            selectedStateOption={branchItem["city"]}
            onSelect={(areaOption) =>
              areaOption && onOptionFieldChange("area", areaOption)
            }
          />
        </div>
      </div>
      <div className="flex mt-2 w-full justify-around ">
        <div className="flex-col w-1/2 px-1">
          <label className={`${COLORS.TEXT_FORM} font-semibold`}>
            {translate("Street_Name_Text", "Street Name")}
          </label>
          <TextInput
            className="w-full border p-2"
            placeholder="Street Name"
            type="text"
            name="street"
            value={branchItem?.street}
            onChange={(e) => onTextFieldChange("street", e.target.value)}
          />
        </div>
        <div className="flex-col w-1/2 px-1">
          <label className={`${COLORS.TEXT_FORM} font-semibold`}>
            {translate("Building_Number_Text", "Building No")}
          </label>
          <TextInput
            className="w-full border p-2"
            placeholder="Building Number"
            type="text"
            name="buildingNumber"
            value={branchItem?.buildingNumber}
            onChange={(e) =>
              onTextFieldChange("buildingNumber", e.target.value)
            }
          />
        </div>
      </div>

      <div className="text-end mt-8">
        <Button className="text-white rounded-[12px] mx-2 w-[6.5rem]">
          <span className="text-center">
            {translate("Cancel_Text", "Cancel")}
          </span>
        </Button>
        <Button
          className="text-white rounded-[12px] mx-2 w-[6.5rem]"
          primary
          onClick={onAddBranchClicked}>
          {translate("Add_Text", "Add")}
        </Button>
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  vendorName: state.vendordetails.vendorName,
  branchId: state.vendordetails.vendorBranches.length + 1,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onAddBranchClicked: (model: BranchState) => {
    dispatch(addNewBranch(model));
  },
});

const AddBranchsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBranchs);
export { AddBranchsContainer };
