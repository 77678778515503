import { push } from "@lagunovsky/redux-react-router";
import { MenuItem } from "@ofrz/ui-package";
import { useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { RolesTypes } from "../../libs/models/login";
import { VENDOR_ROUTES } from "../../libs/routes";
import ImageIcon from "../../static/images/images/img-icon-test.png";
import { NotificationIcon } from "../../static/images/svgs/notification-icon-svg";
import { OfferzLogo } from "../../static/images/svgs/offerz-logo";
import { SearchIcon } from "../../static/images/svgs/search-icon-svg";
import { SettingIcon } from "../../static/images/svgs/setting-icon-svg";
import { AppDispatch, ApplicationState } from "../../store";
import { logout } from "../../store/slices/logout";
import translate from "../../utils/translations";
import { TopNavigationBarProps } from "./top-navigation-bar.props";
import { CrosspayLogo } from "static/images/svgs/crosspay-logo";

const TopNavigationBar = (props: TopNavigationBarProps) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false); // State to track dropdown visibility

  const toggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle dropdown state on click
  };
  const isActiveButton = (buttonRoute: string) => {
    return location.pathname == buttonRoute;
  };
  const logout = () => {
    props.logout();
  };
  const MenuItemLabel = ({
    defaultValue,
    key,
  }: {
    defaultValue: string;
    key: string;
  }) => {
    return { id: key, title: <p>{translate(key, defaultValue)}</p> };
  };
  const onButtonClicked = (buttonRouteClicked: string) => {
    props.goTo(buttonRouteClicked);
  };
  return (
    <div className="w-full h-full px-[32px] flex align-middle items-center justify-between">
      <div className="flex align-middle items-center">
        <div>
          <CrosspayLogo />
        </div>
        {props.userRole === RolesTypes.VendorAdmin ? (
          <div className="flex ms-[64px]">
            {/* <MenuItem
              isActive={isActiveButton(VENDOR_ROUTES.DASHBOARD)}
              menuItem={MenuItemLabel({
                defaultValue: "Dashboard",
                key: "dashboard",
              })}
              isExpandable={false}
              onClick={() => onButtonClicked(VENDOR_ROUTES.DASHBOARD)}
            /> */}
            <MenuItem
              isActive={isActiveButton(VENDOR_ROUTES.OFFERZ)}
              menuItem={MenuItemLabel({
                defaultValue: "Offerz",
                key: "Offerz",
              })}
              isExpandable={false}
              onClick={() => onButtonClicked(VENDOR_ROUTES.OFFERZ)}
            />
            <MenuItem
              isActive={isActiveButton(VENDOR_ROUTES.MAGAZINE)}
              menuItem={MenuItemLabel({
                defaultValue: "Magazine",
                key: "Magazine",
              })}
              isExpandable={false}
              onClick={() => onButtonClicked(VENDOR_ROUTES.MAGAZINE)}
            />
            <MenuItem
              isActive={isActiveButton(VENDOR_ROUTES.CATEGORIES)}
              menuItem={MenuItemLabel({
                defaultValue: translate("Categories", "Categories"),
                key: "Categories",
              })}
              isExpandable={false}
              onClick={() => onButtonClicked(VENDOR_ROUTES.CATEGORIES)}
            />
            <MenuItem
              isActive={isActiveButton(VENDOR_ROUTES.REPORTS)}
              menuItem={MenuItemLabel({
                defaultValue: "Reports",
                key: "Reports",
              })}
              isExpandable={false}
              onClick={() => onButtonClicked(VENDOR_ROUTES.REPORTS)}
            />
            <MenuItem
              isActive={isActiveButton(VENDOR_ROUTES.PRODUCTS)}
              menuItem={MenuItemLabel({
                defaultValue: "Products",
                key: "Products",
              })}
              isExpandable={false}
              onClick={() => onButtonClicked(VENDOR_ROUTES.PRODUCTS)}
            />
            <MenuItem
              isActive={isActiveButton(VENDOR_ROUTES.USERS)}
              menuItem={MenuItemLabel({ defaultValue: "Users", key: "Users" })}
              isExpandable={false}
              onClick={() => onButtonClicked(VENDOR_ROUTES.USERS)}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="flex text-end gap-1 align-middle items-center">
        <div className="p-[10px] cursor-pointer">
          <SearchIcon />
        </div>
        <div className="p-[10px] cursor-pointer">
          <SettingIcon />
        </div>
        <div className="p-[10px] cursor-pointer">
          <NotificationIcon />
        </div>
        <div className="dropdown relative">
          <button
            className="ms-[16px] h-10 w-10 rounded-[200px] flex items-center justify-center"
            onClick={toggleDropdown}
          >
            <img
              width="40px"
              height="40px"
              className="rounded-[200px]"
              src={ImageIcon}
              alt="Dropdown Trigger"
            />
          </button>

          {isOpen && (
            <div className="dropdown-content absolute top-full left-0 right-0 z-50 bg-white shadow-md rounded-md overflow-hidden w-[100px]">
              {/* Your dropdown menu items here */}
              <div
                className="px-4 py-2 hover:bg-gray-200 cursor-pointer float-start"
                onClick={logout}
              >
                {translate("logout", "logout")}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  userRole: state.login.userRole ?? RolesTypes.Admin,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  goTo: (route: string) => {
    dispatch(push(route));
  },
  logout: () => {
    dispatch(logout());
  },
});

const TopNavigationBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TopNavigationBar);
export { TopNavigationBarContainer };
