import { connect } from "react-redux";

import { Button } from "@ofrz/ui-package";
import { useEffect, useState } from "react";
import { COLORS } from "../../../../libs/colors";
import { CategoryModel } from "../../../../libs/models/category";
import { RolesTypes } from "../../../../libs/models/login";
import { OfferzLogo } from "../../../../static/images/svgs/offerz-logo";
import { VerticalDotsIcon } from "../../../../static/images/svgs/vertical-dots-icon-svg";
import { ApplicationState, AppDispatch } from "../../../../store";
import {
  deleteCategory,
  setCategoryItem,
  setClearCategoryItem,
} from "../../../../store/slices/categories";
import {
  ModalPopupStore,
  setDialogStateChange,
  setCloseDialog,
} from "../../../../store/slices/modal-popup";
import translate from "../../../../utils/translations";
import { AddSubCategoriesPopupContainer } from "../../containers/AddSubCategoryContainer/add-sub-category-popup";
import { EditCategoryPopUpContainer } from "../../containers/UpdateCategoryContainer";
import DeleteCategoryPopUp from "../delete-category-popup";
import { CategoryCardItemProps } from "./category-item.props";
import { OFFERZ_END_POINTS } from "../../../../libs/endpoints";
import { HttpOfferz } from "../../../../services/https";
import { getFileUrl } from "../../../../utils/mediaUrlFormater";
import { CrosspayLogo } from "static/images/svgs/crosspay-logo";

const CategoryCardItem = (props: CategoryCardItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClicks);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClicks);
    };
  }, [isOpen]);
  const handleOutsideClicks = (event: any) => {
    if (isOpen && event.target.name !== "menu-option") {
      setIsOpen(false);
    }
  };
  const onDeleteItem = () => {
    props.onDeleteCategoryClick({
      isOpen: true,
      title: <></>,
      content: <DeleteCategoryPopUp />,
      actionButtons: [
        {
          children: (
            <div className={`${COLORS.TEXT_WHITE} bg-red`}>
              {translate("Close", "Close")}
            </div>
          ),
          className: `rounded-lg`,
          primary: true,
          onClick: props.setCloseDialog,
        },
        {
          children: (
            <div className={`${COLORS.TEXT_WHITE} bg-red`}>
              {translate("Delete", "Delete")}
            </div>
          ),
          className: `rounded-lg`,
          primary: true,

          onClick: () => props.deleteCategory(props.categoryItem.id),
        },
      ],
      onClose: () => props.setCloseDialog(),
    });
    setIsOpen(false);
  };
  const onEditItem = () => {
    props.onEditCategoryClick(
      {
        isOpen: true,
        title: <p>{translate("Edit_Category", "Edit Category")}</p>,
        content: <EditCategoryPopUpContainer />,
        actionButtons: [],
        onClose: () => props.setCloseDialog(),
      },
      props.categoryItem
    );
    setIsOpen(false);
  };
  const onAddSubCategory = () => {
    props.onAddSubCategoryClick({
      isOpen: true,
      title: <p>{translate("Add_Sub_Category", "Add Sub Category")}</p>,
      content: (
        <AddSubCategoriesPopupContainer
          parentCategoryId={props.categoryItem.id}
          parentCategoryName={props.categoryItem.name}
        />
      ),
      actionButtons: [],
      onClose: () => props.setCloseDialog(),
    });
  };
  const RenderSubCategory = () => {
    const slicedItems = props.categoryItem.children.slice(0, 3);
    const renderSubCategory = slicedItems.map((subCategory) => {
      return (
        <div
          key={subCategory.id}
          className="bg-[#518c7d1a]  rounded-[16px] inline-block px-2 align-middle items-center h-[24px] "
        >
          <label className="text-[12px] text-[#518c7d]">
            {subCategory.name}
          </label>
        </div>
      );
    });
    return <> {renderSubCategory}</>;
  };

  return (
    <div
      className="rounded-[8px] shadow-lg w-[300px] p-3 flex-col"
      key={props.categoryItem.id}
    >
      <div className="flex justify-between">
        <div className="text-ellipsis line-clamp-2 font-semibold text-[14px]">
          {props.categoryItem.name}
        </div>
        {props.userRole === RolesTypes.Admin && (
          <div className="cursor-pointer relative inline-block">
            <div className="" onClick={toggleDropdown}>
              <VerticalDotsIcon />
            </div>
            {isOpen && (
              <div
                id="myDropdown"
                className="absolute shadow-md  min-w-[100px] "
              >
                <button
                  className="hover:bg-[#2980B9] px-2 py-2 w-full text-start"
                  onClick={onEditItem}
                  name="menu-option"
                >
                  Edit
                </button>
                <hr />
                <button
                  className="hover:bg-[#2980B9] px-2 py-2 w-full text-start"
                  onClick={onDeleteItem}
                  name="menu-option"
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex w-full h-[110px] contain-size bg-white rounded-[10px] mr-3 justify-center items-center my-3">
        {props.categoryItem.pictureFileId === null ? (
          <CrosspayLogo width="auto" height="90" />
        ) : (
          <img
            src={getFileUrl(props.categoryItem.pictureFileId)}
            alt="Downloaded Image"
            className="object-cover w-full h-full rounded-lg"
          />
        )}
      </div>
      <div className="flex w-auto bg-white rounded-[10px] mr-3 justify-start items-center text-[12px] text-[#0e1014]">
        {translate("Sub_Categories", "Sub-Categories")}
      </div>
      <div className="flex flex-wrap gap-1 w-full h-[50px] mt-2 items-center">
        <RenderSubCategory />
        {props.categoryItem.children.length > 3 && (
          <div
            key={props.categoryItem.children.length - 3}
            className="bg-[#518c7d1a] rounded-[16px] inline-block px-2 align-middle items-center"
          >
            <label className=" text-[12px] text-[#518c7d]">{`+${
              props.categoryItem.children.length - 3
            }`}</label>
          </div>
        )}
      </div>
      {props.userRole === RolesTypes.Admin && (
        <div className="flex justify-center mt-2 ">
          <Button
            className="border-2 rounded-[8px] w-full !py-2"
            onClick={onAddSubCategory}
          >
            <span className="text-center text-[14px] font-medium">
              {translate("New Sub-Category", "New Sub-Category")}
            </span>
          </Button>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  userRole: state.login.userRole!,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  deleteCategory: (id: string) => {
    dispatch(deleteCategory(id));
  },
  onDeleteCategoryClick: (modalDialogProps: ModalPopupStore) => {
    dispatch(setDialogStateChange(modalDialogProps));
  },
  onEditCategoryClick: (
    modalDialogProps: ModalPopupStore,
    categoryItem: CategoryModel
  ) => {
    dispatch(setCategoryItem(categoryItem));
    dispatch(setDialogStateChange(modalDialogProps));
  },
  onAddSubCategoryClick: (modalDialogProps: ModalPopupStore) => {
    dispatch(setDialogStateChange(modalDialogProps));
  },
  setCloseDialog: () => {
    dispatch(setClearCategoryItem());
    dispatch(setCloseDialog());
  },
});

const CategoryCardItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryCardItem);
export { CategoryCardItemContainer };
