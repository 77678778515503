import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  SystemVendorsView,
  VendorDetailsResponse,
  VendorsDetailsResponse,
  VendorsRequestsResponse,
} from "../../libs/models/system-admin";
import { AsyncAppThunk } from "../application-state";
import {
  GetVendorDetails,
  GetVendorsList,
  GetVendorsRequests,
  activateVendor,
  approveVendorRequest,
  rejectVendorRequest,
  suspendVendor,
} from "../../services/offerz-service";
import { showLoaderWhile } from "./ui";
import { PaginatedList } from "../../libs/models/paginated-list";
import { Filter } from "../../libs/models/filter";
import { getFilterQuery } from "../../utils/filter";
import { setCloseDialog } from "./modal-popup";
import { SYSTEM_ADMIN } from "../../libs/routes";
import { matchPath } from "react-router";

export interface VendorsStore {
  filter: Filter;
  VendorDetails?: VendorDetailsResponse;
  vendorsRequests: PaginatedList<VendorsRequestsResponse>;
  vendorsList: PaginatedList<VendorsDetailsResponse>;
}

const initialState: VendorsStore = {
  filter: {
    search: { key: "VendorName", value: "" },
    dateProp: { from: null, to: null },
    orderBy: { key: "VendorName", sort: "desc" },
  },
  vendorsRequests: {
    list: [],
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
  },
  vendorsList: {
    list: [],
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
  },
};

const slice = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    setVendorsRequests: (
      state: VendorsStore,
      action: PayloadAction<PaginatedList<VendorsRequestsResponse>>
    ) => {
      state.vendorsRequests = action.payload;
    },
    setVendorsList: (
      state: VendorsStore,
      action: PayloadAction<PaginatedList<VendorsDetailsResponse>>
    ) => {
      state.vendorsList = action.payload;
    },
    setSearch: (state: VendorsStore, action: PayloadAction<string>) => {
      state.filter.search!.value = action.payload;
    },
    setSkip: (state: VendorsStore, action: PayloadAction<number>) => {
      state.vendorsList.pageIndex = action.payload;
    },
    setSortFilter: (
      state: VendorsStore,
      action: PayloadAction<{ key: string; sort: "desc" | "asc" }>
    ) => {
      state.filter.orderBy = action.payload;
    },
    setVendorDetails: (
      state: VendorsStore,
      action: PayloadAction<VendorDetailsResponse>
    ) => {
      state.VendorDetails = action.payload;
    },
  },
});

export const {
  setVendorsRequests,
  setVendorsList,
  setSearch,
  setSkip,
  setSortFilter,
  setVendorDetails,
} = slice.actions;
export const reducer = slice.reducer;

export const getVendorsRequests =
  (): AsyncAppThunk => async (dispatch, store) => {
    dispatch(
      showLoaderWhile(async () => {
        const queryFilter = getFilterQuery(
          store().vendors.filter,
          store().vendors.vendorsRequests.pageIndex *
            store().vendors.vendorsRequests.pageSize,
          store().vendors.vendorsRequests.pageSize
        );
        const vendorsResponse = await GetVendorsRequests(queryFilter);

        if (vendorsResponse) {
          dispatch(setVendorsRequests(vendorsResponse.result));
        }
      })
    );
  };

export const getVendorsList = (): AsyncAppThunk => async (dispatch, store) => {
  dispatch(
    showLoaderWhile(async () => {
      const vendorsListResponse = await GetVendorsList(
        getFilterQuery(
          store().vendors.filter,
          store().vendors.vendorsList.pageIndex *
            store().vendors.vendorsList.pageSize,
          store().vendors.vendorsList.pageSize
        )
      );
      if (vendorsListResponse && vendorsListResponse?.result !== null) {
        dispatch(setVendorsList(vendorsListResponse!.result));
      }
    })
  );
};

export const searchByName =
  (name: string, type: SystemVendorsView): AsyncAppThunk =>
  async (dispatch, store) => {
    dispatch(
      showLoaderWhile(async () => {
        dispatch(setSearch(name));
        type === SystemVendorsView.Requests
          ? dispatch(getVendorsRequests())
          : dispatch(getVendorsList());
      })
    );
  };

export const onSort =
  (dataIndex: string, order: "desc" | "asc"): AsyncAppThunk =>
  async (dispatch, store) => {
    dispatch(
      showLoaderWhile(async () => {
        dispatch(
          setSortFilter({
            key: dataIndex,
            sort: order,
          })
        );
        dispatch(getVendorsList());
      })
    );
  };

export const onSkip =
  (currentPage: number): AsyncAppThunk =>
  async (dispatch, store) => {
    dispatch(
      showLoaderWhile(async () => {
        dispatch(setSkip(currentPage));
        dispatch(getVendorsList());
      })
    );
  };

export const vendorsRequestAction =
  (requestId: string, approve: boolean): AsyncAppThunk =>
  async (dispatch, store) => {
    dispatch(
      showLoaderWhile(async () => {
        if (approve) {
          const response = await approveVendorRequest(requestId);
          dispatch(getVendorsRequests());
        } else {
          const response = await rejectVendorRequest(requestId);
          dispatch(getVendorsRequests());
        }
      })
    );
  };

export const vendorAction =
  (vendorId: string, active: boolean): AsyncAppThunk =>
  async (dispatch, store) => {
    dispatch(
      showLoaderWhile(async () => {
        let response = null;
        if (active) {
          response = await activateVendor(vendorId);
        } else {
          response = await suspendVendor(vendorId);
        }
        if (response?.result) {
          dispatch(setCloseDialog());

          const pathname = store().router.location.pathname;
          const matchVendorDetails = matchPath(
            { path: SYSTEM_ADMIN.VENDOR_DETAILS },
            pathname
          );

          if (matchVendorDetails != null) {
            dispatch(getVendorDetails(matchVendorDetails.params.id!));
          } else {
            dispatch(getVendorsList());
          }
        }
      })
    );
  };

export const getVendorDetails =
  (vendorId: string): AsyncAppThunk =>
  async (dispatch, store) => {
    const response = await GetVendorDetails(vendorId);
    if (response?.result) {
      dispatch(setVendorDetails(response?.result));
    }
  };
