import { useState } from "react";
import { CollipsibleCardProps } from "./collipsible-card.props"

const CollipsibleCard = (props:CollipsibleCardProps) =>{
    
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => setIsOpen(!isOpen);

    return(
        <div className="shadow-sm bg-white rounded-lg relative border-2 p-4 mb-4 cursor-default">
          <div className="flex w-full cursor-pointer" onClick={toggleContent}>
           {props.content}
            <i className={`fas fa-chevron-${isOpen ? 'up' : 'down'} text-gray-500 transition duration-300 ease-in-out`}></i>
          </div>
          {isOpen && props.details}
        </div>
    )
}

export {CollipsibleCard}