import { connect } from "react-redux";
import { AppDispatch, ApplicationState } from "../../store";
import {
  ModalPopupStore,
  setCloseDialog,
  setDialogStateChange,
} from "../../store/slices/modal-popup";
import translate from "../../utils/translations";
import { Button, Grid } from "@ofrz/ui-package";
import { adminProductRequestHeaders, getCategoryIdBySubCategory, getProductModelList } from "./admin-product.util";
import { AdminProductProps } from "./admin-product.props";
import { AddProductPopUpContainer } from "./containers/AddAdminProductContainer/add-admin-product-popup.container";
import { useEffect } from "react";
import { deleteProduct, getProductList, setClearProductItem, setProductItem, setProductListPage } from "../../store/slices/product";
import { UpdateProductPopUpContainer } from "./containers/UpdateAdminProductContainer";
import { ProductModel } from "../../libs/models/product";
import { getCategoriesLookUp, setSubCategoryLookUp } from "../../store/slices/categories";
import { getBrandsLookUp } from "../../store/slices/brand";

const AdminProduct = (props: AdminProductProps) => {
  useEffect(() => {
    props.getProductList();
    props.loadCategoryLookUp();
    props.loadBrandLookUp();
  }, []);

  const onAddProducItem = () => {
    props.onAddProductClick({
      isOpen: true,
      title: <p>New Product</p>,
      content: <AddProductPopUpContainer />,
      actionButtons: [],
      onClose: () => props.setCloseDialog(),
    });
  };
  const onEdit = (id: string, productModel: ProductModel) => {
    const categoryId = getCategoryIdBySubCategory(
      productModel.subCategoryId!,
      props.parentCategoryLoopkUp
    )
    props.setSubCategory(categoryId!);

    props.onEditProductClick({
      isOpen: true,
      title: <p>Update Product</p>,
      content: <UpdateProductPopUpContainer categoryId={categoryId} />,
      actionButtons: [],
      onClose: () => props.setCloseDialog(),
    }, productModel);
  }
  const onDelete = (id: string) => {
    props.deleteProduct(id);
  }
  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between py-[25px]">
        <div className="text-[30px] font-medium">
          {translate("Products_Text", "Products")}
        </div>
        <Button
          className="text-white rounded-lg !bg-[#518c7d]"
          onClick={onAddProducItem}
        >
          {translate("Add_New_Product_Text", "Add New Product")}
        </Button>
      </div>
      <Grid
        columns={adminProductRequestHeaders}
        dataSource={getProductModelList(props.productList.list, onEdit, onDelete)}
        currentPage={props.productList.pageIndex + 1}
        onPageChange={(newPage: number) => { props.onPageChange(newPage - 1) }}
        pagination={true}
        pageSize={props.productList.pageSize}
        totalCount={props.productList.totalCount}
        onSort={(dataIndex: string, order: "desc" | "asc") => { }}
      />
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  productList: state.product.productList,
  parentCategoryLoopkUp: state.categories.parentCategoryLoopkUp,
  brandLookUp: state.brand.brandLoopkUp,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onAddProductClick: (modalProps: ModalPopupStore) => {
    dispatch(setDialogStateChange(modalProps));
  },
  getProductList: () => {
    dispatch(getProductList());
  },
  loadCategoryLookUp: () => {
    dispatch(getCategoriesLookUp());
  },
  setSubCategory: (categoryId: string) => {
    dispatch(setSubCategoryLookUp(categoryId));
  },
  setCloseDialog: () => {
    dispatch(setClearProductItem());
    dispatch(setCloseDialog());
  },
  loadBrandLookUp: () => {
    dispatch(getBrandsLookUp());
  },
  deleteProduct: (id: string) => {
    dispatch(deleteProduct(id));
  },
  onEditProductClick: (modalDialogProps: ModalPopupStore, productItem: ProductModel) => {
    dispatch(setProductItem(productItem))
    dispatch(setDialogStateChange(modalDialogProps));
  },
  onPageChange: (pageNumber: number) => {
    dispatch(setProductListPage(pageNumber));
    dispatch(getProductList());
  }
});
const AdminProductContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProduct);
export { AdminProductContainer };
