import { ValidationModel } from "libs/models/validate-model";
import { VendorProductModel } from "libs/models/vendor-product";
import { VendorProductStore } from "store/slices/vendor-product";
import translate from "utils/translations";

export const validateUpdateVendorProductItemState = (
    vendorProductState: VendorProductStore,
): ValidationModel<VendorProductModel> => {
    let validationModel = {} as ValidationModel<VendorProductModel>;
    if (!vendorProductState.isValidating) {
        return validationModel;
    }
    if (!vendorProductState.vendorProductItem.price || vendorProductState.vendorProductItem.price === null || vendorProductState.vendorProductItem.price === "") {
        validationModel.price = translate("Required_Text", "This field is required");
    }
    return validationModel;
};
