import { Button, PhoneNumber, TextInput } from "@ofrz/ui-package";
import React from "react";
import { connect } from "react-redux";
import { PopUpContainer } from "../../components/pop-up";
import { COLORS } from "../../libs/colors";
import { CountryCodes } from "../../libs/country-codes";
import { AppDispatch, ApplicationState } from "../../store";
import { setEmail, setPassword } from "../../store/slices/signup";
import {
  setFirstName,
  setLastName,
  setMobileNumber,
  signup,
} from "../../store/slices/signup";
import translate from "../../utils/translations";
import { validateSignupState } from "./signup.policy";
import { SignUpFormProps } from "./signup.props";
import { ROUTES } from "../../libs";
import { push } from "@lagunovsky/redux-react-router";

const SignUp = (props: SignUpFormProps) => {
  const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeFirstName(event.target.value.trim());
  };

  const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeLastName(event.target.value.trim());
  };
  const onMobileNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeMobilember(event.target.value.trim());
  };
  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeEmail(event.target.value.trim());
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangePassword(event.target.value.trim());
  };
  const onNextClicked = () => {
    props.onRegister();
  };

  return (
    <div className="flex flex-col py-2">
      <PopUpContainer email={props.username} />

      <div className="mt-2 ">
        <label className={`${COLORS.TEXT_FORM} font-semibold`}>
          {translate("First_Name_Text", "First Name")}
        </label>
        <TextInput
          className="w-full border p-2"
          placeholder="first name"
          type="text"
          value={props.firstName}
          autoComplete="first-name"
          onInput={onFirstNameChange}
          onChange={onFirstNameChange}
          error={props.validationState.firstName}
        />
      </div>
      <div className="mt-2">
        <label className={`${COLORS.TEXT_FORM} font-semibold`}>
          {translate("Last_Name_Text", "Last Name")}
        </label>
        <TextInput
          className="w-full border p-2"
          placeholder="last name"
          type="text"
          autoComplete="last-name"
          onInput={onLastNameChange}
          onChange={onLastNameChange}
          value={props.lastName}
          error={props.validationState.lastname}
        />
      </div>
      <div className="mt-2">
        <label className={`${COLORS.TEXT_FORM} font-semibold`}>
          {translate("Login_Email_Text", "Email")}
        </label>
        <TextInput
          className="w-full border p-2"
          placeholder="store@offerz.com"
          type="text"
          autoComplete="user-name"
          disableSpace
          value={props.username}
          onChange={onEmailChange}
          onInput={onEmailChange}
          error={props.validationState.userName}
        />
      </div>
      <div className="mt-2">
        <label className={`${COLORS.TEXT_FORM} font-semibold`}>
          {translate("Phone_Number_Text", "Phone Number")}
        </label>
        <PhoneNumber
          defaultCountryCode="+02"
          value={props.mobileNumber}
          onChange={onMobileNumberChange}
          countryCodes={CountryCodes}
          error={props.validationState.mobileNumber}
        />
      </div>
      <div className="mt-2 ">
        <label className={`${COLORS.TEXT_FORM} font-semibold`}>
          {translate("Login_Password_Text", "Password")}
        </label>
        <TextInput
          className="w-full border p-2"
          placeholder="Type your password"
          type="password"
          autoComplete="password"
          disableSpace
          value={props.password}
          onInput={onPasswordChange}
          onChange={onPasswordChange}
          error={props.validationState.password}
        />
      </div>
      <div className="mt-6 flex justify-end">
        <Button
          onClick={onNextClicked}
          primary
          className={`bg-red-900 rounded-lg  ${COLORS.TEXT_WHITE} w-1/5 text-center !px-0 `}
          size="md"
        >
          {translate("Next_Text", "Next")}
        </Button>
      </div>
      <div className="flex justify-center mt-4">
        <p
          onClick={() => props.goTo(ROUTES.LOGIN)}
          className="cursor-pointer hover:underline hover:text-primary-500 text-primary-500 text-sm mt-1 hover"
        >
          {translate("Already_User_Text", "Already a user?, Signin")}
        </p>
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  userCulture: state.signup.userCulture,
  username: state.signup.email,
  password: state.signup.password,
  firstName: state.signup.firstName,
  lastName: state.signup.lastName,
  mobileNumber: state.signup.mobileNumber,
  validationState: validateSignupState(state.signup),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onChangeFirstName: (firstName: string) => {
    dispatch(setFirstName(firstName));
  },
  onChangeLastName: (lastName: string) => {
    dispatch(setLastName(lastName));
  },
  onChangeMobilember: (mobileNumber: string) => {
    dispatch(setMobileNumber(mobileNumber));
  },
  onChangeEmail: (email: string) => {
    dispatch(setEmail(email));
  },
  onChangePassword: (password: string) => {
    dispatch(setPassword(password));
  },
  onRegister: () => {
    dispatch(signup());
  },
  goTo: (route: string) => {
    dispatch(push(route));
  },
});

const SignUpContainer = connect(mapStateToProps, mapDispatchToProps)(SignUp);
export { SignUpContainer };
