import { MagazineModel } from "../../libs/models/magazine";
import { ValidationModel } from "../../libs/models/validate-model";
import { MagazineStore } from "../../store/slices/magazine";
import translate from "../../utils/translations";


export const validateMagazineItemState = (state: MagazineStore): ValidationModel<MagazineModel> => {
    let validationModel = {} as ValidationModel<MagazineModel>;
  
    if (!state.isValidating) {
      return validationModel;
    }

    Object.keys(state.magazineItem).map((key) => {
      if ((state.magazineItem[key as keyof MagazineModel] === null || state.magazineItem[key as keyof MagazineModel] === "") && key != "id") {
        validationModel[key as keyof MagazineModel] = translate(
          "Required_Text",
          "This field is required"
        );
      }
    });
    return validationModel;
  };
  