import { connect } from "react-redux";
import { COLORS } from "../../../libs/colors";
import { AddSvg } from "../../../static/images/svgs/add-svg";
import { AppDispatch, ApplicationState } from "../../../store";
import {
  removeBranch,
  setAddBranchDialogIsOpen,
} from "../../../store/slices/vendor-details";
import translate from "../../../utils/translations";
import { AddBranchComponentProps } from "./add-branch-component.props";
import { Button } from "@ofrz/ui-package";
import { CloseIcon } from "../../../static/images/svgs/close-icon-svg";

const AddBranchComponent = (props: AddBranchComponentProps) => {
  const onAddNewBranch = () => {
    props.onAddNewBranch();
  };
  const onRemoveBranchClick = (branchId: number) => {
    props.onRemoveBranch(branchId);
  };
  const RenderBranchComponent = () => {
    return (
      <div className="flex align-middle justify-between">
        <div className="flex justify-start">
          {props.vendorBranches.map(({ area, id }) => {
            return (
              <div
                key={id}
                className="bg-[#518c7d1a]  rounded-[16px] inline-block px-3 "
              >
                <div className="flex align-middle items-center">
                  <div className=" text-[#518c7d] ">{area}</div>
                  <div
                    className="ps-1"
                    onClick={() => onRemoveBranchClick(id!)}
                  >
                    <CloseIcon />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          onClick={onAddNewBranch}
          className="rounded-[16px]  bg-[#518c7d] inline-block px-3 py-1 text-white cursor-pointer text-end"
        >
          <div className="flex align-middle items-center">
            <div className="h-[15px] w-[15px] self-center">
              <AddSvg width="auto" height="auto" strokeColor="white" />
            </div>
            <p className="text-[14px] self-center ps-1">Add New</p>
          </div>
        </div>
      </div>
    );
  };
  return props.vendorBranches.length > 0 ? (
    <RenderBranchComponent />
  ) : (
    <div className="inline-block cursor-pointer" onClick={onAddNewBranch}>
      <div className="mr-[0.5rem] float-start">
        <AddSvg />
      </div>
      <div className={`${COLORS.TEXT_PRIMARY} font-semibold float-end`}>
        {translate("Add_Vendor_Branch_Text", "Add vendor branch")}
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  userCulture: state.vendordetails.userCulture,

  vendorBranches: state.vendordetails.vendorBranches,
  addBranchDialogIsOpen: state.vendordetails.addBranchDialogIsOpen,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onAddNewBranch: () => {
    dispatch(setAddBranchDialogIsOpen(true));
  },
  onRemoveBranch: (branchId: number) => {
    dispatch(removeBranch(branchId));
  },
});
const AddBranchComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBranchComponent);
export { AddBranchComponentContainer };
